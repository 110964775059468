import { Drawer, styled } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import React from 'react';
import MenuList from './MenuList';

const MenuLateral = ({ open, onClose, menu, top }) => {

    const [subMenuZero, setSubMenuZero]  = useState(menu);
    const [subMenuOne,  setSubMenuOne]   = useState([]);
    const [subMenuTwo,  setSubMenuTwo]   = useState([]);
    const [subMenuTree, setSubMenuTree]  = useState([]);

    const [scrollZero, setScrollZero] = useState(0);
    const [scrollOne, setScrollOne]   = useState(0);
    const [scrollTwo, setScrollTwo]   = useState(0);
    const [scrollTree, setScrollTree] = useState(0);

    const location = useLocation();
    
    useEffect(() => {
        setTimeout(() => {
            onClose();
        }, 100)

        // eslint-disable-next-line
    }, [location])

    const handleMenuSelect = (setSubMenu, index) => {
        setSubMenu((prevState) => {
            return prevState.map((menu, i) => ({
                ...menu,
                Select: index === i
            }))
        })
    }

    const handleResetMenu = (propertyMenu, tipo) => {
        if(propertyMenu === 'MenuSub1') {
            if(tipo === 'list') {
                setSubMenuTwo([])
                setSubMenuTree([])
            } else {
                setSubMenuOne([])
                setSubMenuTwo([])
                setSubMenuTree([])
            }
        } else if(propertyMenu === 'MenuSub2') {
            if(tipo === 'list') {
                setSubMenuTree([])
            } else {
                setSubMenuTwo([])
                setSubMenuTree([])
            }
        } else if(propertyMenu === 'MenuSub3') {
            if(tipo === 'list') {
                
            } else {
                setSubMenuTree([])
            }  
        } 
    }

  return (
    <MuiDrawer
        anchor='top'
        open={open}
        top={top}
        onClose={onClose}
        onClick={onClose}
    >
        <div style={{ overflow: 'hidden' }}>
            <MuiBox top={top}>
                <MenuList 
                    menu={subMenuZero} 
                    propertyMenu={'MenuSub1'} 
                    setSubMenu={setSubMenuOne}
                    onReset={handleResetMenu}
                    onSelect={(index) => handleMenuSelect(setSubMenuZero, index)}
                    scroll={scrollZero}
                    setScroll={setScrollZero}
                    par={0}
                />
                <MenuList 
                    menu={subMenuOne} 
                    propertyMenu={'MenuSub2'} 
                    setSubMenu={setSubMenuTwo} 
                    onReset={handleResetMenu}
                    onSelect={(index) => handleMenuSelect(setSubMenuOne, index)}
                    scroll={scrollOne}
                    setScroll={setScrollOne}
                    par={1}
                />
                <MenuList 
                    menu={subMenuTwo} 
                    propertyMenu={'MenuSub3'} 
                    setSubMenu={setSubMenuTree}
                    onReset={handleResetMenu}
                    onSelect={(index) => handleMenuSelect(setSubMenuTwo, index)}
                    scroll={scrollTwo}
                    setScroll={setScrollTwo}
                    par={0}
                />
                <MenuList 
                    menu={subMenuTree} 
                    onReset={handleResetMenu}
                    onSelect={(index) => handleMenuSelect(setSubMenuTree, index)}
                    scroll={scrollTree}
                    setScroll={setScrollTree}
                    par={1}
                />
            </MuiBox>
        </div>
    </MuiDrawer>
  )
}


const MuiBox = styled('div')(({ top }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    maxWidth: '1230px',
    margin: '0 auto',
    height: top ? 'calc(100% - 65px)' : 'calc(100% - 111px)',
    zIndex: '900',
}));

const MuiDrawer = styled(Drawer)(({ top }) => ({
    '& .MuiBackdrop-root': {
        marginTop: top ? '65px' : '111px',
    },
    '& .MuiDrawer-paper': {
        marginTop: top ? '65px' : '111px',
    },
    '& .MuiPaper-root': {
        backgroundColor: 'transparent',
    },
    '& .MuiPaper-elevation16': {
        boxShadow: 'none'
    },
    '& .MuiDrawer-paperAnchorTop': {
        height: '100%'
    }, 
    '&.MuiDrawer-root': {
        zIndex: '900 !important'
    }
}));

export default MenuLateral;


