import { useState } from "react";
import "./css/ProductoExistencia.css"
import IconHelp from "../../icons/IconHelp";
import { Tooltip, styled } from "@material-ui/core";
import useCheckOut from "../../../services/intelafApiClient/handler/useCheckOut";

const ProductoExistencia = (props) => {
    const { existencias, enBodega, enTransito, fechaTransito } = props;

    const { handleCreateUpdateCarrito } = useCheckOut();

    var sucursal = existencias.find(t => t.CodigoSucursal === '5');


    return (
        <div className='container-sucursales'>
            <div>
                <p>Disponibilidad</p>
                {
                    existencias.length > 0 ? <><p style={{ fontSize: '11.5px', marginTop: '3px', padding: "3px", borderRadius: "5px", borderStyle: "solid", borderWidth: "1px", borderColor: "white", color: 'white', backgroundColor: "white" }}>No hay stock en tiendas</p></> :
                        <>
                            <p style={{ fontSize: '11.5px', marginTop: '3px', padding: "3px", borderRadius: "5px", borderStyle: "solid", borderWidth: "1px", borderColor: "rgb(250,200,200)", color: 'red', backgroundColor: "rgb(250,210,210)" }}>No hay stock en tiendas</p>
                        </>
                }
                <hr style={{ marginTop: '10px', marginBottom: '20px' }}></hr>
                <div className='sucursales'>
                    {
                        existencias.map((tienda) => (
                            <div key={tienda.CodigoSucursal} className='card-tienda' onClick={() => { handleCreateUpdateCarrito(tienda) }}>
                                <div className='tienda'>
                                    <span>
                                        <p className='tienda-title'>{tienda.NombreSucursal.toCapitalize()} </p>
                                        {tienda.CodigoSucursal === "5" ? <IconToolTip /> : <></>}
                                    </span>
                                    <p className='tienda-direccion'>{tienda.CodigoSucursal !== "5" ? tienda.Direccion : "Pickup-Recoger en: " + tienda.Direccion + ""}</p>
                                </div>
                                <p className='tienda-cantidad'>{tienda.Existencia}</p>
                            </div>
                        ))
                    }
                </div>
                <hr style={{ marginTop: '20px', marginBottom: '10px' }}></hr>
                <div className='disponibilidad-bodega'>
                    <div>
                        <p>Envío a domicilio</p>
                        <p style={{ color: `${sucursal === undefined ? '#8b8b8b' : 'rgba(0, 217, 68, 1)'}` }}>{sucursal === undefined ? 'No Disponible' : 'Disponible'}</p>
                    </div>
                    <div>
                        <p>En bodega</p>
                        <p style={{ color: `${enBodega ? 'rgba(0, 217, 68, 1)' : '#8b8b8b'}` }}>{enBodega ? 'Si' : 'No'}</p>
                    </div>
                    <div>
                        <p>En tránsito</p>
                        <p style={{ color: `${enTransito ? 'rgba(0, 217, 68, 1)' : '#8b8b8b'}` }}>{enTransito ? 'Si' : 'No'}</p>
                    </div>
                    {enTransito ?
                        <div><p>Ingresa el:</p><p style={{ color: "rgba(0, 217, 68, 1)" }}>{new Date(fechaTransito).toLocaleDateString('es-GT', { timeZone: 'America/Guatemala', day: '2-digit', month: '2-digit', year: 'numeric' })}</p></div>
                        : <></>}
                </div>
            </div>
        </div>
    )
}

const IconToolTip = () => {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };


    return (
        <MuiTooltip open={open} onClose={handleClose} onOpen={handleOpen} title={<Titletip><strong>¡Atención!</strong> Esta sucursal no funciona como una tienda tradicional. No ofrecemos ventas físicas. En cambio, puedes realizar pedidos a través de nuestro sitio web y luego recogerlos en la sucursal.</Titletip>} >
            <span>
                <IconHelp width={18} height={18} fill={"red"} />
            </span>
        </MuiTooltip>
    )
}

const MuiTooltip = styled(Tooltip)({
    display: 'flex',
    marginLeft: '0.8vw',
    tooltipPlacementBottom: {
        margin: '0px 0px'
    }
})

const Titletip = styled('p')({
    fontSize: '12px',
    margin: '0px',
    padding: '0px',
    lineHeight: 1.5
})

export default ProductoExistencia;