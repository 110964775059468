import { useSelector, useDispatch } from 'react-redux';
import { createMenu, resetMenu } from '../state/menuSlice';
import { getMenuAsync } from '../../services/intelafApiClient/repository/menuRepository';

const useMenuHandler = () => {

    //Redux
    const dispatch = useDispatch();
    const menu     = useSelector((store) => store.menu);

    //methods
    const handleGetMenuAsync = async () => {
        let menus = await getMenuAsync();
        await dispatch(createMenu(menus.map(menu => menu.toJSON())));
    }

    const handleResetMenu = () => {
        dispatch(resetMenu())
    }

    return {
        menu,
        handleGetMenuAsync,
        handleResetMenu,
    }
}

export default useMenuHandler;