import { CuotaBancoDetalleDto } from "./cuotaBancoDetalleDto";

export class CuotaBancoDto {
    constructor(cuotaData) {
        this.IdBanco = cuotaData.IdBanco || '';
        this.Imagen = cuotaData.Imagen || '';
        this.Orden = cuotaData.Orden || 0;
        this.Detalle = (cuotaData.Detalle || []).map(detalleData => new CuotaBancoDetalleDto(detalleData));
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}