import { Divider, List, ListItem, ListItemIcon as MuiListItemIcon, ListItemText as MuiListItemText, styled } from '@material-ui/core';
import React, { useState } from 'react';
import IconArrowBackIos from '../../../icons/IconArrowBackIos';
import IconArrowForwardIos from '../../../icons/IconArrowForwardIos';
import { useNavigate } from 'react-router-dom';

const MenuProductos = (
    { 
        onOffProductos, 
        onTypeAdd, 
        onTypeRest, 
        typeMenu,
        menuZero,
        menuOne,
        setMenuOne,
        menuTwo,
        setMenuTwo,
        menuTree,
        setMenuTree  
    }) => {

    const [subMenu, setSubMenu] = useState();

    const navigate = useNavigate();
    
    const handleMenuNaviate = (categoria, propertyMenu, setSubMenu) => {
        if(categoria[propertyMenu] === undefined || categoria[propertyMenu] === null) {
            navigate(categoria.Area.includes('/') ? categoria.Area : `/precios_stock_resultado/${categoria.Area.toLowerCase()}`);
            return;
        }

        if(categoria[propertyMenu].length === 0) {
            navigate(categoria.Area.includes('/') ? categoria.Area : `/precios_stock_resultado/${categoria.Area.toLowerCase()}`);
            return;

        } else {
            setSubMenu(categoria);
            onTypeAdd();
            return;
        }
    }

    if(typeMenu === 0) {
        return (
            <ListMenuProductos 
                menu={menuZero} 
                title={'Productos'} 
                propertyMenu={'MenuSub1'}
                setSubMenu={setMenuOne}
                onClick={handleMenuNaviate}
                onTypeRest={onOffProductos}
            /> 
        )
    } else if(typeMenu === 1) {
        return (
            <ListMenuProductos 
                menu={menuOne.MenuSub1} 
                title={menuOne.Nombre} 
                propertyMenu={'MenuSub2'}
                setSubMenu={setMenuTwo}
                onClick={handleMenuNaviate}
                onTypeRest={onTypeRest}
            /> 
        )
    } else if(typeMenu === 2) {
        return (
            <ListMenuProductos 
                menu={menuTwo.MenuSub2} 
                title={menuTwo.Nombre} 
                propertyMenu={'MenuSub3'}
                setSubMenu={setMenuTree}
                onClick={handleMenuNaviate}
                onTypeRest={onTypeRest}
            /> 
        )
    } else if(typeMenu === 3) {
        return (
            <ListMenuProductos 
                menu={menuTree.MenuSub3} 
                title={menuTree.Nombre} 
                propertyMenu={'MenuSub3'}
                setSubMenu={setSubMenu}
                onClick={handleMenuNaviate}
                onTypeRest={onTypeRest}
            /> 
        )
    }

  return (
    <ListMenuProductos menu={menuZero} title={'Productos'} />
  )
}

const ListMenuProductos = ({ menu, propertyMenu, title, setSubMenu, onClick, onTypeRest }) => {

    return (
        <List>
            <ListItem button onClick={onTypeRest}>
                <ListItemIcon>
                    <IconArrowBackIos width={26} height={26} fill={"#808080"}/>
                </ListItemIcon>
                <ListItemText primary={'Regresar'}/>
            </ListItem>
            <Divider style={{ margin: '8px 0px' }}/>
        
            <TitleMenu>{title}</TitleMenu>
            {
                menu.map((categoria, index) => (
                    <ListItem key={index} onClick={() => onClick(categoria, propertyMenu, setSubMenu)} style={{ margin: '10px 0px' }} button>
                        <ListItemIcon>
                            <IconImg src={`https://www.intelaf.com${categoria.Imagen}`}/>
                        </ListItemIcon>
                        <ListItemText primary={categoria.Nombre}/>
                        {
                            categoria[propertyMenu] === undefined || categoria[propertyMenu] === null ? <></> : 
                            <>
                                {
                                    categoria[propertyMenu].length === 0 ? <></> :
                                    <ListItemIcon>
                                        <IconArrowForwardIos width={15} height={15} fill={"#808080"}/>
                                    </ListItemIcon>
                                }
                            </>
                        }
                    </ListItem>
                ))
            }
        </List>
    )
}

const ListItemIcon = styled(MuiListItemIcon)({
    '&.MuiListItemIcon-root': {
        minWidth: '50px !important',
        justifyContent: 'center'
    }
});

const ListItemText = styled(MuiListItemText)({
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontWeight: '400',
    fontSize: '45px',
    fontFamily: 'Inter',
    color: 'rgb(155, 155, 155)',
    '& .MuiTypography-body1': {
        fontSize: '15px',
        fontFamily: 'Inter',
    }
});

const TitleMenu = styled('p')({
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '-.75px',
    margin: '30px 15px 15px 15px'
});

const IconImg = styled('img')({
    width: '24px',
    height: '24px'
});

export default MenuProductos;
