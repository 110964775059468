import React from 'react'

const IconVisibilityOn = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M480.091-336.924q67.985 0 115.485-47.59 47.5-47.591 47.5-115.577 0-67.985-47.59-115.485-47.591-47.5-115.577-47.5-67.985 0-115.485 47.59-47.5 47.591-47.5 115.577 0 67.985 47.59 115.485 47.591 47.5 115.577 47.5ZM480-392q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 171.999q-126.307 0-231.037-67.385Q144.232-354.77 81.771-464.693q-5-8.615-7.308-17.365-2.308-8.751-2.308-17.962t2.308-17.941q2.308-8.731 7.308-17.346 62.461-109.923 167.192-177.307Q353.693-779.999 480-779.999t231.037 67.385q104.731 67.384 167.192 177.307 5 8.615 7.308 17.365 2.308 8.751 2.308 17.962t-2.308 17.941q-2.308 8.731-7.308 17.346-62.461 109.923-167.192 177.307Q606.307-220.001 480-220.001ZM480-500Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"
        fill={props.fill}
      />
    </svg>
  )
}

export default IconVisibilityOn