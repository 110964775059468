import React from 'react'

const IconStore = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M198.461-789.999h563.078q12.749 0 21.374 8.628 8.625 8.629 8.625 21.384 0 12.756-8.625 21.371-8.625 8.615-21.374 8.615H198.461q-12.749 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.625-8.615 21.374-8.615Zm7.693 619.998q-15.365 0-25.759-10.394t-10.394-25.759v-203.847h-12.385q-17.077 0-28.115-13.346-11.039-13.346-7.039-30.423l40-187.691q2.734-12.486 12.757-20.512 10.024-8.026 22.781-8.026h564q12.757 0 22.781 8.026 10.023 8.026 12.757 20.512l40 187.691q4 17.077-7.039 30.423-11.038 13.346-28.115 13.346h-12.385V-200q0 12.75-8.628 21.374-8.629 8.625-21.384 8.625-12.756 0-21.371-8.625-8.615-8.624-8.615-21.374v-210.001H549.999v203.847q0 15.365-10.394 25.759t-25.759 10.394H206.154Zm23.845-59.998h260.002v-180.002H229.999v180.002Zm-43.385-240h586.772-586.772Zm0 0h586.772l-30.539-140.002H217.153l-30.539 140.002Z"
        fill={props.fill}
      />
    </svg>
  )
}

export default IconStore