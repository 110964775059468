import React from 'react'

const IconWidgets = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M633.769-486.078 486.848-633q-5.616-5.615-7.924-11.897-2.307-6.282-2.307-13.461 0-7.18 2.307-13.411 2.308-6.23 7.924-11.846l146.921-146.921q5.615-5.615 11.897-7.923t13.462-2.308q7.179 0 13.41 2.308t11.846 7.923l146.921 146.921q5.616 5.616 7.923 11.898 2.308 6.282 2.308 13.461 0 7.18-2.308 13.41-2.307 6.231-7.923 11.846L684.384-486.078q-5.615 5.615-11.897 7.923-6.282 2.307-13.462 2.307-7.179 0-13.41-2.307-6.231-2.308-11.846-7.923ZM152.31-563.846v-207.69q0-15.366 10.394-25.76 10.394-10.394 25.76-10.394h207.69q15.365 0 25.759 10.394t10.394 25.76v207.69q0 15.365-10.394 25.759t-25.759 10.394h-207.69q-15.366 0-25.76-10.394-10.394-10.394-10.394-25.759Zm375.383 375.382v-207.69q0-15.365 10.394-25.759t25.759-10.394h207.69q15.366 0 25.76 10.394 10.394 10.394 10.394 25.759v207.69q0 15.366-10.394 25.76-10.394 10.394-25.76 10.394h-207.69q-15.365 0-25.759-10.394t-10.394-25.76Zm-375.383 0v-207.69q0-15.365 10.394-25.759t25.76-10.394h207.69q15.365 0 25.759 10.394t10.394 25.759v207.69q0 15.366-10.394 25.76-10.394 10.394-25.759 10.394h-207.69q-15.366 0-25.76-10.394-10.394-10.394-10.394-25.76Zm59.999-399.227h160v-160h-160v160Zm447.767 43.384 113-113-113-113-113 113 113 113Zm-72.385 331.998h160v-160h-160v160Zm-375.382 0h160v-160h-160v160Zm160-375.382Zm174.767-69.616ZM372.309-372.309Zm215.382 0Z"
        fill={props.fill}
      />
    </svg>
  )
}

export default IconWidgets