
import { createAdapterMenu  } from "../../../adapter/menuAdapter";
import { getAsync } from "../httpServiceIntelafApi";

const URI = '/Menu';

export const getMenuAsync = () => {
    return getAsync(URI, {}).then((response) => {
        return response.map(marca => createAdapterMenu(marca))
    });
}