import './css/Share.css';
import { useState, useEffect } from "react";
import copy from "copy-to-clipboard";
import IconMessenger from '../icons/IconMessenger';
import IconTwitter   from '../icons/IconTwitter';
import IconEnvelope  from '../icons/IconEnvelope';
import IconWhatsapp  from '../icons/IconWhatsapp';
import IconTelegram  from '../icons/IconTelegram';
import IconClose     from '../icons/IconClose';
import IconLink      from '../icons/IconLink';

function Share(props) {
    const [getLink, setLink] = useState("")

    useEffect(() => {
        handleGetLink();
    }, [])


    const handleGetLink = () => {
        setLink(window.location.href.replace('#', '%23'))
    }

    const handleToCopy = () => {
        copy(window.location.href);
        props.onClose()
    }

    return (
        <>
            <div className="popup show">
             <header>
               <span>Compartir</span>
               <div className="close" onClick={props.onClose}><IconClose width={24} height={24} /></div>
             </header>
             <div className="content">
               <p>Comparte este enlace a través de</p>
               <ul className="icons">
                 <a href={"fb-messenger://share/?link="+getLink  }  target="_blank" rel="noreferrer"><IconMessenger width={24} height={24}/></a>
                 <a href={"https://twitter.com/intent/tweet?text=" + getLink} target="_blank" rel="noreferrer"><IconTwitter width={24} height={24}/></a>
                 <a href={"mailto:example@email.com?body=" + getLink} target='_blank' rel="noreferrer"><IconEnvelope width={24} height={24}/></a>
                 <a href={"https://api.whatsapp.com/send?text=" + getLink} target="_blank" rel="noreferrer"><IconWhatsapp width={24} height={24}/></a>
                 <a href={"https://t.me/share/url?url=" + getLink} target="_blank" rel="noreferrer"><IconTelegram width={24} height={24}/></a>
               </ul>
               <p>O copiar el  enlace</p>
               <div className="field">
                 <IconLink width={45} height={45}/>
                 <input type="text" value={window.location.href} readOnly/>
                 <button onClick={handleToCopy}>copiar</button>
               </div>
             </div>
            </div>
        </>
    );
}

export default Share;