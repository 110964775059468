import React from 'react';

const IconContactSupport = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M493.848-220.001H460q-133.646 0-226.822-93.154-93.177-93.154-93.177-226.768 0-133.615 93.163-226.845 93.163-93.231 226.791-93.231 66.814 0 124.852 24.962 58.038 24.961 101.654 68.576 43.615 43.616 68.576 101.58Q779.999-606.918 779.999-540q0 124.384-67.231 229.768-67.23 105.384-170.306 169.614-7.692 4.039-15.384 4.443-7.692.404-14.154-3.443-6.461-3.846-10.923-9.922-4.461-6.077-5.076-14.769l-3.077-55.692ZM560-226q71-60 115.5-140.5T720-540q0-109-75.5-184.5T460-800q-109 0-184.5 75.5T200-540q0 109 75.5 184.5T460-280h100v54Zm-99.786-103.078q14.632 0 24.901-10.328 10.269-10.329 10.269-24.962 0-14.632-10.329-24.901-10.328-10.269-24.961-10.269-14.632 0-24.901 10.329-10.269 10.328-10.269 24.961 0 14.632 10.328 24.901 10.329 10.269 24.962 10.269Zm-85.037-299.384q8.977 3.846 17.934.075 8.958-3.771 14.658-12.152 8.615-12.769 22.154-20.038 13.538-7.269 30.846-7.269 26.736 0 43.445 14.461 16.71 14.462 16.71 38.075 0 14.618-7.693 28.195-7.692 13.577-26.692 32.577-25.385 23.538-37 42.269-11.615 18.731-11.615 37.192 0 9.931 6.825 16.965 6.825 7.035 16.461 7.035t16.097-7.269q6.462-7.269 8.923-17.346 3.462-14.692 15.692-29.269 12.231-14.577 25.924-28.27Q549.615-555 559.73-575.487q10.116-20.487 10.116-40.974 0-43.572-29.962-70.094-29.961-26.521-78.345-26.521-30.712 0-56.625 14.923-25.914 14.923-40.639 38.37-5.197 9.552-2.044 18.514 3.154 8.961 12.946 12.807ZM460-513Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconContactSupport;
