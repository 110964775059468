import CarritoDto from "../models/carritoDto";
import CarritoItemDto from "../models/carritoItemDto";

export const createAdapterCarrito = (endpoint) => {
    return new CarritoDto(endpoint)
}

export const createAdapterCarritoItem = (endpoint) => {
    return new CarritoItemDto(endpoint)
}
