import React from 'react';

const IconMenu = (props) => {
  return (
    <svg
      { ...props }
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path d="M170.001-254.616q-12.75 0-21.375-8.629t-8.625-21.384q0-12.756 8.625-21.371 8.625-8.615 21.375-8.615h619.998q12.75 0 21.375 8.629 8.625 8.628 8.625 21.384 0 12.755-8.625 21.37-8.625 8.616-21.375 8.616H170.001Zm0-195.385q-12.75 0-21.375-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.625-8.615 21.375-8.615h619.998q12.75 0 21.375 8.628 8.625 8.629 8.625 21.384 0 12.756-8.625 21.371-8.625 8.615-21.375 8.615H170.001Zm0-195.384q-12.75 0-21.375-8.629-8.625-8.628-8.625-21.384 0-12.755 8.625-21.37 8.625-8.616 21.375-8.616h619.998q12.75 0 21.375 8.629t8.625 21.384q0 12.756-8.625 21.371-8.625 8.615-21.375 8.615H170.001Z" 
      fill={props.fill}
      />
    </svg>
  );
};

export default IconMenu;
