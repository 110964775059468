import { createAdapterDireccion  } from "../../../adapter/direccionAdapter";
import { deleteAsync, getAsync, postAsync, putAsync } from "../httpServiceIntelafApi";
import { getAuthorization } from '../../../shared/TokenHeaders';

const URI = '/Direcciones';

export const getDireccionAsync = () => {
    return getAsync(URI, getAuthorization()).then((response) => {
        return response.map(direccion => createAdapterDireccion(direccion));
    });
};

export const postAddDireccionAsync = (direccion) => {
    return postAsync(URI, getAuthorization(), direccion).then((response) => {
        return createAdapterDireccion(response);
    });
};

export const putUpdateDireccionAsync = (direccion) => {
    return putAsync(URI, getAuthorization(), direccion).then((response) => {
        return createAdapterDireccion(response);
    });
};

export const deleteDireccionAsync = (id) => {
    return deleteAsync(`${URI}/${id}`, getAuthorization(), null).then((response) => {
        return createAdapterDireccion(response);
    });
};
