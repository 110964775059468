
export function Browser() {
    var ua= navigator.userAgent, tem, 
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
};

export function Sistema() {
    if(navigator.userAgent.match(/Android/i)) {
        return "Movil Android"
    }
    if(navigator.userAgent.match(/webOS/i)) {
        return "Web OS"
    }
    if(navigator.userAgent.match(/iPhone/i)) {
        return "IPhone"
    }
    if(navigator.userAgent.match(/iPad/i)) {
        return "IPad"
    }
    if(navigator.userAgent.match(/iPod/i)) {
        return "IPod"
    }
    if(navigator.userAgent.match(/BlackBerry/i)) {
        return "BlackBerry"
    }
    if(navigator.userAgent.match(/Windows Phone/i)) {
        return "Windows Phone"
    }
    if (navigator.appVersion.indexOf("Win") !== -1) {
        return "Windows OS";
    } 

    if (navigator.appVersion.indexOf("Mac") !== -1) {
        return "MacOS"
    }

    if(navigator.appVersion.indexOf("X11") !== -1) {
        return "Unix OS";
    }

    if(navigator.appVersion.indexOf("Linux") !== -1) {
        return "Linux OS"
    }

    return navigator.platform;
}