import React from 'react'
import IconQuestionExchange from "../../../../components/icons/IconQuestionExchange";

function OlvidarMetodoEntrega({ onClick }) {
    return (
        <div style={{display:"flex", justifyContent:"center", marginTop:"20px", cursor:"pointer"}} >
            <p style={{display:"flex", alignItems:"center", padding:"0", margin:"0"}} onClick={onClick}>
                <IconQuestionExchange heigth={18} width={18} fill={"#f0761d"} /> 
            <span style={{color:"#878787", fontSize:"15.5px", marginLeft: "8px"}}>Olvidar método de entrega</span></p>
        </div>
    )
}

export default OlvidarMetodoEntrega