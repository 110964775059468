import React from 'react';

const IconPolicy = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M480-104.771q-6.231 0-12.231-1.192t-11.64-3.192q-126.898-45-201.513-159.577Q180.001-383.308 180.001-516v-180.153q0-22.791 13.106-41.024t33.894-26.437l227.692-85q12.846-4.615 25.307-4.615 12.461 0 25.307 4.615l227.692 85q20.788 8.204 33.894 26.437 13.106 18.233 13.106 41.024V-516q0 67.307-20.231 132.268t-59.674 121.345q-7.172 10.693-19.829 11.193-12.658.5-22.035-8.731L555.384-362.771q-16.461 11.385-35.807 17.077-19.346 5.693-39.577 5.693-57.749 0-98.874-41.125-41.125-41.125-41.125-98.874 0-57.749 41.125-98.874 41.125-41.125 98.874-41.125 57.749 0 98.874 41.125 41.125 41.125 41.125 98.874 0 20.254-5.5 39.127t-15.731 35.949l74.616 74.617q21.539-42.154 34.077-88.5Q720-465.154 720-516v-180.538q0-3.846-2.116-6.923-2.115-3.078-5.962-4.616l-227.691-85q-1.923-.769-4.231-.769t-4.231.769l-227.691 85q-3.847 1.538-5.962 4.616-2.116 3.077-2.116 6.923V-516q0 121 68 220t172 132q18.308-5.769 35.731-13.923 17.423-8.154 34.347-19.077 10.538-6.462 23.053-4.975t20.099 11.897q7.308 10.539 4.705 22.486-2.602 11.947-13.012 18.667-19.616 12-39.231 22.077-19.615 10.077-41.7 18.077-5.761 2-11.761 3-6 1-12.231 1ZM480-400q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400ZM4.923-77.385Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconPolicy;
