import { useState } from "react";
import { CircularProgress } from '@material-ui/core';
import './css/ButtonCollapse.css';
import IconKeyBoardArrowDown from "../icons/IconKeyboardArrowDown";

const ButtonCollapse = (props) => {
    const [rotate, setRotate] = useState(false);
    const iconContainerStyle = {
        display: 'flex',
        alignItems: 'center',
    };

    return (
        <>
            <button className={`buttonCollapse no-select ${props.className}`}
                onClick={(e) => { props.onClick(e); setRotate(!rotate); }}
                disabled={props.loading}
            >
                {
                    props.loading ?
                        <>
                            <CircularProgress color='inherit' size={25} />
                        </> :
                        <>
                            <span style={iconContainerStyle}>{props.icon}</span>
                            <span className='text'>{props.text}</span>
                            <span
                                id='buttonCollapse_keyboard_arrow_id'
                                style={{ transform: `${rotate ? 'rotateX(180deg)' : 'rotateX(360deg)'}`, display: 'flex', alignItems: 'center' }}
                            >
                                <IconKeyBoardArrowDown width={23.75} height={24} fill={'rgba(0,0,0,.35)'}/>
                            </span>
                        </>
                }
            </button>
            <div>
                {props.children}
            </div>
        </>
    )
}

export default ButtonCollapse;