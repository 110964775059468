import { styled } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';

const LinkWithIcon = ({ icon, title, pro, to, media }) => {
  return (
    <Content to={to}>
            { icon }

        {
            media ? <></> :
            <div>
                <Pronombre>{pro}</Pronombre>
                <Title>{title}</Title>
            </div>
        }
    </Content>
  )
}

const Content = styled (Link)({
    display: 'flex',
    height: '64px',
    alignItems: 'center',
    gap: '6px',
    color: 'gray',
    '&:hover': {
        color: '#F0761D',
        textDecoration: 'none'
    },
    '&:focus': {
        color: '#F0761D',
        textDecoration: 'none'
    }
})

const Title = styled('p')({
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '1',
    fontFamily: 'Inter',
    margin: 0,
    padding: 0,
});

const Pronombre = styled(Title)({
    fontSize: '12px', 
    fontWeight: '400'
});

export default LinkWithIcon;