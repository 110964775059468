import { useSelector, useDispatch } from 'react-redux';
import { createUsuario, updateUsuario, resetUsuario } from '../state/usuarioSlice';
import { updateCarrito } from '../state/carritoSlice';

import { 
  postLoginUserAsync, 
  putUpdateDataUserAsync, 
  putUpdateCredencialsUserAsync, 
  deleteUsuarioAsync, 
  getUsuarioLogoutAsync,
  postValidarCuentaUsuarioAsync,
  postReenviarCodigoEmailAsync,
  putUsuarioCompletarDatosAsync,
} from '../../services/intelafApiClient/repository/usuarioRepository';

import { 
  postUpdateCarritoAsync, 
  getShowCarritoAsync 
} from '../../services/intelafApiClient/repository/carritoRepository';

import metodoDePago from '../../enums/metodoDePago';
import { store } from '../store';
import { updateCheckout } from '../state/checkOutSlice';

const useUsuarioHandler = () => {

  //Redux
  const dispatch = useDispatch();
  const usuario  = useSelector((store) => store.usuario); 
  const carrito  = useSelector((store) => store.carrito);  
  const checkout = useSelector((store) => store.checkout);
  
  //methods
  const handleLoginUserAsync = async (email, password) => {
    let usuarioCreated = await postLoginUserAsync(email, password);
    
    await dispatch(createUsuario(usuarioCreated));

    if(carrito.IsCarritoActivo) {
      await postUpdateCarritoAsync(carrito.idCarrito);

      let metodoPago = usuarioCreated.IsMayorista ? metodoDePago.MONETARIO : checkout.MetodoDePago;
      let carritoUpdate = await getShowCarritoAsync(carrito.idCarrito, metodoPago);
      
      await dispatch(updateCarrito(carritoUpdate));
      await dispatch(updateCheckout({ MetodoDePago: metodoPago  }));
    }
  }

  const handleUpdateUserAsync = async(usuario) => {
    await putUpdateDataUserAsync(usuario);
    await dispatch(updateUsuario(usuario));
  }

  const handleUpdatePasswordAsync = async(usuario) => {
    await putUpdateCredencialsUserAsync(usuario);
    await dispatch(updateUsuario({ ...usuario, Clave: '', ClaveRepeat: '' }));
  }

  const handleGetState = () => {
    return store.getState().usuario;
  }

  const handleDeleteUserAsync = async(usuario) => {
    await deleteUsuarioAsync(usuario.Usuario);
    await dispatch(updateUsuario({}));
  }

  const handleResetUsuarioAsync = async() => {
    await getUsuarioLogoutAsync()
    await dispatch(resetUsuario());
  }

  const handleResetUsuario = () => {
    dispatch(resetUsuario());
  }

  const handleUsuarioActiveAsync = async(codigo) => {
    let response = await postValidarCuentaUsuarioAsync(codigo)
    await dispatch(updateUsuario({ UsuarioValido: response }))
  }

  const handleReenviarEmailAsync = async() => {
    await postReenviarCodigoEmailAsync(usuario.Usuario);
  }

  const handleUsuarioCompletarAsync = async(datos) => {
    let usuarioCreated = await putUsuarioCompletarDatosAsync(datos);
    
    await dispatch(createUsuario(usuarioCreated));

    if(carrito.IsCarritoActivo) {
      await postUpdateCarritoAsync(carrito.idCarrito);

      let metodoPago    = usuarioCreated.IsMayorista ? metodoDePago.MONETARIO : checkout.MetodoDePago;
      let carritoUpdate = await getShowCarritoAsync(carrito.idCarrito, metodoPago);
      
      await dispatch(updateCarrito(carritoUpdate));
      await dispatch(updateCheckout({ MetodoDePago: metodoPago  }));
    }
  }


  return {
    usuario,
    handleLoginUserAsync,
    handleUpdateUserAsync,
    handleGetState,
    handleUpdatePasswordAsync,
    handleDeleteUserAsync,
    handleResetUsuarioAsync,
    handleResetUsuario,
    handleUsuarioActiveAsync,
    handleReenviarEmailAsync,
    handleUsuarioCompletarAsync
  }
}

export default useUsuarioHandler;