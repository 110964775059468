import { createSlice } from "@reduxjs/toolkit";

const getSessionFromSucursale = () => {
    const sucursales = sessionStorage.getItem('app-sucursales');
    return sucursales ? JSON.parse(sucursales) : []
}

const setSessionFromSucursales = (sucursales) => {
  sessionStorage.setItem('app-sucursales', JSON.stringify(sucursales));
}

const handlerResetSucursales = () => {
  setSessionFromSucursales([]);
  return [];
}

const handlerCreateSucursales = (state, action) => {
  let sucursales = action.payload;
  setSessionFromSucursales(sucursales);
  return sucursales;
}

const sucursalesSlice = createSlice({
    name: 'sucursales',
    initialState: getSessionFromSucursale(),
    reducers: {
      resetSucursales: handlerResetSucursales,
      createSucursales: handlerCreateSucursales,
    },
});

export const sucursalesReducer = sucursalesSlice.reducer;
export const { resetSucursales, createSucursales } = sucursalesSlice.actions;