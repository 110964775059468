import { useEffect, useState } from 'react';
import { getUltimaActualizacionInventarioAsync } from '../repository/ultimaActualizacionRepository'
import useExcepcion from '../../../shared/hooks/useExcepcion';
import { startTransition } from 'react';


const useUltimaActualizacionInventario = () => {
    // state
    const [loader, setLoader] = useState(true);
    const [ultimaActualizacionInventario, setUltimaActualizacionInventario] = useState("01/01/1900 00:00");

    // Hooks
    const { handleMessageException } = useExcepcion();

    useEffect(() => {
        setLoader(true);
        startTransition(() => {
            getUltimaActualizacionInventarioAsync().then(d => {
                let updateDate = new Date(d)
                setUltimaActualizacionInventario(updateDate.getDate() + "/" + (updateDate.getMonth() + 1) + "/" + updateDate.getFullYear() +  " a las " + updateDate.getHours().toString().padStart(2, '0') + ":" + updateDate.getMinutes().toString().padStart(2, '0'))
            }).catch(err => {
                handleMessageException(err);
            }).finally(() => {
                setLoader(false);
            })
        })
        //eslint-disable-next-line
    }, [])
    
    return {loader, ultimaActualizacionInventario}
}

export default useUltimaActualizacionInventario;