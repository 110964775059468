import React, { useState, useEffect } from "react";
import { Divider, Modal } from "@material-ui/core";
import { Box } from "@material-ui/core";
import ButtonWithIcon from "../../../../components/controls/ButtonWithIcon";
import Loader from "../../../../components/misc/Loader";
import IconClose from "../../../../components/icons/IconClose";
import IconInfo from "../../../../components/icons/IconInfo";
import SwitchMetodoEntrega from '../SwitchMetodoEntrega';
import useCheckOutService from './services/useCheckOutService';
import useSucursalesService from './services/useSucursalesService';
import SucursalCard from "../SucursalCard";
import metodoDeEnvio from "../../../../enums/metodoDeEnvio";
import OlvidarMetodoEntrega from "../OlvidarMetodoEntrega";
import './index.css';

const ModalMetodoDeEntrega = ({ onClose, open, codigo = null}) => {
    
    const { checkout, loader: ckeckLoader, handleCreateUpdateCarrito, handleOlvidarCarrito} = useCheckOutService();
    const { usuario, loader: sucuLoader, sucursalesFilter: sucursales} = useSucursalesService(codigo);
         
    //State
    const [metodoDeEntrega, setMetodoDeEntrega] = useState(checkout.MetodoDeEnvio);

    useEffect(() => {
        if(!checkout.IsCarritoActivo) {
            setMetodoDeEntrega(metodoDeEnvio.DOMICILIO);
        }
        
        // eslint-disable-next-line
    }, [open])

    return (
        <Modal
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ zIndex: '2300', }}
        >
            <Box className="modal-metodo-container">
                <span className="modal-metodo-close no-select" onClick={()=> onClose(false)}>
                    <IconClose height={24} width={24} fill={"#333333"} />
                </span>
                <div className="card-metodo no-select">
                    <p>Selecciona método de entrega</p>
                    <SwitchMetodoEntrega
                        metodo={metodoDeEntrega}
                        onClick={(e) => setMetodoDeEntrega(e)}
                    />
                    { checkout.IsCarritoActivo ? <OlvidarMetodoEntrega onClick={() => handleOlvidarCarrito(onClose)}/> : <></> } 
                    <br/>
                    <Divider/>
                    <br/>
                    {
                        sucuLoader || ckeckLoader ? <Loader/> : 
                        <>
                            {
                                metodoDeEntrega === metodoDeEnvio.DOMICILIO ?
                                <>
                                    {
                                        sucursales.filter(x => x.CodigoSucursal === 5).length > 0 ? 
                                        <>
                                            <ButtonWithIcon
                                                text='Seleccionar'
                                                loading={false}
                                                height={45}
                                                textSize={14}
                                                iconSize={23}
                                                className={'buttonWithIcon-orange'}
                                                onClick={() => {
                                                    let sucursalDto = sucursales.find(x => x.CodigoSucursal === 5);
                                                    handleCreateUpdateCarrito(sucursalDto, metodoDeEntrega, codigo, onClose)
                                                }}
                                                ariaLabel={'Seleccionar'}
                                            />   
                                        </> : <>
                                        <p style={{ fontSize: '14px', fontWeight: '400' }}>No hay existencia del producto para envío a domicilio</p>
                                        </>
                                    }

                                </> : <>
                                    <div className="modal-metodo-scroll">
                                    {
                                        sucursales.map(sucursal => (
                                            <SucursalCard
                                                key={sucursal.CodigoSucursal}
                                                sucursal={sucursal}
                                                onClick={() => handleCreateUpdateCarrito(sucursal, metodoDeEntrega, codigo, onClose)}
                                            />
                                        ))
                                    }
                                    </div>
                                </>
                            }
                        </>
                    }

                    {
                        usuario.IsMayorista ? 
                        <>
                            <br/>
                            <div style={{ display: "flex" }}>
                                <IconInfo height={18} width={18} fill={"#FF0000"} />
                                <p style={{ fontSize: "12px", margin: "0px", marginLeft: '3px' }}>Para clientes mayoristas, solo se realizan envíos a domicilio a departamentos.</p>
                            </div>
                        </> : <></>
                    }
                </div>
            </Box>
        </Modal>
    )
}

export default ModalMetodoDeEntrega;