import { useState } from "react";
import useCuotasBancoService from "./service/useCuotasBancoService"
import ButtonCollapse from "../../controls/ButtonCollapse";
import IconPayments from "../../icons/IconPayments";

const CuotaBanco = ({ codigo }) => {
    const {loader: loaderCuotas, getCuotasBancoAsync } = useCuotasBancoService(codigo)
    const [openCuotas, setOpenCuotas] = useState(false);
    const [cuotas, setCuotas] = useState([])

    const handleCollapseCuotas = async ()=>{
        if(!openCuotas) setCuotas(await getCuotasBancoAsync())
        setOpenCuotas(!openCuotas)
    }



    return (<>
        <ButtonCollapse
            icon={<IconPayments width={23.75} height={24} fill={"rgba(0,0,0,0.35)"} />}
            text='Ver las cuotas disponibles'
            loading={loaderCuotas}
            onClick={handleCollapseCuotas}
            className='buttonCollapse-light-grey'
        >

            {
                openCuotas ?
                    <>
                        <div className='container-cuotas'>
                            {
                               cuotas.map((cuota) => (<CoutasCard key={cuota.Orden} cuota={cuota} />))
                            }
                        </div>
                    </> : <></>
            }
        </ButtonCollapse>

    </>)

}


const CoutasCard = (props) => {
    const { cuota } = props;
    const [showCuotas, setShowCuota] = useState(false);

    return (
        <div className='card-cuotas' onClick={(e) => { setShowCuota(!showCuotas) }}>
            <div className={ cuota.IdBanco === 'OTROS' ? "imagen-flex" : 'imagen' }>
                <img src={`https://www.intelaf.com/${cuota.Imagen}`} alt='imagen'></img>
                {
                    cuota.IdBanco === 'OTROS' ? <span>Cuota de otros bancos</span> : <></>
                }
            </div>
            {
                showCuotas ?
                <>
                    <div className='cuotas'>
                        <div className='cuotas-card'>
                            <div className='title-cuotas'>
                                <p>Cuotas</p>
                                <p>Monto</p>
                            </div>
                            {
                                cuota.Detalle.map((item) => (
                                    <div key={item.Cuotas} className='cuotas-montos'>
                                        <p>{item.Cuotas}</p>
                                        <p>{item.Monto}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </> : <></>
            }
        </div>
    )
}



export default CuotaBanco