import React, { useState } from 'react';
import './style.css';
import TextBox from '../../../../controls/TextBox';
import Button from '../../../../controls/Button';
import useNewLetter from './service/useNewLetter';

const NeewsLetter = () => {
    const [correoNewsLetter, setCorreoNewsLetter] = useState("");
    const { loader, handleSubscritionLatter }     = useNewLetter();

    const handleSendSubscritionLatter = async (email) => {
        await handleSubscritionLatter(email);
        setCorreoNewsLetter('');
    }

  return (
    <div id="footer">
        <div className="footer-newsletter">
            <div className="grupo-footer">
            <div className="frame-linea">
            </div>
                <div className="newsletter">
                    <section className="newsletter-suscribirme">
                        <h3 className="titulo-newsletter">¡Suscríbete a nuestro Newsletter!</h3>
                        <p>Serás el primero en enterarte de nuestras novedades, descuentos, lanzamientos y más.</p>
                        <div className="newsletter-suscribirme-input">
                            <TextBox
                                id="inputCorreoNews"
                                pattern="[\w\.\-]{1,30}@[\w\.\-]+\.[a-z]{2,3}"
                                Label="INGRESA TU CORREO"
                                valor={correoNewsLetter}
                                onChange={(e) => { setCorreoNewsLetter(e.target.value) }}
                            ></TextBox>
                            <Button 
                                Texto="Suscribirme" 
                                ButtonLoading={loader} 
                                className="btn-intelaf btn-intelaf-orange" 
                                onClick={() => handleSendSubscritionLatter(correoNewsLetter)}
                            />
                        </div>
                    </section>
                    <section className="newsletter-cliente">
                        <img src="https://www.intelaf.com/img/footer/cliente_newsletter.png" className="newsletter-cliente-img" width={60} height={49} alt="Imagen atención al cliente" loading="lazy"></img>
                        <div className="newsletter-cliente-texto">
                            <h3 className="titulo-newsletter">Atención al Cliente</h3>
                            <p>Lunes a Sábado de 8 a 18 hrs.</p>
                            <p className="subtitulo-newsletter">(502) 2328-0000</p>
                        </div>
                    </section>
                    <section className="newsletter-whatsapp">
                        <img src="https://www.intelaf.com/img/footer/whatsapp_newsletter.png" className="newsletter-whatsapp-img" width={50} height={49} alt="Logo WhatsApp" loading="lazy"></img>
                        <div className="newsletter-whatsapp-texto">
                            <h3 className="titulo-newsletter">WhatsApp</h3>
                            <p className="interlineado-parrafo-newsletter">Lunes a Viernes de 8 a 20 hrs. fin de semana y días festivos de 9 a 19 hrs.</p>
                            <p className="subtitulo-newsletter">(502) 5833 - 0003</p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NeewsLetter
