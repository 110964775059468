import React from 'react';

const IconBuildCircle = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path d="m628.847-281.692 46.768-46.769q5.616-5.58 5.616-13.021 0-7.44-5.616-13.055L531.538-499.23q5.154-11.385 7.731-23.731 2.577-12.346 2.577-27.5 0-54.935-39.146-93.968-39.145-39.032-94.239-39.032-9.908 0-18.915 1t-17.995 4.231q-8.089 3.23-9.897 12.076-1.808 8.847 4.423 15.077L437-579.769l-59.077 59.077L306.231-592q-6.231-6.23-15.077-4.423-8.846 1.808-12.231 10.009-2.538 9.112-3.807 18.225-1.27 9.112-1.27 18.958 0 55.094 39.146 94.239 39.145 39.146 94.239 39.146 14.111 0 26.594-2.77 12.483-2.769 24.637-7.538l144.693 144.077q5.615 5.616 12.846 5.808 7.23.192 12.846-5.423Zm-148.78 181.691q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" fill={props.fill}/>
    </svg>
  );
};

export default IconBuildCircle;
