import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { Modal, Typography, Box } from '@material-ui/core';
import ButtonWithIcon from "../../components/controls/ButtonWithIcon";

const AppUpdateContext = createContext();

export const AppUpdateProvider = ({ children }) => {

    const [open, setOpen] = useState(false); // El modal empieza abierto

    const searchForUpdates = useCallback(() => {
        const appNeedUpdate = sessionStorage.getItem('app-need-update');
        if (appNeedUpdate && JSON.parse(appNeedUpdate) === true) {
            setOpen(true)
        }
    }, [])

    useEffect(() => {
        if (!open) {
            searchForUpdates() // Abre el modal si no está abierto
        }
    }, []);


    useEffect(() => {
        const interval = setInterval(() => {
            if (!open) {
                searchForUpdates() // Abre el modal si no está abierto
            }
        }, 900000); // Cada 15 segundos intenta abrir el modal

        return () => clearInterval(interval); // Limpia el intervalo al desmontar
    }, [open]);

    const handleClose = () => {
        setOpen(false)
    }

    const handleUpdate = () => {
        sessionStorage.setItem('app-need-update', JSON.stringify(false));
        setOpen(false);
        window.location.reload();
    };

    return (
        <AppUpdateContext.Provider value={searchForUpdates}>
            {children}
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            backgroundColor: 'white',
                            padding: 20,
                            outline: 'none',
                            boxShadow: 24,
                            borderRadius: 25
                        }}
                    >
                        <Typography variant="h5" id="simple-modal-title">
                            Nueva Versión Disponible
                        </Typography>
                        <Typography variant="body2" id="simple-modal-description" style={{ margin: '20px 0', fontSize: '12px' }}>
                            Hay una nueva versión del sitio web. ¿Quieres actualizar ahora?
                        </Typography>


                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '15px',
                            }}
                        >
                            <ButtonWithIcon
                                iconSize={18}
                                text={'Más tarde'}
                                textSize={14}
                                textWeight={600}
                                height={48}
                                flexDirection={'row-reverse'}
                                className='buttonWithIcon-whiteghost'
                                aria-label={'Más tarde'}
                                onClick={handleClose}
                                type='button'
                            />
                            <ButtonWithIcon
                                iconSize={18}
                                text={'Actualizar'}
                                textSize={14}
                                textWeight={600}
                                height={48}
                                flexDirection={'row-reverse'}
                                className='buttonWithIcon-orange'
                                aria-label={'Actualizar'}
                                onClick={handleUpdate}
                                type={'submit'}
                            />
                        </div>



                    </Box>
                </Modal>
            </div>
        </AppUpdateContext.Provider>
    )
}

export const useAppUdate = () => {
    return useContext(AppUpdateContext);
};
