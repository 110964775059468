import { createSlice } from "@reduxjs/toolkit";
import BannerDto from '../../models/bannerDto';

const getSessionFromBanners = () => {
    const banners = sessionStorage.getItem('app-banners');
    return banners ? JSON.parse(banners) : new BannerDto({});
}
   
const setSessionFromBanners = (banners) => {
  sessionStorage.setItem('app-banners', JSON.stringify(banners));
}

const handlerResetBanners = () => {
  let bannerDto = new BannerDto({});
  setSessionFromBanners(bannerDto.toJSON());
  return bannerDto;
}

const handlerCreateBanners = (state, action) => {
  let banners = action.payload;
  setSessionFromBanners(banners);
  return banners;
}

const bannerSlice = createSlice({
    name: 'banners',
    initialState: getSessionFromBanners(),
    reducers: {
      resetBannersMain: handlerResetBanners,
      createBannersMain: handlerCreateBanners,
    },
});

export const bannerReducer = bannerSlice.reducer;
export const { resetBannersMain, createBannersMain } = bannerSlice.actions;