import { useMemo, useState } from 'react';
import { useMetodoDeEntrega } from "../../../contexts/MetodoDeEntrega";
import { useNavigate } from 'react-router-dom';
import { getProductosPromoCondi } from '../repository/productoRepository';
import { usePromoCondi  } from '../../../contexts/PromoCondi';
import useCheckOutHandler from '../../../redux/handler/useCheckOutHandler';
import useExcepcion from '../../../shared/hooks/useExcepcion';
import Swal from '../../../shared/SweetAlert';

const useCarrito = (producto) => {

    //state
    const [loader, setLoader] = useState(false);

    //hooks
    const { 
        checkout,
        handleProductoAddCarritoCheCkOutAsync, 
        handleUpdateProductoCarritoCheckOutAsync, 
        handleDeleteProductoCarritoCheckOutAsync,
        handleClearProductoCarritoCheckOutAsync,
        handleAddCuponCarritoCheckOutAsync,
        handleDeleteCuponCarritoCheckOutAsync
    } = useCheckOutHandler();

    const { handleMessageException } = useExcepcion();
    const { openDialogEntrega }      = useMetodoDeEntrega();
    const { openDialogPromo  }       = usePromoCondi();

    const navigate = useNavigate();

    //resultado
    const disponibilidad = useMemo(() => {
        if(Object.keys(checkout.SucursalDeEntrega).length > 0) {
            if(producto !== undefined && producto !== null) {
                return producto.Existencia.some(sucursal => sucursal.CodigoSucursal.toString() === checkout.SucursalDeEntrega.CodigoSucursal.toString());
            } else {
                return true;
            }
        } else {          
          return true;
        }
    }, [checkout, producto])

    const handleAddProductoCarrito = async (cantidad) => {
        try {
            setLoader(true);

            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              'event': 'add_to_cart',
              'ecommerce': {
                'add': {
                  'products': [{
                    'id': producto.Codigo,
                    'name': (producto.Descripcion || ''),
                    'category': (producto.AreaFuncional || ''),
                    'brand': (producto.Marca || ''),
                    'price': (producto.PrecioNormal || ''),
                    'quantity': 1
                  }]
                }
              }
            });
        

            if(disponibilidad) {
                if(checkout.IsCarritoActivo) {
                    await handleProductoAddCarritoCheCkOutAsync(producto.Codigo, cantidad);
                    
                    //Obtiene las promos condis
                    let promoCondi = await getProductosPromoCondi(producto.Codigo);       

                    if(promoCondi.length > 0) {
                        openDialogPromo({ products: promoCondi, onAddPromo: handleAddProductoPromoCondi })
                        .then(() => {
                            navigate('/carrito');
                        });

                    } else {

                        Swal.fire({
                            title: '',
                            text: 'Producto añadido al carrito',
                            icon: 'success',
                            showDenyButton: true,
                            confirmButtonText: 'Ir a mi carrito',
                            confirmButtonColor: 'DarkOrange',
                            denyButtonText: "Seguir comprando",
                            denyButtonColor: "Orange"
                        }).then((result) => {
                            if (result.isConfirmed) {
                              navigate("/carrito")
                            }
                        });

                    }

                } else {

                    openDialogEntrega({ producto: producto.Codigo}).then(async(response) => {
                        if(response) {
                            let promoCondi = await getProductosPromoCondi(producto.Codigo);

                            if(promoCondi.length > 0) {
                                openDialogPromo({ products: promoCondi, onAddPromo: handleAddProductoPromoCondi })
                                .then(() => {
                                    navigate('/carrito');
                                });
                            }
                        }
                    });
                }
            } else {

                Swal.fire({
                    title: 'Lo sentimos',
                    text: 'El producto no se encuentra disponible para el método de entrega seleccionado',
                    icon: 'warning',
                    showDenyButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'Orange',
                    denyButtonText: "Cambiar método de entrega",
                }).then(async (result) => {
                    if (result.isDenied) {
                        openDialogEntrega({ producto: producto.Codigo}).then(async(response) => {
                            if(response) {
                                let promoCondi = await getProductosPromoCondi(producto.Codigo);
        
                                if(promoCondi.length > 0) {
                                    openDialogPromo({ products: promoCondi, onAddPromo: handleAddProductoPromoCondi })
                                    .then(() => {
                                        navigate('/carrito');
                                    });
                                }
                            }
                        });
                    }
                });
            }

        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

    const handleUpdateProductoCarrito = async (codigo, cantidad) => {
        try {
            setLoader(true);
            await handleUpdateProductoCarritoCheckOutAsync(codigo, cantidad);     
            Swal.fire({
                title: 'Exitoso',
                text: 'El producto se ha añadido correctamente.',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'Orange',
            });

        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

    const handleDeleteProductoCarrito = async (codigo) => {
        try {
            setLoader(true);
            await handleDeleteProductoCarritoCheckOutAsync(codigo);     
            Swal.fire({
                title: 'Exitoso',
                text: 'El producto se ha eliminado correctamente.',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'Orange',
            });

        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

    const handleClearProductoCarrito = async () => {
        try {
            setLoader(true);
            await handleClearProductoCarritoCheckOutAsync();     
            Swal.fire({
                title: 'Exitoso',
                text: 'El carrito se a vaciado.',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'Orange',
            });

        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

    const handleAddProductoPromoCondi = async(codigo, cantidad) => {
        await handleProductoAddCarritoCheCkOutAsync(codigo, cantidad); 
        Swal.fire({
            title: 'Exitoso',
            text: 'La promocio se ha añadido correctamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'Orange',
        });
    }

    const handleAddCuponCarrito = async (cupon) => {
        try {
            setLoader(true);
            await handleAddCuponCarritoCheckOutAsync(cupon);
        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

    const handleDeleteCuponCarrito = async () => {
        try {
            setLoader(true);
            await handleDeleteCuponCarritoCheckOutAsync();

        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

  return {
    loader,
    disponibilidad,
    handleAddProductoCarrito,
    handleUpdateProductoCarrito,
    handleDeleteProductoCarrito,
    handleClearProductoCarrito,
    handleAddCuponCarrito,
    handleDeleteCuponCarrito
  }
}

export default useCarrito;