import { Checkbox, FormControlLabel, styled } from '@material-ui/core';
import React from 'react';

const CardPromoCondi = ({ producto, addProducto }) => {

  return (
    <Box>
        <div style={{ gridArea: 'img' }}>
            <Imagen src={producto.Imagen}/>
        </div>
        <div style={{ gridArea: 'info' }}>
            <Marca>{producto.Marca}</Marca>
            <Descripcion>{producto.Descripcion.toCapitalize()}</Descripcion>
            <Precio>Precio normal: {producto.PrecioNormal.toCurrency()}</Precio>
            <Precio>Descuento: - {producto.PrecioDescuento.toCurrency()}</Precio>
            <Precio>SubTotal: {(producto.PrecioNormal - producto.PrecioDescuento).toCurrency()}</Precio>
        </div> 
        <div style={{ justifySelf: 'center', alignSelf: 'center', gridArea: 'check' }}>
            <LabelControl
              value="start"
              control={<Checkbox color="primary" onChange={() => addProducto(producto.Codigo)} />}
              label="Añadir"
              labelPlacement="start"
            />
        </div>
    </Box>
  )
}

const LabelControl = styled(FormControlLabel)({
    borderRadius: '16px',
    border: 'solid 1px rgba(19, 180, 188, 1)',
    padding: '0px 0px 0px 10px',
    margin: '0px',
    '& .MuiTypography-body1': {
        fontSize: '13px',
        color: 'rgba(19, 180, 188, 1)'
    },
    '& .MuiIconButton-label': {
        color: 'rgba(19, 180, 188, 1)',
    }
});

const Box = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    gridTemplateAreas: `"img info check"`,
    '@media (max-width: 500px)': {
        gridTemplateColumns: '1fr 2fr',
        gridTemplateAreas: `"img info info" 
                            "check check check"`,
        gap: '7px'
    },
})

const Imagen = styled('img')({
    width: 'auto',
    height: '100%',
    maxHeight: '100px'
});


const Marca = styled('p')({
    color: '#aaa',
    fontSize: '9px',
    fontWeight: '600',
    lineHeight: '11px',
    margin: '0px'
});

const Descripcion = styled('p')({
    color: '#46474a',
    fontSize: '13px',
    fontWeight: '500',
    marginBottom: '5px',
    '@media (max-width: 500px)': {
        fontSize: '11px'
    } 
});

const Precio = styled('p')({
    color: '#a8a8a8',
    fontSize: '13px',
    fontWeight: '400',
    letterSpacing: '-.035em',
    lineHeight: '15px',
    margin: '0px',
    '@media (max-width: 500px)': {
        fontSize: '10px'
    } 
});

export default CardPromoCondi;