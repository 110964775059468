import { useMediaQuery } from '@material-ui/core';
import { createContext, useContext, useEffect, useState } from 'react';

const ResponsiveContext = createContext();

export const ResponsiveProvider = ({ children }) => {
    const matches = useMediaQuery('(max-width:991px)');

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, [matches]);

  return (
    <ResponsiveContext.Provider value={matches}>
        {
          mounted ? children : <></>
        }
    </ResponsiveContext.Provider>
  )
}

export const useResponsive = () => {
  return useContext(ResponsiveContext);
};