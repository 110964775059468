import React from 'react';
import RecomendadoCard from '../../../components/cards/RecomendadoCard';
import { Swiper, SwiperSlide } from 'swiper/react';


// Import styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const SwiperSliderRecomendado = ({ productos }) => {
  return (
    <>
      <Swiper
          style={StyleSwipper}
          slidesPerView={5}
          spaceBetween={25}
          slidesPerGroup={4}
          breakpoints={Breakpoints}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
        }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
      >
          {
            productos.map((producto, index) => (
                <SwiperSlide key={index}>
                    <RecomendadoCard producto={producto}/>
                </SwiperSlide>
            ))
          }       
      </Swiper>
    </>
  )
}

const StyleSwipper = {
  '--swiper-navigation-color': '#f0761d',
  '--swiper-pagination-color': '#f0761d',
};

const Breakpoints = {
  320: {
    slidesPerView: 1.5,
    slidesPerGroup: 1
  },
  400: {
    slidesPerView: 1.7,
    slidesPerGroup: 1
  },
  455: {
    slidesPerView: 1.8,
    slidesPerGroup: 1
  },
  490: {
    slidesPerView: 2,
    slidesPerGroup: 1
  },
  530: {
    slidesPerView: 2.5,
    slidesPerGroup: 1
  },
  650: {
    slidesPerView: 2.8,
    slidesPerGroup: 1
  },
  720: {
    slidesPerView: 3,
    slidesPerGroup: 2
  },
  990: {
    slidesPerView: 4,
    slidesPerGroup: 3
  },
  1200: {
    slidesPerView: 5,
    slidesPerGroup: 4
  },
}

export default SwiperSliderRecomendado;

