class DireccionDto {
    constructor(address) {
        this.Id               = address.Id               || 0;
        this.Usuario          = address.Usuario          || '';
        this.Titulo           = address.Titulo           || '';
        this.Direccion        = address.Direccion        || '';
        this.Departamento     = address.Departamento     || 'Guatemala';
        this.Municipio        = address.Municipio        || 'Guatemala';
        this.Zona             = address.Zona             ?? '';
        this.NotasAdicionales = address.NotasAdicionales || '';
        this.CodigoIso        = address.CodigoIso        || '';
    }

    
    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }

    toString() {
        return `${this.Titulo}, ${this.Direccion}, Zona ${this.Zona}, ${this.Municipio}, ${this.Departamento}`;
    }

    IsValid() {
        if (!this.Titulo.match(/^[A-z0-9À-ÿ\s]{3,40}$/)) {
            throw new Error('El Alias no es valido');
        }

        if (!this.Direccion.match(/^[a-zA-Z0-9\s.-]{5,80}$/)) {
            throw new Error('La Direccion no es valida.');
        }

        if (this.Departamento.length === 0) {
            throw new Error('El Departamento es requerido.');
        }

        if (this.Municipio.length === 0) {
            throw new Error('El Municipio es requerido.');
        }

        if (!(this.Zona >= 0)) {
            throw new Error('La Zona no es valida.');
        }

        if (!this.NotasAdicionales.match(/^[\w\s.,\-À-ÿ]{0,250}$/)) {
            throw new Error('La Notas no es valida.');
        }

        if (!this.Usuario.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            throw new Error('El Usuario es no es valido.');
        }

        return true;
    }
}

export default DireccionDto;