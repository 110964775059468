import React from 'react';

const IconClose = (props) => {
  return (
    <svg
      { ...props }
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
      onClick={props.onClick}
    >
      <path
        d="M480-437.847 277.076-234.924q-8.307 8.308-20.884 8.5-12.576.193-21.268-8.5-8.693-8.692-8.693-21.076t8.693-21.076L437.847-480 234.924-682.924q-8.308-8.307-8.5-20.884-.193-12.576 8.5-21.268 8.692-8.693 21.076-8.693t21.076 8.693L480-522.153l202.924-202.923q8.307-8.308 20.884-8.5 12.576-.193 21.268 8.5 8.693 8.692 8.693 21.076t-8.693 21.076L522.153-480l202.923 202.924q8.308 8.307 8.5 20.884.193 12.576-8.5 21.268-8.692 8.693-21.076 8.693t-21.076-8.693L480-437.847Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconClose;
