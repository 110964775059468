import { styled } from '@material-ui/core';
import { DOMINIO } from '../../../../shared/Constant';
import React from 'react'

const LogoIntelaf = (props) => {
  return (<Logo { ...props } src={`${DOMINIO}/img/intelaf_logotipo.png`} alt='Intelaf_Logo' />)
}

const Logo = styled('img')(({ width = 140, height = 45 }) => ({
    width: width, 
    height: height
}));

export default LogoIntelaf;