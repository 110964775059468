import useUsuarioHandler from '../../redux/handler/useUsuarioHandler';
import useSucursalesHandler from '../../redux/handler/useSucursalesHandler';
import useCheckOutHandler from '../../redux/handler/useCheckOutHandler';
import useBannersHandler from '../../redux/handler/useBannersHandler';

const useLogout = () => {
    const { handleResetUsuario         } = useUsuarioHandler();
    const { handleResetSucursales      } = useSucursalesHandler();
    const { handleResetCarritoCheckOut } = useCheckOutHandler();
    const { handleResetBannersHome     } = useBannersHandler();

    const handleLogoutSession = () => {
      handleResetUsuario();
      handleResetSucursales();
      handleResetCarritoCheckOut();
      handleResetBannersHome();
      
      sessionStorage.clear();
      localStorage.clear(); 
      window.location.reload();
    }

  return handleLogoutSession;
}

export default useLogout;