import CarritoItemDto from "./carritoItemDto";

class CarritoDto {
    constructor(carrito) {
        this.idCarrito                 = carrito.idCarrito                 || '';
        this.CantidadItems             = carrito.CantidadItems             || 0;
        this.CantidadArticulos         = carrito.CantidadArticulos         || 0;
        this.SubTotal                  = carrito.SubTotal                  || 0.0;
        this.Descuento                 = carrito.Descuento                 || 0.0;
        this.Total                     = carrito.Total                     || 0.0;
        this.NombreSucursalCompra      = carrito.NombreSucursalCompra      || '';
        this.NombreCortoSucursalCompra = carrito.NombreCortoSucursalCompra || '';
        this.CodigoSucursalCompra      = carrito.CodigoSucursalCompra      || '';
        this.EmailSucursalCompra       = carrito.EmailSucursalCompra       || '';
        this.EsCombo                   = carrito.EsCombo                   || false;
        this.Mayorista                 = carrito.Mayorista                 || false;
        this.CodigoDescuento           = carrito.CodigoDescuento           || '';
        this.AceptaCuotas              = carrito.AceptaCuotas              || false;
        this.Items                     = this.parseItemscarrito(carrito.Items);
        this.ItemsNoDisponibles        = this.parseItemscarrito(carrito.ItemsNoDisponibles)
    }

    parseItemscarrito(Items) {
        if(Items) {
            return Items.map(item => new CarritoItemDto(item))
        }

        return []
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}

export default CarritoDto;