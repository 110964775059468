import React from 'react';
import { List, ListItem, styled } from '@material-ui/core';
import IconArrowForwardIos from '../../../icons/IconArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useEffect } from 'react';

const MenuList = ({ menu, setScroll, scroll, propertyMenu = null, setSubMenu, onReset, onSelect, par }) => {
    const navigate   = useNavigate();
    const muiListRef = useRef();

    useEffect(() => {
        if (muiListRef.current) {
            muiListRef.current.scrollTop = scroll ;
        }

        // eslint-disable-next-line
    }, [])

    const handleSelect = (e, categoria, index) => {
        e.stopPropagation();

        if(propertyMenu === null) {
            navigate(categoria.Area.includes('/') ? categoria.Area : `/precios_stock_resultado/${categoria.Area.toLowerCase()}`);
            onReset(propertyMenu, 'navigate');
        } else {
            if(categoria[propertyMenu].length === 0) {
                navigate(categoria.Area.includes('/') ? categoria.Area : `/precios_stock_resultado/${categoria.Area.toLowerCase()}`);
                onReset(propertyMenu, 'navigate');
            } else {
                setSubMenu(categoria[propertyMenu]);
                onReset(propertyMenu, 'list');
            }
        }

        onSelect(index);
    }

    const handleScroll = () => {
        const position = muiListRef.current.scrollTop;
        setScroll(position);
    };

    const handleMouseDown = (e, categoria) => {
        e.preventDefault();

        if(e.button === 2) {
            if(propertyMenu === null) {
                if(categoria.Area.includes('/')) {
                    window.open(categoria.Area.toLowerCase(), "_blank");
                } else {
                    window.open("/precios_stock_resultado/" + categoria.Area.toLowerCase(), "_blank");
                }
            } else {
                if(categoria[propertyMenu].length === 0) {
                    if(categoria.Area.includes('/')) {
                        window.open(categoria.Area.toLowerCase(), "_blank");
                    } else {
                        window.open("/precios_stock_resultado/" + categoria.Area.toLowerCase(), "_blank");
                    }
                }
            }
        }
    }

  return (
    menu.length === 0 ? <></> :
    <MuiList
        ref={muiListRef}
        onScroll={handleScroll}
        par={par}
    >
      {
        menu.map((categoria, index) => {

            if(!categoria.Area && categoria[propertyMenu].length === 0) {
                return <></>
            }

            return (
                <MuiListItem 
                    onClick={(e) => handleSelect(e, categoria, index)}
                    key={index}
                    onMouseDown={(e) => handleMouseDown(e, categoria)}
                    onContextMenu={(e) => handleMouseDown(e, categoria)}
                >
                    <MuiItemContent select={categoria.Select ? 1:0} par={par}>
                        {
                            categoria.Imagen ? <IconImg src={`https://www.intelaf.com${categoria.Imagen}`}/> : <div></div>
                        }
                        
                        <TextItem>{categoria.Nombre}</TextItem>
                        {
                            propertyMenu === null ? <div></div> :
                            categoria[propertyMenu].length === 0 ? <div></div> : <IconArrowForwardIos  width={13} height={13} fill={"#808080"}/>
                        }
                    </MuiItemContent>
                </MuiListItem>
            )
        })
      }
    </MuiList>
  )
}

const MuiList = styled(List)(({ par }) => ({
    backgroundColor: par ? '#f4f4f4' : 'white',
    paddingLeft: '8px',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    minWidth: '25%',
    zIndex: '100',
    scrollbarWidth: 'thin', // Firefox
    scrollbarColor: '#dddddd #f5f5f5', // Firefox
    '&::-webkit-scrollbar': {
        width: '5px',
    },
    '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
        backgroundColor: '#f5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#dddddd',
        borderRadius: '10px',
        animation: 'linear 3s',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'darkgray',
    }
}));

const MuiListItem = styled(ListItem)({
    margin: 0,
    padding: 0,
    display: 'block',
    borderRadius: '20px 0px 0px 20px',
    overflow: 'hidden',
    marginBottom: '6px',
    cursor: 'pointer'
});

const MuiItemContent = styled('div')(({ select = false, par }) => ({
    padding: '10px 0px',
    textDecoration: 'none',
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr',
    alignItems: 'center',
    justifyItems: 'center',
    backgroundColor: select ? ( par ? 'white' : 'rgb(242, 242, 242)' ) : 'transparent',
    '&:hover': {
        textDecoration: 'none',
        color: '#F0761D',
        backgroundColor: par ? 'white' : 'rgb(242, 242, 242)',
    }
}));

const TextItem = styled('span')({
    color: 'grey',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '-.25px',
    textDecoration: 'none',
    fontFamily: 'Inter, sans-serif',
    justifySelf: 'self-start'
});

const IconImg = styled('img')({
    width: '24px',
    height: '24px'
});

export default MenuList;
