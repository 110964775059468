//https://fonts.google.com/icons?icon.style=Rounded&icon.query=logout

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { createContext, lazy, Suspense } from "react";
import { MetodoDeEntregaProvider } from "./contexts/MetodoDeEntrega";
import { PromoCondiProvider } from './contexts/PromoCondi';
import LayoutPage from "./pages/LayoutPage";
import PreciosStockDetalladoPage from './pages/PreciosStockDetalladoPage';

import './shared/extensions';
import { ResponsiveProvider } from "./contexts/Responsive";
import Loader from "./components/misc/Loader";
import { AppUpdateProvider } from "./contexts/AppUpdate";
//import { Lazy } from "swiper/modules";

const loadingRender = () => <Loader />;
const LayoutCheckoutPage = lazy(() => import("./pages/LayoutCheckoutPage"));
const LayoutUsuarioPage = lazy(() => import('./pages/LayoutUsuarioPage'));
const HomePage = lazy(() => import("./pages/HomePage"));
const NoPage = lazy(() => import('./pages/error/404/NoPage'));
const ProductoAgotadoPage = lazy(() => import("./pages/ProductoAgotadoPage"));
const InternalServerErrorPage = lazy(() => import('./pages/error/500/InternalServerErrorPage'));
const PreciosStockPage = lazy(() => import('./pages/PreciosStockPage'));
//const PreciosStockDetalladoPage = lazy(() => import('./pages/PreciosStockDetalladoPage'));
const PreciosStockResultadoPage = lazy(() => import('./pages/PreciosStockResultadoPage'));
const CarritoPage = lazy(() => import('./pages/CarritoPage'));
const CheckOutEnvioPage = lazy(() => import('./pages/CheckOutEnvioPage'));
const CheckOutPagoPage = lazy(() => import('./pages/CheckOutPagoPage'));
const UsuarioLoginPage = lazy(() => import('./pages/UsuarioLoginPage'));
const UsuarioRegistroPage = lazy(() => import('./pages/UsuarioRegistroPage'));
const UsuarioRecuperacionPage = lazy(() => import('./pages/UsuarioRecuperacionPage'));
const UsuarioValidarPage = lazy(() => import('./pages/UsuarioValidarPage'));
const UsuarioCuentaPage = lazy(() => import('./pages/UsuarioCuentaPage'));
const UsuarioMetodoEntregaPage = lazy(() => import('./pages/UsuarioMetodoEntregaPage'));
const UsuarioDireccionesPage = lazy(() => import('./pages/UsuarioDireccionesPage'));
const UsuarioFavoritosPage = lazy(() => import('./pages/UsuarioFavoritosPage'));
const UsuarioProductosHistorialPage = lazy(() => import('./pages/UsuarioProductosHistorialPage'));
const UsuarioConfiguracionPage = lazy(() => import('./pages/UsuarioConfiguracionPage'));
const UsuarioAyudaPage = lazy(() => import('./pages/UsuarioAyudaPage'));
const CheckOutPagoEstadoPage = lazy(() => import('./pages/CheckOutPagoEstadoPage'));
const EmpresaPage = lazy(() => import('./pages/EmpresaPage'));
const BuscarPage = lazy(() => import('./pages/BuscarPage'));
const EmpleosPage = lazy(() => import('./pages/EmpleosPage'));
const SugerenciasPage = lazy(() => import('./pages/SugerenciasPage'));
const EnviosPage = lazy(() => import('./pages/EnviosPage'));
const MayoreoPage = lazy(() => import('./pages/MayoreoPage'));
const GarantiasPage = lazy(() => import('./pages/GarantiasPage'));
const DevolucionesPage = lazy(() => import('./pages/DevolucionesPage'));
const PagosPage = lazy(() => import('./pages/PagosPage'));
const TiendaPage = lazy(() => import('./pages/TiendasPage'));
const CentroServicioPage = lazy(() => import('./pages/CentroServicioPage'));
const UsuarioHomePage = lazy(() => import('./pages/UsuarioHomePage'));
const UsuarioPedidosPage = lazy(() => import('./pages/UsuarioPedidosPage'));
const UsuarioLogoutPage = lazy(() => import('./pages/UsuarioLogoutPage'));
const SuperOfertasPage = lazy(() => import('./pages/SuperOfertasPage'));
const FormaDePagoPage = lazy(() => import('./pages/FormaDePagoPage'));
const RecogerEnTiendaPage = lazy(() => import('./pages/RecogerEnTiendaPage'));
const MisionVisionPage = lazy(() => import('./pages/MisionVisionPage'));
const UsuarioCambiarContraseñaPage = lazy(() => import('./pages/UsuarioCambiarContraseñaPage'));
const UsuarioDeposPage = lazy(() => import('./pages/UsuarioDeposPage'));
const PreciosStockMarca = lazy(() => import('./pages/PreciosStockMarca'));
const CodigoDescuentoPage = lazy(() => import('./pages/CodigoDescuentoPage'));
const ProductosSimilares = lazy(() => import('./pages/ProductosSimilaresPage'));
const CombosPage = lazy(() => import('./pages/CombosPage'));
const ComboPage = lazy(() => import('./pages/ComboPage'));
const RealidadAumentada = lazy(() => import('./pages/RealidadAumentada'));
const TerminosCondicionesPage = lazy(() => import('./pages/TerminosCondicionesPage'));
const PoliticasPrivacidadPage = lazy(() => import('./pages/PoliticasPrivacidadPage'));
const PoliticasCookiesPage = lazy(() => import('./pages/PoliticasCookiesPage'));
const PoliticasPage = lazy(() => import('./pages/PoliticasPage'));
const UsuarioDocumentoPage = lazy(() => import('./pages/UsuarioDocumentoPage'));
const UsuarioActualizacionPage = lazy(() => import('./pages/UsuarioActualizacionPage'));
const PagesPage = lazy(() => import('./pages/PagesPage'));
const UsuarioEliminarCuentaPage = lazy(() => import('./pages/UsuarioEliminarCuentaPage'));
const MarcasPage = lazy(() => import('./pages/MarcasPage'));

export const AppContext = createContext();

function App() {
  return (
    <Provider store={store}>
      <AppUpdateProvider>
        <MetodoDeEntregaProvider>
          <PromoCondiProvider>
            <ResponsiveProvider>
              <BrowserRouter>
                <Suspense fallback={loadingRender()}>
                  <Routes>
                    <Route path="/" element={<LayoutPage />}>
                      <Route index element={<HomePage />} />
                      <Route path="pages/:name" element={<PagesPage />} />
                      <Route path="politicas" element={<PoliticasPage />} />
                      <Route path="precios_stock" element={<PreciosStockPage />} />
                      <Route path="precios_stock_detallado/:codigo" element={<PreciosStockDetalladoPage />} />
                      <Route path="precios_stock_resultado/:area" element={<PreciosStockResultadoPage />} />
                      <Route path="buscar/:queBuscar" element={<BuscarPage />} />
                      <Route path="buscar" element={<BuscarPage />} />
                      <Route path="marca/:queMarca" element={<PreciosStockMarca />} />
                      <Route path="similares/:codigo" element={<ProductosSimilares />} />
                      <Route path="realidadAumentada/:codigo" element={<RealidadAumentada />} />
                      <Route path="superofertas" element={<SuperOfertasPage />} />
                      <Route path="combos" element={<CombosPage />} />
                      <Route path="combo/:archivo/:numero" element={<ComboPage />} />
                      <Route path="entrega" element={<UsuarioMetodoEntregaPage />}></Route>
                      <Route path="carrito" element={<CarritoPage />} />
                      <Route path="checkout" element={<LayoutCheckoutPage />}>
                        <Route path="pago" element={<CheckOutPagoPage />}></Route>
                        <Route path="pago_estado/:orden" element={<CheckOutPagoEstadoPage />}></Route>
                        <Route path="envio" element={<CheckOutEnvioPage />}></Route>
                      </Route>
                      <Route path="registro" element={<UsuarioRegistroPage />}></Route>
                      <Route path="recuperacion" element={<UsuarioRecuperacionPage />}></Route>
                      <Route path="restablecer/:token" element={<UsuarioCambiarContraseñaPage />}></Route>
                      <Route path="deposito/:token" element={<UsuarioDeposPage />}></Route>
                      <Route path="documento/:token" element={<UsuarioDocumentoPage />}></Route>
                      <Route path="actualizar/:token" element={<UsuarioActualizacionPage />}></Route>
                      <Route path="login/*" element={<UsuarioLoginPage />}></Route>
                      <Route path="usuario" element={<LayoutUsuarioPage />}>
                        <Route index element={<UsuarioHomePage />}></Route>
                        <Route path="cuenta" element={<UsuarioCuentaPage />}></Route>
                        <Route path="favoritos" element={<UsuarioFavoritosPage />}></Route>
                        <Route path="validacion" element={<UsuarioValidarPage />}></Route>
                        <Route path="pedidos" element={<UsuarioPedidosPage />}></Route>
                        <Route path="direccion" element={<UsuarioDireccionesPage />}></Route>
                        <Route path="codigos" element={<CodigoDescuentoPage />}></Route>
                        <Route path="productos_historial" element={<UsuarioProductosHistorialPage />}></Route>
                        <Route path="configuracion" element={<UsuarioConfiguracionPage />}></Route>
                        <Route path="ayuda" element={<UsuarioAyudaPage />}></Route>
                        <Route path="logout" element={<UsuarioLogoutPage />}></Route>
                      </Route>
                      <Route path="empresa" element={<EmpresaPage />}></Route>
                      <Route path="empleos" element={<EmpleosPage />}></Route>
                      <Route path="sugerencias" element={<SugerenciasPage />}></Route>
                      <Route path="envios" element={<EnviosPage />}></Route>
                      <Route path="mayoreo" element={<MayoreoPage />}></Route>
                      <Route path="garantias" element={<GarantiasPage />}></Route>
                      <Route path="devoluciones" element={<DevolucionesPage />}></Route>
                      <Route path="pagos" element={<PagosPage />}></Route>
                      <Route path="tiendas" element={<TiendaPage />}></Route>
                      <Route path="centros_servicio" element={<CentroServicioPage />}></Route>
                      <Route path="forma_de_pagos" element={<FormaDePagoPage />}></Route>
                      <Route path="recoger_en_tienda" element={<RecogerEnTiendaPage />}></Route>
                      <Route path="mision_vision" element={<MisionVisionPage />}></Route>
                      <Route path="terminos_y_condiciones" element={<TerminosCondicionesPage />}></Route>
                      <Route path="politicas_privacidad" element={<PoliticasPrivacidadPage />}></Route>
                      <Route path="politicas_cookies" element={<PoliticasCookiesPage />}></Route>
                      <Route path="marcas" element={<MarcasPage />}></Route>
                      <Route path="como_eliminar_cuenta" element={<UsuarioEliminarCuentaPage />}></Route>
                      <Route path="agotado" element={<ProductoAgotadoPage />} />
                      <Route path="500" status={500} element={<InternalServerErrorPage />} />
                      <Route path="*" status={404} element={<NoPage />} />
                    </Route>
                  </Routes>
                </Suspense>
              </BrowserRouter>
            </ResponsiveProvider>
          </PromoCondiProvider>
        </MetodoDeEntregaProvider>
      </AppUpdateProvider>
    </Provider>
  );
}

export default App;
