import React from 'react';
import { AppBar, Chip, styled } from '@material-ui/core';
import ToolBarOne from './ToolBarOne';
import useCheckOutHandler from  '../../../../redux/handler/useCheckOutHandler';
import ToolBarTwo from './ToolBarTwo';
import { useState } from 'react';
import MenuLateral from './MenuLateral';
import useMenuService from '../service/useMenuService';
import MenuLateralMovil from './MenuLateralMovil';
import RocketArrow from "../../assets/rocket.png";
import IconInventory from '../../../icons/IconInventory';
import { useMemo } from 'react';
import metodoDeEnvio from '../../../../enums/metodoDeEnvio';
import IconLocalShipping from '../../../icons/IconLocalShipping';
import IconStoreFront from '../../../icons/IconStoreFront';
import { useMetodoDeEntrega } from "../../../../contexts/MetodoDeEntrega";
import Container from '../../../../components/controls/Container';

const MenuBar = ({ top, media }) => {  
  const [open, setOpen]       = useState(false);
  const { checkout, carrito } = useCheckOutHandler();
  const { menu }              = useMenuService();

  const handleDrawerClose = () => {
    setOpen(false);
  }

  const handleToogleMenu = () => {
    setOpen(!open)
  }

  return (
    <>
      <MuiAppBar position='static'>
          <ToolBarOne 
            checkout={checkout}
            carrito={carrito}
            top={top}
            onMenu={handleToogleMenu}
            media={media}
          />
          <ToolBarTwo
            checkout={checkout}
            carrito={carrito}
            top={top}
            media={media}
          />
      </MuiAppBar>
      <ChipFlotante/>
      {
        menu.length === 0 ? <></> : 
        <>
          {
            media ?         
            <MenuLateralMovil
              open={open}
              onClose={handleDrawerClose}
              menu={menu}
            />:
            <MenuLateral 
              open={open}
              onClose={handleDrawerClose}
              menu={menu}
              top={top}
            /> 
          }
        </>
      }
      {
        top ? <Rocket/> : <></>
      }
    </>
  );
}

const MuiAppBar = styled(AppBar)({
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none',
    borderBottom: '1px solid hsla(0,0%,85%,.5)',
});

const ChipFlotante = () => {
  const { checkout }          = useCheckOutHandler();
  const { openDialogEntrega } = useMetodoDeEntrega ();

  const tienda = useMemo(() => {
    if(checkout.IsCarritoActivo) {
      if(checkout.MetodoDeEnvio === metodoDeEnvio.DOMICILIO) {
        return {
          name: 'Entrega a Domicilio',
          icon: <IconLocalShipping fill='rgb(38, 56, 128)'/>
        }
      } else {
        return {
          name: checkout.SucursalDeEntrega.Nombre.toCapitalize(),
          icon: <IconStoreFront fill='rgb(38, 56, 128)'/>
        }
      }
    } else {
      return {
        name: 'Elegir método de entrega',
        icon: <IconInventory fill='rgb(38, 56, 128)'/>
      }
    }
  }, [checkout])

  const handleSelectSucursal = async () => {
    await openDialogEntrega({});
  }

  return (
    <Container>
      <ChipMui
        avatar={tienda.icon} 
        label={tienda.name} 
        onClick={handleSelectSucursal}
      />
    </Container>
  )
}

const ChipMui = styled(Chip)({
  position: 'fixed', 
  top: '75px', 
  zIndex: '100',
  background: 'rgba(38,56,128,0.3)',
  fontSize: "12px", 
  letterSpacing: "-0.25px", 
  cursor: "pointer",
  color: 'rgb(38, 56, 128)',
  fontWeight: '550',
  padding: '5px',
  '&:focus': {
    background: 'rgba(38,56,128,0.5)',
  },
  '&:hover': {
    background: 'rgba(38,56,128,0.5)',
  }
})

const Rocket = () => {
  const handleOnClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div style={{ position: "absolute" }}>
      <img 
        style={{ position: "fixed", right: "20px", bottom: "20px", cursor: "pointer", zIndex: 1 }} 
        onClick={handleOnClick} 
        src={RocketArrow} alt="arrow"
      />
    </div>
  )
}

export default MenuBar;