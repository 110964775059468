import React from 'react';

const IconInventory = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M620-261.537 825.693-467.23q8.307-8.308 20.884-8.5 12.577-.192 21.269 8.5 8.692 8.692 8.692 21.076t-8.692 21.076L645.307-202.539Q634.461-191.693 620-191.693q-14.461 0-25.307-10.846L484.924-312.308q-8.308-8.308-8.5-20.884-.193-12.577 8.5-21.269 8.692-8.692 21.076-8.692t21.076 8.692L620-261.537ZM212.309-140.001q-29.827 0-51.067-21.241-21.241-21.24-21.241-51.067v-535.382q0-29.827 21.241-51.067 21.24-21.241 51.067-21.241H388.54q8.308-29.23 33.961-48.46Q448.154-887.69 480-887.69q33.076 0 58.422 19.231 25.346 19.23 33.653 48.46h175.616q29.827 0 51.067 21.241 21.241 21.24 21.241 51.067v157.692q0 12.749-8.629 21.374Q802.741-560 789.986-560q-12.756 0-21.371-8.625Q760-577.25 760-589.999v-157.692q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846h-87.692v68.461q0 15.365-10.351 25.759-10.35 10.394-25.65 10.394H335.994q-15.3 0-25.646-10.394-10.347-10.394-10.347-25.759V-760h-87.692q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v535.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h197.692q12.749 0 21.374 8.629Q440-182.743 440-169.987q0 12.755-8.625 21.37-8.625 8.616-21.374 8.616H212.309Zm267.758-615.383q15.394 0 25.74-10.414 10.347-10.413 10.347-25.807 0-15.394-10.414-25.74-10.414-10.346-25.807-10.346-15.394 0-25.74 10.413-10.347 10.414-10.347 25.808t10.414 25.74q10.414 10.346 25.807 10.346Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconInventory;
