import { createAdapterProductoHistorial  } from "../../../adapter/productoHistorialAdapter";
import { createAdapterProductoFavorito  } from "../../../adapter/productoFavoritoAdapter";
import { createAdapterProductoExistencia } from "../../../adapter/productoExistenciaAdapter";
import { createAdapterProductoDetalle } from "../../../adapter/productoDetalleAdapter";
import { createAdapterProducto } from "../../../adapter/productoAdapter";
import { getAsync } from "../httpServiceIntelafApi";
import { getAuthorization, getToken } from '../../../shared/TokenHeaders';
import { createAdapterCuotaBanco } from "../../../adapter/cuotaBancoAdapter";

const URI = '/producto';

export const getProductosFavoritosAsync = () => {
    return getAsync(URI + "/favorito", getAuthorization()).then((productoFavorito) => { 
        return productoFavorito.map(favorito => createAdapterProductoFavorito(favorito)); 
    });
}

export const getProductoExistenciaAsync = (codigoProducto) => {
    return getAsync(`${URI}/existencia?codigo=${codigoProducto}`, {}).then((productoExistencia) => {
        return createAdapterProductoExistencia(productoExistencia);
    });
}

export const getPromoProductoCondiAsync = (codigoProducto) => {
    return getAsync(`${URI}/obtenerPromoCondis/${codigoProducto}`, {}).then((producto) => {
        return createAdapterProducto(producto);
    });
}

export const getComboAsync = (archivo) => {
    return getAsync(`${URI}/combo/detalle?archivo=${archivo}`, getToken()).then((combo) => {
        return combo;
    });
}

export const getCombosAsync = () => {
    return getAsync(`${URI}/combo`, getToken()).then((combo) => {
        return combo;
    });
}

export const getProductosHistorialAsync = () => {
    return getAsync(URI + '/vistosRecientes', getAuthorization()).then((productoHistorial) => { 
        return productoHistorial.Productos.map(historial => createAdapterProductoHistorial(historial)); 
    });
}

export const getCategoriaPromo = () => {
    return getAsync(URI + '/promoCategoria',{}).then((response) => {
        return response;
    });
}

export const getProductosPromoCondi = (codigo) => {
    return getAsync(URI + `/obtenerPromoCondis/${codigo}`,{}).then((response) => {
        return response.map(x => createAdapterProducto(x));
    });
}

export const getProductoDetalleAsync = (codigo, codigoSucursal) => {
    return getAsync(URI + `/detalle?codigo=${codigo}&codigoSucursalCompra=${codigoSucursal}`, getToken()).then((productoDetalle) => {
        return createAdapterProductoDetalle(productoDetalle);
    })
}

export const getProductoCuotasAsync = (codigo) => {
    return getAsync(URI + `/cuotas?codigoInventario=${codigo}`, {}).then((productoCuotas) => {
        return productoCuotas.map(productoCuotas => createAdapterCuotaBanco(productoCuotas));
    })
}

export const getProductoEnPromoAsync = () => {
    return getAsync(URI + '/promo', getToken()).then((response) => {
        return response;
    });
}
