import useSucursalesHandler from '../../../redux/handler/useSucursalesHandler';
import useExcepcion from '../../../shared/hooks/useExcepcion';
import { useState } from 'react';
import { useEffect } from 'react';

const useSucursalesService = () => {
    const { handleMessageException } = useExcepcion();
    const { sucursales, handleGetSucursalesAsync } = useSucursalesHandler(); 
    
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        handleGetSucursalesServiceAsync();

        // eslint-disable-next-line
    }, []);
    
    const handleGetSucursalesServiceAsync = async () => {
        try {
            if(sucursales.length === 0) {
                await handleGetSucursalesAsync();
            }

        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

    return {
        sucursales,
        loader
    }
}

export default useSucursalesService;