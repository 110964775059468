import { useSelector, useDispatch } from 'react-redux';
import { resetBannersMain, createBannersMain } from '../state/bannerSlice';
import { getBannersHomeMain } from '../../services/intelafApiClient/repository/bannerRepository';

const useBannersHandler = () => {
  
  //Redux
  const dispatch = useDispatch();
  const banners  = useSelector((store) => store.banners); 

  //methods
  const handleGetBannresHomeAsync = async () => {
    let bannersDtos = await getBannersHomeMain();
    await dispatch(createBannersMain(bannersDtos.toJSON()));
  }

  const handleResetBannersHome = () => {
    dispatch(resetBannersMain());
  }

  return {
    banners,
    handleGetBannresHomeAsync, 
    handleResetBannersHome
  }
}

export default useBannersHandler;
