import React from 'react';

const IconLocalShipping = (props) => {
  return (
    <svg
      {...props}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path d="M233.755-177.694q-46.832 0-79.524-32.756t-32.692-79.551H90.001q-15.366 0-25.76-10.394-10.394-10.394-10.394-25.759v-381.537q0-30.308 21-51.308t51.307-21h472.307q29.827 0 51.067 21.241 21.241 21.24 21.241 51.067v80h69.999q17.173 0 32.538 7.682 15.365 7.683 25.308 21.24L898.922-464.46q3.615 4.52 5.423 9.943 1.808 5.423 1.808 11.75v116.613q0 15.365-10.394 25.759t-25.76 10.394h-36.153q0 46.795-32.783 79.551t-79.615 32.756q-46.832 0-79.524-32.756-32.693-32.756-32.693-79.551H346.153q0 46.923-32.783 79.615-32.782 32.692-79.615 32.692Zm.091-59.998q22 0 37.154-15.154 15.154-15.154 15.154-37.155 0-22-15.154-37.154-15.154-15.154-37.154-15.154-22.001 0-37.155 15.154t-15.154 37.154q0 22.001 15.154 37.155t37.155 15.154Zm-120-112.307h28.923q12.768-22.231 36.884-37.27 24.116-15.039 54.193-15.039 29.308 0 53.808 14.847 24.5 14.846 37.269 37.462H610.77v-357.692q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H126.154q-4.615 0-8.462 3.846-3.846 3.847-3.846 8.463v357.692Zm607.693 112.307q22 0 37.154-15.154 15.154-15.154 15.154-37.155 0-22-15.154-37.154-15.154-15.154-37.154-15.154-22.001 0-37.155 15.154t-15.154 37.154q0 22.001 15.154 37.155t37.155 15.154Zm-50.77-192.307H850L749.999-562.693q-1.923-2.307-4.423-3.654-2.5-1.346-5.577-1.346h-69.23v137.694ZM362.308-535Z" fill={props.fill}
      />
    </svg>
  );
};

export default IconLocalShipping;
