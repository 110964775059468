import { useEffect, useRef } from "react";
import { DOMINIO } from "../Constant";

const useCanonical = (relativeUrlCanonical) => {
    const relativeUrlCanonicalRef = useRef(relativeUrlCanonical);

    useEffect(() => {
        let isHome = relativeUrlCanonicalRef.current.trim() === "/";
        const canonicalLink = document.querySelector('link[rel="canonical"]')
        const homeLink = document.createElement('link');
        canonicalLink.href = DOMINIO + relativeUrlCanonicalRef.current;
        if (isHome) {
            homeLink.rel = 'home';
            homeLink.href = DOMINIO + relativeUrlCanonicalRef.current;
            document.head.appendChild(homeLink);
        }
        return () => {
            if (isHome) document.head.removeChild(homeLink);
        };
        // eslint-disable-next-line
    }, []);
}

export default useCanonical;