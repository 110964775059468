
import { useState } from 'react';
import { postSearchProductosAsync } from '../repository/searchRepository';
import useExcepcion from '../../../shared/hooks/useExcepcion';
import SearchDto from '../../../models/searchDto';

const useSearchIntelaf = () => {
    const [dataSearch, setDataSearch] = useState(new SearchDto({}));
    const [loader, setLoader]         = useState(false);

    const { handleMessageException }  = useExcepcion();

    const handleGetSearchIntelaf= async (valor) => {
        try {
            setLoader(true);

            let response = await postSearchProductosAsync(valor)
            setDataSearch(response);
        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

  return {
    dataSearch,
    loader,
    handleGetSearchIntelaf
  }
}

export default useSearchIntelaf
