import { List, ListItem, Toolbar, styled } from '@material-ui/core';
import React from 'react'
import { Link as DomLink } from 'react-router-dom';
import MenuSoporte from './MenuSoporte';
import MenuPoliticas from './MenuPoliticas';
import TitleMetodo from './TitleMetodo';

const ToolBarTwo = ({ checkout, media }) => {

  return (
    <MuiToolBar id='mui-tool-bar-two' media={media}>
        {
            media ? 
            <Content media={media}>
                <TitleMetodo media={media} checkout={checkout}/>
            </Content> : 
            <Content>
                <div>
                    <TitleMetodo checkout={checkout}/>
                </div>
                <MuiList>
                    <MuiItem>
                        <Link  to="/superofertas">Ofertas</Link>
                    </MuiItem>
                    <MuiItem>
                        <Link to="/tiendas">Ubicaciones</Link>
                    </MuiItem>
                    <MuiItem>
                        <Link to="/mayoreo">Mayoreo</Link>
                    </MuiItem>
                    <MuiItem>
                        <Link to="/empresa">Empresa</Link>
                    </MuiItem>
                    <MenuSoporte/>
                    <MenuPoliticas/>
                    <MuiItem>
                        <Link to="/sugerencias">Sugerencias</Link>
                    </MuiItem>
                    <MuiItem>
                        <Link to="/empleos">Empleos</Link>
                    </MuiItem>
                </MuiList>
            </Content>
        }
    </MuiToolBar>
  )
}

const MuiList = styled(List)({
    padding: '0px', 
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: '20px',
});

const MuiItem = styled(ListItem)({
    padding: '0px',
    justifyContent: 'flex-end'
});

const Link = styled(DomLink)({
    color: 'grey',
    fontSize: '13px',
    fontWeight: '400',
    letterSpacing: '-.25px',
    textDecoration: 'none',
    fontFamily: 'Inter',
    '&:hover': {
        textDecoration: 'none',
        color: '#F0761D'
    },
    '&:focus': {
        textDecoration: 'none',
        color: '#F0761D'
    }
});

const Content = styled('div')(({ media }) => ({
    maxWidth: '1230px',
    padding: '0px 15px',
    margin: '0px auto',
    minHeight: '45px',
    display: 'grid',
    gridTemplateColumns: media ? '1fr' : '1fr 1fr',
    gap: '10px',
    alignItems: 'center',
}));

const MuiToolBar = styled(Toolbar)(({ media }) => ({
    width: '100%',
    marginTop: media ? '115px' : '65px',  //65 110
    minHeight: '45px',
    margin: '0px',
    padding: '0px',
    display: 'block',
    zIndex: '1000',
    backgroundColor: 'white',
}));

export default ToolBarTwo;
