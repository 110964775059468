import React from 'react';

const IconHomeWork = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M684.616-604.616h70.768v-70.768h-70.768v70.768Zm0 160h70.768v-70.768h-70.768v70.768Zm0 160h70.768v-70.768h-70.768v70.768Zm143.075 144.615H689.998q-12.75 0-21.375-8.629-8.624-8.629-8.624-21.384 0-12.756 8.624-21.371Q677.248-200 689.998-200h137.693q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-535.382q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H480q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v59.461l-59.998-43.383v-17.309q0-29.16 20.958-50.118 20.959-20.959 50.118-20.959h348.922q29.827 0 51.067 21.241 21.241 21.24 21.241 51.067v535.382q0 29.827-21.241 51.067-21.24 21.241-51.067 21.241Zm-695.382 0q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.067v-260.537q0-17.692 8.038-33.346 8.038-15.653 22.5-25.499L278.23-665.46q19.066-13.461 41.764-13.461 22.697 0 41.774 13.461L549.46-531.691q14.462 9.846 22.5 25.499 8.039 15.654 8.039 33.346v260.537q0 29.827-21.241 51.067-21.24 21.241-51.067 21.241H401.539q-15.366 0-25.76-10.395-10.394-10.394-10.394-25.759v-153.846h-90.77v153.846q0 15.365-10.394 25.759-10.394 10.395-25.76 10.395H132.309ZM120-473.461v261.152q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462h82.307v-153.846q0-15.365 10.395-25.759 10.394-10.394 25.759-10.394h138.46q15.365 0 25.759 10.394 10.395 10.394 10.395 25.759V-200h82.307q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-261.152q0-3.077-1.346-5.77-1.347-2.692-4.039-4.615L326.924-616.23q-3.078-2.308-6.924-2.308-3.846 0-6.924 2.308L125.385-483.846q-2.692 1.923-4.039 4.615-1.346 2.693-1.346 5.77Zm510.076-98.23ZM425.384-200v-189.999H214.616V-200v-189.999h210.768V-200Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconHomeWork;
