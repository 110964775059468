import { useSelector, useDispatch } from 'react-redux';
import { createSucursales, resetSucursales } from '../state/sucursalesSlice';
import { getSucursalesAsync } from '../../services/intelafApiClient/repository/sucursalRepository';
import { store } from '../store';

const useSucursalesHandler = () => {

  //Redux
  const dispatch   = useDispatch();
  const sucursales = useSelector((store) => store.sucursales); 

  //methods
  const handleGetSucursalesAsync = async () => {
    let sucursales = await getSucursalesAsync();
    await dispatch(createSucursales(sucursales.map(sucursal => sucursal.toJSON())));
  }

  const handleResetSucursales = () => {
    dispatch(resetSucursales());
  }

  const handleGetState = async () => {
    await store.getState().sucursales;
  }


  return {
    sucursales,
    handleGetSucursalesAsync, 
    handleResetSucursales,
    handleGetState
  }
}

export default useSucursalesHandler;