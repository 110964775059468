import React from 'react';

const IconHeartPlus = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M440-501Zm0 354.075-86.612-77.844Q271.771-299 215.656-354.616q-56.116-55.615-90.77-101.577-34.654-45.962-49.77-86.423Q60.003-583.078 60.003-626q0-85.154 57.422-142.269Q174.847-825.384 260-825.384q52.385 0 99 24.501 46.615 24.5 81 70.269 34.385-45.769 81-70.269 46.615-24.501 99-24.501 75.23 0 126.961 44.347 51.73 44.346 67.115 111.038H751Q737.231-714.615 700.693-740 664.154-765.385 620-765.385q-49.846 0-88.192 27.5-38.347 27.5-72.27 77.885h-39.076q-33.693-50.77-73.385-78.077-39.692-27.308-87.077-27.308-57.769 0-98.885 39.692Q120-686 120-626q0 33.384 14 67.769 14 34.385 50 79.269 36 44.885 98 105.154T440-228q28.308-25.308 60.615-53.769 32.308-28.462 54.462-49.616l6.692 6.692 14.692 14.692 14.692 14.692 6.692 6.693q-22.769 21.153-54.269 48.923-31.5 27.769-59.423 53.077L440-146.925Zm274.615-143.076v-120h-120v-59.998h120v-120h-59.999v120h120v59.998h-120v120h-59.999Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconHeartPlus;
