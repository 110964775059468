import React from 'react';

const IconAccountBalance = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M230.002-309.999v-240.003q0-12.75 8.629-21.375 8.629-8.624 21.384-8.624t21.371 8.624q8.615 8.625 8.615 21.375v240.003q0 12.749-8.629 21.374Q272.743-280 259.988-280t-21.371-8.625q-8.615-8.625-8.615-21.374Zm219.999 0v-240.003q0-12.75 8.628-21.375 8.629-8.624 21.384-8.624 12.756 0 21.371 8.624 8.615 8.625 8.615 21.375v240.003q0 12.749-8.628 21.374Q492.742-280 479.987-280q-12.756 0-21.371-8.625-8.615-8.625-8.615-21.374Zm-309.23 169.998q-12.75 0-21.375-8.629t-8.625-21.384q0-12.756 8.625-21.371Q128.021-200 140.771-200h678.458q12.75 0 21.375 8.629 8.625 8.628 8.625 21.384 0 12.755-8.625 21.37-8.625 8.616-21.375 8.616H140.771Zm529.228-169.998v-240.003q0-12.75 8.629-21.375 8.629-8.624 21.384-8.624t21.371 8.624q8.615 8.625 8.615 21.375v240.003q0 12.749-8.629 21.374Q712.74-280 699.985-280t-21.371-8.625q-8.615-8.625-8.615-21.374Zm150.769-350.002H136.386q-10.673 0-18.144-7.457-7.471-7.456-7.471-18.108v-15.623q0-7.811 3.914-13.467 3.913-5.655 10.317-9.19l323.229-158.153q14.979-7.23 31.72-7.23t31.818 7.23l321.614 157.153q7.538 3.846 11.692 10.769t4.154 14.873v-10.84q0 12.286-8.039 20.324-8.038 8.039-20.422 8.039ZM252.615-720h454.77-454.77Zm0 0h454.77l-222-107.692q-2.692-1.154-5.385-1.154-2.693 0-5.385 1.154L252.615-720Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconAccountBalance;
