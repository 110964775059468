import React from 'react';
import MenuBar from './components/MenuBar';
import { useState } from 'react';
import { useEffect } from 'react';
import { useResponsive } from '../../../contexts/Responsive';

const Nav = () => {
  const isMovil = useResponsive();

  const [top, setTop]       = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if(position === 0 ) {
        setTop(0);
    } else {
        setTop(1);
    }
  }


  useEffect(() => { 
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };

    // eslint-disable-next-line
  }, [])


  return (
    <MenuBar 
      top={top}
      media={isMovil ? 1:0}
    /> 
  )
}

export default Nav;
