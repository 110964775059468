import useSucursalesHandler from '../../../../../redux/handler/useSucursalesHandler'
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { getProductoExistenciaAsync } from '../../../../../services/intelafApiClient/repository/productoRepository';
import useUsuarioHandler from '../../../../../redux/handler/useUsuarioHandler';
import Swal from '../../../../../shared/SweetAlert';

const useSucursalesService = (codigo) => {
    const [loader, setLoader]           = useState(true); 
    const [existencias, setExistencias] = useState({ Existencias: [] })

    const { sucursales, handleGetSucursalesAsync } = useSucursalesHandler();
    const { usuario }    = useUsuarioHandler();

    useEffect(() => {
      const getExistenciaProducto = async () => {
        try {
          setLoader(true);

          if(codigo !== null) {
            setExistencias(await getProductoExistenciaAsync(codigo));
          }

        } catch(err) {
          Swal.fire({
            title: 'Lo sentimos',
            text: err.message,
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'Orange',
          });
        } finally {
          setLoader(false);
        }
      }
     
      getExistenciaProducto();

      setInterval(async () => {
        await handleGetSucursalesAsync();
      }, 43200000);

      // eslint-disable-next-line
    }, [codigo]);

    const sucursalesFilter = useMemo(() => { 
      if(codigo !== null) {
          if(usuario.IsMayorista) {
              return existencias.Existencias.filter(x => x.CodigoSucursal === '5').map((x) => {
                  return sucursales.filter(x =>  (x.Tipo === 1 | x.Tipo === 2 | x.Tipo === 4)).find(s => s.CodigoSucursal.toString() === x.CodigoSucursal);
              })
          }

          if(existencias.Existencias.length === 0) {
            return []
          }

          return existencias.Existencias.filter(x => x.CodigoSucursal !== "1").map((x) => {
              return sucursales.filter(x =>  (x.Tipo === 1 | x.Tipo === 2 | x.Tipo === 4)).find(s => s.CodigoSucursal.toString() === x.CodigoSucursal);
          })
      }

      if(usuario.IsMayorista) {
          return sucursales.filter(x =>  (x.Tipo === 1 | x.Tipo === 2 | x.Tipo === 4)).filter(x => x.CodigoSucursal === 5); 
      }

      return sucursales.filter(x => (x.Tipo === 1 | x.Tipo === 2 | x.Tipo === 4) && x.CodigoSucursal !== 1);

      // eslint-disable-next-line
    }, [sucursales, existencias, codigo])

  return {
    usuario,
    sucursalesFilter,
    loader,
  }
}

export default useSucursalesService
