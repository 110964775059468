import React from 'react';

const IconKeyBoardArrowDown = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M480-372.924q-7.231 0-13.461-2.308-6.231-2.307-11.846-7.923L274.924-562.924q-8.308-8.307-8.5-20.884-.193-12.576 8.5-21.268 8.692-8.693 21.076-8.693t21.076 8.693L480-442.153l162.924-162.923q8.307-8.308 20.884-8.5 12.576-.193 21.268 8.5 8.693 8.692 8.693 21.076t-8.693 21.076L505.307-383.155q-5.615 5.616-11.846 7.923-6.23 2.308-13.461 2.308Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconKeyBoardArrowDown;
