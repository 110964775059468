import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from 'react';
import ModalMetodoDeEntrega  from './components/ModalMetodoDeEntrega';

const MetodoDeEntregaContext = createContext();

export const MetodoDeEntregaProvider = ({ children }) => {
  
  //state
  const [open, setOpen]         = useState(false);
  const [producto, setProducto] = useState(null);

  //Ref
  const resolveRef = useRef(null);

  //methods
  const openDialogEntrega = useCallback(({ producto = null }) => {
    setOpen(true);
    setProducto(producto);

    return new Promise((resolve) => {
      resolveRef.current = resolve;
    })

  }, [])

  const closeDialogEntrega = useCallback((response)  => {
    if(resolveRef.current) {
      resolveRef.current(response);
      resolveRef.current = null;
    }

    setOpen(false);
  }, [])

  useEffect(() => {
    return () => {
      closeDialogEntrega();
    }

    // eslint-disable-next-line
  }, [])

  return (
    <MetodoDeEntregaContext.Provider value={{ openDialogEntrega }}>
        {children}
        <ModalMetodoDeEntrega
          open={open}
          onClose={closeDialogEntrega}
          codigo={producto}
        />
    </MetodoDeEntregaContext.Provider>
  )
}

export const useMetodoDeEntrega = () => {
  return useContext(MetodoDeEntregaContext);
};