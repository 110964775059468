import { CircularProgress, styled } from '@material-ui/core';

const Loader =({ fontSize = '30px', ...props })=>{
    return (
        <Content {...props}>
            <CircularProgress style={{width: fontSize, height: fontSize}} color="inherit"/>
        </Content>
    )
}

const Content = styled('div')({
    display: 'block',
    width: '40px',
    height: '40px',
    margin: '8vh auto',
    color:  '#fca86a' //"#f0761d"
})

export default Loader