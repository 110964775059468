import React from 'react';

const IconLoyalty = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path d="M556.153-117.847q-17.231 17.23-42.768 17.23-25.538 0-42.769-17.23l-352-352q-8.692-8.692-13.653-20.038-4.962-11.346-4.962-24.115v-286q0-24.538 17.731-42.268 17.73-17.731 42.268-17.731h286q12.384 0 23.73 4.77 11.346 4.769 19.654 13.076l352 352.616q17.615 17.615 17.807 43.037.192 25.422-17.038 42.653l-286 286ZM513.385-160l286-286L446-800H160v286l353.385 354ZM260-650.001q20.769 0 35.384-14.615 14.615-14.615 14.615-35.384t-14.615-35.384Q280.769-749.999 260-749.999t-35.384 14.615Q210.001-720.769 210.001-700t14.615 35.384q14.615 14.615 35.384 14.615ZM160-800Zm385.692 502.384 105.077-107q9.846-9.846 15.384-23.115 5.538-13.269 5.538-28.73 0-29.769-21.115-51.269t-50.884-21.5q-19.385 0-38.077 10.615-18.692 10.616-41.615 36.616-25.384-27.231-43.154-37.231-17.769-10-36.538-10-29.769 0-50.884 21.5-21.115 21.5-21.115 51.269 0 15.461 5.538 28.73t15.384 23.115l105.077 107Q505.154-286.77 520-286.77t25.692-10.846Z" fill={props.fill}
      />
    </svg>
  );
};

export default IconLoyalty;
