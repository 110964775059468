class FleteDto {
    constructor(flete) {
        this.CodigoSucuCompra    = flete.CodigoSucuCompra    || 0;
        this.DepartamentoEntrega = flete.DepartamentoEntrega || '';
        this.MunicipioEntrega    = flete.MunicipioEntrega    || '';
        this.ZonaEntrega         = flete.ZonaEntrega         || 0;
        this.IdCarrito           = flete.IdCarrito           || '';
        this.EsMonetario         = flete.EsMonetario         || false;
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}

export default FleteDto;




