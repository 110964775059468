class UsuarioDto {
    constructor(user) {
        this.EntregaObservacion  = user.EntregaObservacion || null;
        this.EntregaZona         = user.EntregaZona || 0;
        this.EntregaMunicipio    = user.EntregaMunicipio || null;
        this.EntregaDepartamento = user.EntregaDepartamento || null;
        this.DireccionEntrega    = user.DireccionEntrega || null;
        this.DireccionFiscal     = user.DireccionFiscal || null;
        this.UsuarioValido       = user.UsuarioValido || false;
        this.Clave               = user.Clave || '';
        this.ClaveRepeat         = user.ClaveRepeat || '';
        this.Distribuidor        = user.Distribuidor || "0";
        this.Telefono            = user.Telefono || '';
        this.Apellidos           = user.Apellidos || '';
        this.Nombres             = user.Nombres || '';
        this.Email               = user.Email || '';
        this.NIT                 = user.NIT || null;
        this.Usuario             = user.Usuario || '';
        this.Activo              = user.Activo || 'N';
        this.TiendaCompra        = user.TiendaCompra || '';
        this.IsNewDispositivo    = user.IsNewDispositivo || false;
        this.NotificacionActiva  = user.NotificacionActiva || false;
        this.Token               = user.Token || '';
        this.IsLogged            = this.userIsLogged();
        this.IsMayorista         = this.userIsMayorista();
    }

    userIsLogged() {
        return this.Token !== '';
    }

    userIsMayorista() {
        return this.Distribuidor === '1';
    }

    isValid() {
        if (!this.Email.match(/^[\w.-_]{1,30}@[\w.-]+.[a-z]{2,}$/)) {
            throw new Error('El correo electronico no es valido');
        }

        if (this.Nombres.length === 0) {
            throw new Error('El nombre es requerido');
        }

        if (!this.Nombres.match(/^[A-zÀ-ÿ\s']+$/)) {
            throw new Error('El nombre no es valido');
        }

        if (!this.Apellidos.match(/^[A-zÀ-ÿ\s']+$/)) {
            throw new Error('El Apellido no es valido');
        }

        if (!this.Telefono.match(/^[0-9]{8}$/)) {
            throw new Error('El Telefono no es valido');
        }

        return true;
    }

    isValidatePassword(){
        if (this.Clave.length === 0) {
            throw new Error('La contraseñas es requerida');
        }

        if (this.ClaveRepeat.length === 0) {
            throw new Error('La confirmación de la contraseñas es requerida');
        }

        if (this.ClaveRepeat !== this.Clave) {
            throw new Error('Las contraseñas debe concidir.');
        }

        if (!this.Clave.match(/^.{6,12}$/)) {
            throw new Error('La contraseñas debe tener entre 6 y 12 caracteres');
        }

        if (!this.ClaveRepeat.match(/^.{6,12}$/)) {
            throw new Error('La Confimacion debe tener entre 6 y 12 caracteres');
        }

        return true;

    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}

export default UsuarioDto;