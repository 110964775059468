import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import MenuMain from './MenuMain';
import MenuProductos from './MenuProductos';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const MenuLateralMovil = ({ open, onClose, menu }) => {
    const [menuZero, setMenuZero]  = useState(menu);
    const [menuOne, setMenuOne]    = useState([]);
    const [menuTwo, setMenuTwo]    = useState([]);
    const [menuTree, setMenuTree]  = useState([]);
    const [active, setActive]      = useState(false);
    const [typeMenu, setTypeMenu]  = useState(0);

    const location = useLocation();
    
    useEffect(() => {
        setTimeout(() => {
            onClose();
        }, 100)

        // eslint-disable-next-line
    }, [location])


    const handleOnProductos = () => {
        setActive(true);
    }

    const handleOffProducto = () => {
        setActive(false);
    }

    const handleMenuAdd = () => {
        setTypeMenu(typeMenu + 1);
    }

    const handleMenuRest = () => {
        setTypeMenu(typeMenu - 1);
    }


  return (
    <Drawer
        open={open}
        onClose={onClose}
    >
        <div style={{ minWidth: 300 }}>
            {
                active ?
                <MenuProductos 
                    menu={menu} 
                    onOffProductos={handleOffProducto}
                    onTypeAdd={handleMenuAdd}
                    onTypeRest={handleMenuRest}
                    typeMenu={typeMenu}
                    menuZero={menuZero}
                    setMenuZero={setMenuZero}
                    menuOne={menuOne}
                    setMenuOne={setMenuOne}
                    menuTwo={menuTwo}
                    setMenuTwo={setMenuTwo}
                    menuTree={menuTree}
                    setMenuTree={setMenuTree}
                /> : 
                <MenuMain 
                    onOpenProductos={handleOnProductos} 
                    onClose={onClose}
                /> 
            }
        </div>
    </Drawer>
  )
}



export default MenuLateralMovil;