
import { postAsync } from "../httpServiceIntelafApi";
import { createAdapterSearch  } from "../../../adapter/searchAdapter";

const URI = "/producto/busqueda/sugerenciaBusqueda";

export const postSearchProductosAsync = (valor) => {
    let object = { texto: valor }
    return postAsync(URI, { }, object).then((response) => {
        return createAdapterSearch(response);
    });
}