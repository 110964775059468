import { HandlerExcepcion } from '../HandlerExcepcion';
import { useNavigate } from 'react-router-dom';
import useLogout  from '../../shared/hooks/useLogout';
import Swal from "../../shared/SweetAlert";
import useUsuarioHandler from '../../redux/handler/useUsuarioHandler';
import { postLogsPage } from '../../services/intelafApiClient/repository/loggingRepository'; 
import useCheckOutHandler from '../../redux/handler/useCheckOutHandler';

const useExcepcion = () => {
    const navigate            = useNavigate();
    const handleLogoutSession = useLogout();

    const { 
        usuario 
    } = useUsuarioHandler();
    
    const { 
        checkout, 
        carrito 
    } = useCheckOutHandler();


    const handleSendLogging = async (err) => {

        let currentURL = window.location.href;

        try {
            if(usuario.IsLogged) {
                var data = {
                    Usuario: usuario.Usuario,
                    Url:   currentURL,
                    Error: err.message,
                    Fecha: new Date()
                }

                if(checkout.IsCarritoActivo) {
                    data.IdCarrito = carrito.idCarrito;
                    data.FormaDePago = checkout.FormaDePago;
                    data.MetodoDeEnvio = checkout.MetodoDeEnvio;
                    data.NombCortoSucursal = checkout.SucursalDeEntrega.NombreCortoSucursal;
                }
            
                await postLogsPage(data);
            }

        } catch(error) {
            console.log(error);
        }    
    } 

    const handleMessageException = (err) => {

        Swal.fire({
            title: 'Lo sentimos',
            text: err.message,
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'Orange',
        });
        
        handleSendLogging(err).then(() => {
            console.log('mensaje enviado', err);
        });

        if(err instanceof HandlerExcepcion) {
            switch(err.code) {
                case 1103:
                        navigate(`/actualizar/${localStorage.getItem("app-token")}`); 
                    break;
                case 1104:
                        navigate('/carrito');
                    break;
                case 1105:
                        window.location.reload();    
                    break;
                case 1106:
                        navigate('/carrito')
                    break;
                case 999:
                        handleLogoutSession();
                    break;
                case 1001:
                    navigate('/agotado');
                    break;
                case 500:
                    navigate('/500');
                    break;
                default: 
                    break;
            }
        }
    }

  return {
    handleMessageException,
  }
}

export default useExcepcion;
