import React from 'react';

const IconCall = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M777.614-140.001q-113.076 0-227.191-52.577-114.115-52.577-209.691-148.346-95.577-95.769-148.154-209.691-52.577-113.923-52.577-226.999 0-18.165 12-30.275t30-12.11h130.46q15.154 0 26.731 9.885 11.577 9.885 14.73 24.423L376.845-668q2.385 16.385-1 28.154-3.384 11.769-12.154 19.769l-92.384 89.923q22.308 40.846 50.962 77.269 28.654 36.424 62.038 69.578 32.924 32.923 70.001 61.154 37.077 28.231 80.077 52.538l89.769-90.538q9.385-9.769 22.731-13.692 13.346-3.923 27.731-1.923l111.075 22.615q15.154 4 24.731 15.461 9.577 11.462 9.577 26.001v129.69q0 18-12.11 30t-30.275 12ZM242.923-586.922l71.385-68.308q1.923-1.539 2.5-4.231.577-2.693-.192-5l-17.385-89.385q-.769-3.077-2.692-4.615-1.923-1.539-5-1.539H206q-2.307 0-3.846 1.539-1.539 1.538-1.539 3.846 3.077 41 13.423 83.308 10.347 42.308 28.885 84.385Zm347.999 345.691q39.77 18.539 82.962 28.347 43.193 9.807 80.731 11.653 2.308 0 3.846-1.538 1.539-1.539 1.539-3.847v-84.153q0-3.077-1.539-5.001-1.538-1.923-4.615-2.692l-84-17.077q-2.307-.769-4.038-.192-1.731.577-3.654 2.5l-71.232 72ZM242.923-586.922Zm347.999 345.691Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconCall;
