import { styled } from '@material-ui/core';

const Container = styled('div')({
    paddingRight: '15px',
    paddingLeft:  '15px',
    marginRight:  'auto',
    marginLeft:   'auto',
    '@media (min-width: 768px)': {
        maxWidth: '750px',
    },
    '@media (min-width: 992px)': {
        maxWidth: '970px',
    },
    '@media (min-width: 1200px)': {
        maxWidth: '1230px',
    },
});

export default Container;