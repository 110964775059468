import { HandlerExcepcion } from "../../shared/HandlerExcepcion";
import { URL_API_INTELAF } from "../../shared/Constant";
import packageJson from "../../../package.json"

const HEARDER = {
    'content-type': 'application/json',
    'app-name': 'intelaf-web',
    'app-version': packageJson.version
}

export const getAsync = async(endpoint, headers) => {
    return fetch(URL_API_INTELAF + endpoint, {
        method: 'GET',
        headers: getHeaders(headers)
    }).then(httpClientResponse)
      .then(httpClientResult)
}

export const postAsync = async(endpoint, headers, data) => {
    return fetch(URL_API_INTELAF + endpoint, {
        method: 'POST',
        headers: getHeaders(headers),
        body: data ? JSON.stringify(data) : null 
    }).then(httpClientResponse)
      .then(httpClientResult)
}

export const putAsync = async(endpoint, headers, data) => {
    return fetch(URL_API_INTELAF + endpoint, {
        method: 'PUT',
        headers: getHeaders(headers),
        body: data ? JSON.stringify(data) : null 
    }).then(httpClientResponse)
      .then(httpClientResult)
}

export const deleteAsync = async(endpoint, headers, data) => {
    return fetch(URL_API_INTELAF + endpoint, {
        method: 'DELETE',
        headers: getHeaders(headers),
        body: data ? JSON.stringify(data) : null 
    }).then(httpClientResponse)
      .then(httpClientResult)
}

const getHeaders = (headers) => {
    if(headers !== null && headers !== undefined) {
        if(Object.keys(headers).length > 0) {
            return Object.assign(headers, HEARDER);
        }
        return HEARDER;
    }
    return HEARDER;
}

const httpClientResult = (result) => {
    if(result !== null && result !== undefined) {
        if(result.state !== null && result.state !== undefined) {
            if(result.state.Code !== null && result.state.Code !== undefined) {

                if(result.state.Code === 1103) {
                    localStorage.setItem("app-token", result.Response.Token);
                }

                if(result.state.Code === 200) {
                    return result.Response;
                } else {
                    throw new HandlerExcepcion(result.state.Code, result.state.Message)
                }
            }
        }

        throw new HandlerExcepcion(500, result.toString());
    }

    throw new HandlerExcepcion(500, "Imposible contactar al servidor. Intente más tarde.");
}

const httpClientResponse = async (response) => {
    const appNeedUpdateHeader = response.headers.get('app-need-update');
    if(response.ok) {
        if (appNeedUpdateHeader !== null) {
            const isUpdateNeeded = appNeedUpdateHeader.toLowerCase() === 'true';
            sessionStorage.setItem('app-need-update', JSON.stringify(isUpdateNeeded));
        } else {
            sessionStorage.removeItem('app-need-update');
        }
        return response.json();
    }

    const contentType = response.headers.get('Content-Type');

    if(contentType.includes('application/json')) {
        return response.json();

    } else if (contentType.includes('text/plain')) {
        return response.text();

    } else if (contentType.includes('text/html')) {
        return response.text();

    } else if(contentType.includes('application/xml')) {
        return response.text();
    }
}
