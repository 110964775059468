import { useMetodoDeEntrega } from "../../../contexts/MetodoDeEntrega";
import useCheckOut from "../../../services/intelafApiClient/handler/useCheckOut";
import Capitalize from "../../../shared/Capitalize";
import IconFmdBad from "../../icons/IconFmdBad";
import IconLocationOn from "../../icons/IconLocationOn";
import IconNotListedLocation from "../../icons/IconNotListedLocation";
import IconWhereToVote from "../../icons/IconWhereToVote";
import './css/MetodoEntrega.css'

const MetodoEntrega = (props) => {
    const { openDialogEntrega } = useMetodoDeEntrega();

    const { existencias } = props;
    const {checkout} = useCheckOut();
    const color = {
        noDisponible: 'rgba(237, 0, 6, 1)',
        disponible: 'rgba(52, 189, 99, 1)',
        metodo: 'rgba(19, 180, 188, 1)',
    }

    const Tienda = () => {
        var sucursal = existencias.find(t => t.CodigoSucursal === checkout.SucursalDeEntrega.CodigoSucursal.toString());
        return (
            <>
                {
                    sucursal === undefined ?
                        <>
                            <div className='metodo-de-entrega no-select' style={{ borderColor: `${color.noDisponible}` }}>
                                <IconFmdBad Icon width={23.75} height={24} fill={'rgba(237, 0, 6, 1)'} />
                                <div className='sucursal-activa' style={{ color: `${color.noDisponible}`, minWidth: '200px' }}>
                                    <p className='name'>No Disponible {Capitalize(checkout.SucursalDeEntrega.Nombre)}</p>
                                    <p className='direccion'>{checkout.SucursalDeEntrega.Direccion_corta}</p>
                                </div>
                            </div>
                        </> :
                        <>
                            {
                                sucursal.Existencia > 0 ?
                                    <>
                                        <div className='metodo-de-entrega no-select' style={{ borderColor: `${color.disponible}` }}>
                                            <IconWhereToVote width={23.75} height={24} fill={'rgba(52, 189, 99, 1)'} />
                                            <div className='sucursal-activa' style={{ color: `${color.disponible}` }}>
                                                <p className='name'>{Capitalize(sucursal.NombreSucursal)}</p>
                                                <p className='direccion'>{sucursal.Direccion}</p>
                                            </div>
                                            <div className='cantidad'>
                                                <div className='cantidad-number' style={{ backgroundColor: `${color.disponible}` }}>
                                                    <p>{sucursal.Existencia}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        <div className='metodo-de-entrega' style={{ borderColor: `${color.noDisponible}` }}>
                                            <IconFmdBad width={23.75} height={24} fill={'rgba(237, 0, 6, 1)'} />
                                            <div className='sucursal-activa' style={{ color: `${color.noDisponible}`, minWidth: '200px' }}>
                                                <p className='name'> No Disponible {Capitalize(sucursal.NombreSucursal)}</p>
                                                <p className='direccion'>{sucursal.Direccion}</p>
                                            </div>
                                        </div>
                                    </>
                            }
                        </>
                }
            </>
        )
    }

    const Domicilio = () => {
        var sucursal = existencias.find(t => t.CodigoSucursal === '5');
        return (
            <>
                {
                    sucursal === undefined ?
                        <>
                            <div className='metodo-de-entrega no-select' style={{ borderColor: `${color.noDisponible}` }}>
                                <IconLocationOn width={23.75} height={24} fill={'rgba(237, 0, 6, 1)'} />
                                <div className='sucursal-activa' style={{ color: `${color.noDisponible}`, minWidth: '180px' }}>
                                    <p className='name'> No disponible a domicilio</p>
                                </div>
                            </div>
                        </> :
                        <>
                            {
                                sucursal.Existencia > 0 ?
                                    <>
                                        <div className='metodo-de-entrega no-select' style={{ borderColor: `${color.disponible}` }}>
                                            <IconWhereToVote width={23.75} height={24} fill={'rgba(52, 189, 99, 1)'} />
                                            <div className='sucursal-activa' style={{ color: `${color.disponible}` }}>
                                                <p className='name'>Disponible a domicilio</p>
                                            </div>
                                            <div className='cantidad'>
                                                <div className='cantidad-number' style={{ backgroundColor: `${color.disponible}` }}>
                                                    <p>{sucursal.Existencia}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        <div className='metodo-de-entrega' style={{ borderColor: `${color.noDisponible}` }}>
                                            <IconLocationOn width={23.75} height={24} fill={'rgba(237, 0, 6, 1)'} />
                                            <div className='sucursal-activa' style={{ color: `${color.noDisponible}`, minWidth: '180px' }}>
                                                <p className='name'> No disponible a domicilio</p>
                                            </div>
                                        </div>
                                    </>
                            }
                        </>
                }
            </>
        )
    }

    return (
        <div className={props.className} onClick={openDialogEntrega}>
            {
                    !checkout.IsCarritoActivo ?
                    <>
                        <div className='metodo-de-entrega no-select' style={{ borderColor: `${color.metodo}` }}>
                            <IconNotListedLocation width={23.75} height={24} fill={'rgb(19, 180, 188)'} />
                            <div className='sucursal-activa' style={{ color: `${color.metodo}`, minWidth: '180px' }}>
                                <p className='name'>Método de entrega</p>
                                <p className='direccion'>Elige una tienda o a daaomicilio</p>
                            </div>
                        </div>
                    </> :
                    <>
                        {
                            checkout.MetodoDeEnvio === 'tienda' ?
                                <>
                                    <Tienda></Tienda>
                                </> :
                                <>
                                    <Domicilio></Domicilio>
                                </>
                        }
                    </>
            }
        </div>
    )
}

export default MetodoEntrega