class SearchDto {
    constructor(search) {
        this.Categorias = search.Categorias || [];
        this.Marcas     = search.Marcas     || [];
        this.Productos  = search.Productos  || [];
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}

export default SearchDto;