import React from 'react';

const IconLogout = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M212.309-140.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h238.076q12.769 0 21.384 8.616 8.616 8.615 8.616 21.384t-8.616 21.384Q463.154-760 450.385-760H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v535.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h238.076q12.769 0 21.384 8.615 8.616 8.615 8.616 21.384t-8.616 21.384q-8.615 8.616-21.384 8.616H212.309Zm492.385-310H393.846q-12.769 0-21.384-8.615-8.616-8.615-8.616-21.384t8.616-21.384q8.615-8.615 21.384-8.615h310.848l-76.924-76.923q-8.307-8.308-8.499-20.269-.193-11.961 8.499-21.269 8.693-9.307 21.077-9.615 12.384-.308 21.692 9l123.767 123.768q10.846 10.846 10.846 25.307 0 14.461-10.846 25.307L670.539-330.925q-8.923 8.923-21.192 8.808-12.269-.116-21.577-9.423-8.692-9.308-8.384-21.384.308-12.077 9-20.769l76.308-76.308Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconLogout;
