import metodoDeEnvio from "../enums/metodoDeEnvio";
import metodoDePago from "../enums/metodoDePago";
import formaDePago from "../enums/FormasDePago";

class CheckOutDto {
    constructor(check) {
        this.MetodoDePago       = check.MetodoDePago       || metodoDePago.TARJETA;
        this.MetodoDeEnvio      = check.MetodoDeEnvio      || metodoDeEnvio.DOMICILIO;
        this.QuienEntrega       = check.QuienEntrega       || "";
        this.SucursalDeEntrega  = check.SucursalDeEntrega  || {};
        this.DireccionDeEntrega = check.DireccionDeEntrega || {};
        this.IsCarritoActivo    = this.IsActiveCarrito(this.SucursalDeEntrega);
        this.FormaDePago        = check.FormaDePago          || formaDePago.TARJETA
    }

    IsActiveCarrito(sucursal) {
        return Object.keys(sucursal).length > 0;
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}

export default CheckOutDto;