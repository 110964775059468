class ProductoFavoritoDto {
    constructor(favorito) {
        this.Codigo                   = favorito.Codigo                   || '';
        this.Descripcion              = favorito.Descripcion              || '';
        this.PrecioNormal             = favorito.PrecioNormal             || 0.0;
        this.PrecioAnterior           = favorito.PrecioAnterior           || 0.0;
        this.PrecioDescuento          = favorito.PrecioDescuento          || 0.0;
        this.DescripcionDescuento     = favorito.DescripcionDescuento     || '';
        this.FechaCaducaDescuento     = favorito.FechaCaducaDescuento     || '01/01/0001';
        this.Imagen                   = favorito.Imagen                   || '';
        this.Existencia               = favorito.Existencia               || [];
        this.EnBodega                 = favorito.EnBodega                 || false;
        this.EnTransito               = favorito.EnTransito               || false;
        this.OcultarBodegaTransito    = favorito.OcultarBodegaTransito    || false;
        this.AplicaPromoIndividual    = favorito.AplicaPromoIndividual    || false;
        this.Marca                    = favorito.Marca                    || '';
        this.EsFavorito               = favorito.EsFavorito               || false;
        this.AreaFuncional            = favorito.AreaFuncional            || '';
        this.AreaFuncionalDescripcion = favorito.AreaFuncionalDescripcion || null;
        this.LinkMarca                = favorito.LinkMarca                || null;
        this.FechaCreacion            = favorito.FechaCreacion            || Date.now;
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }));
    }
}


export default ProductoFavoritoDto;
