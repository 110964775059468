import useMenuHandler from '../../../../redux/handler/useMenuHandler';
import { useEffect } from 'react';

const useMenuService = () => {
    const {  menu, handleGetMenuAsync } = useMenuHandler();

    useEffect(() => {
      if(menu.length === 0) {
        handleGetMenuAsync();
      }

      // eslint-disable-next-line
    }, [])

  return {
    menu,
  }
}

export default useMenuService
