import React from 'react';
import './style.css'
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const Contactanos = () => {
    const isDesktop = useMediaQuery('(min-width:992px)');

  return (
    <div>
        <br/>
        <div className="footer-seccion-contacto">
            <div>
                <p className="footer-seccion-titulo">Nuestra App</p>
                <div>Descarga gratis nuestra aplicación</div>
                <br></br>
                <a href="https://play.google.com/store/apps/details?id=com.intelaf.intelaf_app" target="_blank" rel="noreferrer" className="hide-text">
                    <img className="footer-seccion-contacto-virtual-store" src="https://www.intelaf.com/img/footer/playstore.png" alt="Descarga en playstore" loading="lazy" />
                    App
                </a>
                <a href="https://apps.apple.com/app/id6450431987" target="_blank" rel="noreferrer" className="hide-text">
                    <img className="footer-seccion-contacto-virtual-store" src="https://www.intelaf.com/img/footer/appstore.png" alt="Descarga en appstore" loading="lazy" />
                    Ios
                </a>
            </div>
            <div style={ isDesktop ? { justifySelf: 'center' } : {}}>
                <p className="footer-seccion-titulo">Contáctanos</p>
                <div className="footer-seccion-contacto-contactanos-grid">
                    <Link to="/tiendas">
                        <div className="footer-seccion-contacto-contactanos" >
                            <img style={{maxWidth:"20px", marginRight:"10px"}} src="https://www.intelaf.com/img/footer/call.png" alt="Comunicarse a una tienda" loading="lazy" />
                            Comunicarse a una tienda
                        </div>
                    </Link>
                    <a href="mailto:sugerencias@intelaf.com" target="_blank" rel="noreferrer">
                        <div className="footer-seccion-contacto-contactanos">
                            <img style={{maxWidth:"20px", marginRight:"10px"}} src="https://www.intelaf.com/img/footer/mail.png" alt="Sugerencias a Intelaf" loading="lazy" />
                            sugerencias@intelaf.com
                        </div>
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=50258330003" target="_blank" rel="noreferrer" >
                        <div className="footer-seccion-contacto-contactanos">
                            <img style={{maxWidth:"20px", marginRight:"10px"}} src="https://www.intelaf.com/img/footer/message.png" alt="Chat en linea" loading="lazy" />
                            Chat en línea
                        </div>
                    </a>
                </div>
            </div>
            <div style={ isDesktop ? { justifySelf: 'end' } : {}}>
                <img src="https://www.intelaf.com/img/footer/linkie-preguntas.png" width={123} height={111} alt="logoyipo" loading="lazy"></img>
                <p>¿Cómo ha sido tu experiencia con el sitio?<br />
                    <a href="mailto:sugerencias@intelaf.com"><b>Danos tu opinión acerca de nuestro sitio web</b></a>
                </p>
            </div>
        </div>
        <br/>
    </div>
  )
}

export default Contactanos
