import { useState } from 'react'
import { postSubscriptionLetter } from '../../../../../../services/intelafApiClient/repository/newsLetterRepository';
import useExcepcion from '../../../../../../shared/hooks/useExcepcion';
import Swal from '../../../../../../shared/SweetAlert';

const useNewLetter = () => {
    const [loader, setLoader] = useState(false);
    
    const { handleMessageException } = useExcepcion();

    const handleSubscritionLatter = async (email) => {
        try {

            if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
                throw new Error('El correo electrónico no es válido');
            }

            setLoader(true);
            await postSubscriptionLetter(email);

            Swal.fire({
                title: 'Exitoso',
                text: 'Se envió un mensaje a tu correo electrónico para confirmar la subscripción.',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'Orange',
            });

        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        } 
    }

  return {
    loader, 
    handleSubscritionLatter
  }
}

export default useNewLetter;