import React from 'react';

const IconArrowForwardIos = (props) => {
  return (
    <svg
      style={props.style}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M593.232-480 291.924-781.308q-11.923-11.923-11.616-28.384.308-16.462 12.231-28.384 11.923-11.923 28.384-11.923t28.384 11.923L655.538-531.23q10.846 10.846 16.077 24.307 5.23 13.462 5.23 26.923 0 13.461-5.23 26.923-5.231 13.461-16.077 24.307L348.692-121.924q-11.923 11.923-28.077 11.616-16.153-.308-28.076-12.231-11.923-11.923-11.923-28.384t11.923-28.384L593.232-480Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconArrowForwardIos;
