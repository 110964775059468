import { createSlice } from "@reduxjs/toolkit";
import UsuarioDto from "../../models/usuarioDto";

const getLocalFromUser = () => {
    const usuario = localStorage.getItem('app-usuario');
    return usuario ? JSON.parse(usuario) :  new UsuarioDto({}).toJSON();
}

const setLocalFromUser = (usuario) => {
    localStorage.setItem('app-usuario', JSON.stringify(usuario));
    localStorage.setItem("app-token", usuario.Token);
}

const handlerResetUsuario = () => {
  let usuario = new UsuarioDto({}).toJSON();
  setLocalFromUser(usuario);
  return usuario;
}

const handlerUpdateUsuario = (state, action) => {
  let usuario =  new UsuarioDto({ ...state, ...action.payload }).toJSON();
  setLocalFromUser(usuario);
  return usuario;
}

const handlerCreateUsuario = (state, action) => {
  let usuario = new UsuarioDto(action.payload).toJSON();
  setLocalFromUser(usuario);
  return usuario;
}

const usuarioSlice = createSlice({
    name: 'usuario',
    initialState: getLocalFromUser(),
    reducers: {
      resetUsuario: handlerResetUsuario,
      updateUsuario: handlerUpdateUsuario,
      createUsuario: handlerCreateUsuario,
    },
});

export const usuarioReducer   = usuarioSlice.reducer;
export const { resetUsuario, updateUsuario, createUsuario } = usuarioSlice.actions;

