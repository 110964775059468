import React from 'react';
import IconHome from "../../../../components/icons/IconHome";
import IconStoreFront from "../../../../components/icons/IconStoreFront";
import './index.css';
import { useMediaQuery } from '@material-ui/core';

const SwitchMetodoEntrega = (props) => {
    const { metodo } = props;

    const media = useMediaQuery('(max-width:500px)')

    return (
        <>
            <div className="switch-metodo-card">
                <div
                    className={metodo === 'domicilio' ? 'switch-radio switch-radio-activo' : 'switch-radio'}
                    onClick={() => props.onClick('domicilio')}
                >
                    <IconHome height={30} width={30} fill={"#6C6C6C"} />
                    <p>{ media ? 'A domicilio' : 'Entregar a domicilio'  }</p>
                </div>
                <div
                    className={metodo === 'tienda' ? 'switch-radio switch-radio-activo' : 'switch-radio'}
                    onClick={() => props.onClick('tienda')}
                >
                    <IconStoreFront height={30} width={30} fill={"#6C6C6C"} />
                    <p>{ media ? 'Tienda': 'Recoger en tienda' }</p>
                </div>
            </div>
        </>
    )
}

export default SwitchMetodoEntrega;
