class ProductoExistenciaDto {
    constructor(existencia) {
        this.Codigo      = existencia.Codigo      || '';
        this.Descripcion = existencia.Descripcion || '';
        this.Existencias = existencia.Existencias || [];
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}

export default ProductoExistenciaDto;