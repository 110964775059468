import React from 'react'

const IconSlowMotionVideo = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="m431.77-345.386 174.229-111.922q12.846-8.231 12.846-22.692t-12.846-22.692L431.77-614.614q-13.462-9.231-27.616-1.5-14.153 7.73-14.153 24.192v223.844q0 16.462 14.153 24.192 14.154 7.731 27.616-1.5ZM131.385-440q12.769 0 22.615 8.5 9.846 8.5 13.692 21.653 6.385 27.616 16.616 52.732Q194.539-332 209-308.922q7.077 11.153 6.154 23.922t-10.23 22.076q-8.308 8.308-20.269 7.308-11.961-1-19.038-10.923-23.539-32.923-38.346-68.039-14.808-35.115-22.039-74.653-2.231-12.154 5.577-21.462Q118.616-440 131.385-440Zm73.539-256.692q9.307 9.308 9.922 22.077.616 12.769-5.846 23.307-14.461 23.077-24.5 48.308-10.039 25.231-16.423 52.847Q164.231-537 154.193-528.5q-10.039 8.5-22.808 8.5t-20.576-9.807q-7.808-9.808-4.961-21.962 7.23-39.153 21.846-73.884 14.615-34.731 37.538-67.654 7.077-9.923 19.038-11t20.654 7.615Zm56 490.384q9.307-9.923 22.576-10.346 13.269-.423 24.422 6.654 22.693 14.461 48.001 25.192 25.308 10.731 52.693 17.116 12.769 3.461 21.077 13.192Q438-144.77 438-132.001q0 12.769-9.615 20.269-9.615 7.5-21.769 4.884-40.692-8-74-22.615-33.308-14.615-66.462-36.538-10.538-7.077-12.23-19.038-1.693-11.962 7-21.269ZM440-827.999q0 13.153-8.192 22.692-8.192 9.538-21.346 13.384-28 6.384-53.424 16.615-25.423 10.231-48.116 25.308-11.153 7.077-24.23 6.154-13.076-.923-22.384-10.23-8.923-8.923-7.615-21.192 1.308-12.269 11.846-19.346 33.539-21.923 67.462-36.231 33.923-14.307 75-22.307 12.153-2.231 21.576 5.076Q440-840.768 440-827.999ZM800-480q0-114.923-71.808-202.193-71.808-87.269-182.5-111.346-11.538-2.846-18.615-12.576Q520-815.846 520-827.999q0-12.154 8.692-19.961 8.692-7.808 19.615-5.577 135 24.923 223.346 129.307Q859.999-619.846 859.999-480t-88.346 244.23q-88.346 104.384-223.346 129.307-10.923 2.231-19.615-5.577Q520-119.847 520-132.001q0-12.153 7.077-21.884 7.077-9.73 18.615-12.576 110.692-24.077 182.5-111.346Q800-365.077 800-480Z"
        fill={props.fill}
      />
    </svg>
  )
}

export default IconSlowMotionVideo