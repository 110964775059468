class ProductoDto {
    constructor(producto) {
        this.Codigo                   = producto.Codigo                   || '';
        this.Descripcion              = producto.Descripcion              || '';
        this.PrecioNormal             = producto.PrecioNormal             || 0.0;
        this.PrecioAnterior           = producto.PrecioAnterior           || 0.0;
        this.PrecioDescuento          = producto.PrecioDescuento          || 0.0;
        this.DescripcionDescuento     = producto.DescripcionDescuento     || '';
        this.FechaCaducaDescuento     = producto.FechaCaducaDescuento     || '';
        this.Imagen                   = producto.Imagen                   || '';
        this.Existencia               = producto.Existencia               || [];
        this.EnBodega                 = producto.EnBodega                 || false;
        this.EnTransito               = producto.EnTransito               || false;
        this.OcultarBodegaTransito    = producto.OcultarBodegaTransito    || false;
        this.AplicaPromoIndividual    = producto.AplicaPromoIndividual    || false;
        this.Marca                    = producto.Marca                    || '';
        this.EsFavorito               = producto.EsFavorito               || false;
        this.AreaFuncional            = producto.AreaFuncional            || '';
        this.AreaFuncionalDescripcion = producto.AreaFuncionalDescripcion || '';
        this.LinkMarca                = producto.LinkMarca                || '';
        this.FechaCreacion            = producto.FechaCreacion ? new Date(producto.FechaCreacion) : new Date();
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}

export default ProductoDto;