import React from 'react';

const IconTwitter = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 0 24 22"
      width={props.width}
    >
      <path d="M18.6703 0.200195H22.2272L14.4564 9.08172L23.5981 21.1675H16.4402L10.8339 13.8375L4.41897 21.1675H0.85991L9.17156 11.6677L0.401855 0.200195H7.74148L12.8091 6.90005L18.6703 0.200195ZM17.4219 19.0385H19.3928L6.67054 2.21736H4.55553L17.4219 19.0385Z"
      fill={props.fill}
      />
    </svg>
  );
};

export default IconTwitter;