import React from 'react';
import { Outlet } from "react-router-dom";
import useSucursalesService from "./components/useSucursalesService";
import { usePageTracking } from "../../shared/hooks/usePageTracking";
import Nav from '../../components/misc/Nav';
import Footer from '../../components/misc/Footer';
import { useAppUdate } from '../../contexts/AppUpdate';


const LayoutPage = () => {
  const { sucursales, loader } = useSucursalesService();

  usePageTracking();
  const {searchForUpdates} = useAppUdate()

  return (
    <>
      <Nav />
      <main style={{ padding: '0px' }}>
        <Outlet />
      </main>
      <footer style={{ background: "#f7f7f7", paddingTop: '10px' }}>
        <Footer loadingSucursales={loader} sucursales={sucursales}/>
      </footer>
    </>
  )
}

export default LayoutPage;