class CarritoItemDto {
    constructor(productoData) {
        this.Imagen                  = productoData.Imagen                  || '';
        this.CodigoInventario        = productoData.CodigoInventario        || '';
        this.Descripcion             = productoData.Descripcion             || '';
        this.Area                    = productoData.Area                    || '';
        this.AreaFuncional           = productoData.AreaFuncional           || '';
        this.AreaFuncional2          = productoData.AreaFuncional2          || '';
        this.PrecioUnitario          = productoData.PrecioUnitario          || 0.0;
        this.Descuento               = productoData.Descuento               || 0.0;
        this.SubTotal                = productoData.SubTotal                || 0.0;
        this.Cantidad                = productoData.Cantidad                || 0.0;
        this.UnidadesCajaMaestra     = productoData.UnidadesCajaMaestra     || 0.0;
        this.Dim1                    = productoData.Dim1                    || 0.0;
        this.Dim2                    = productoData.Dim2                    || 0.0;
        this.Dim3                    = productoData.Dim3                    || 0.0;
        this.VolCuanto               = productoData.VolCuanto               || 0.0;
        this.NombreDescuentoAplicado = productoData.NombreDescuentoAplicado || '';
        this.PromoAceptaTarjeta      = productoData.PromoAceptaTarjeta      || '';
        this.PromoCantCuotas         = productoData.PromoCantCuotas         || 0;
        this.Marca                   = productoData.Marca                   || '';
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}

export default CarritoItemDto;