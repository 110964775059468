import { useEffect, useState } from 'react';
import { getProductoDetalleAsync } from '../repository/productoRepository'
//import useExcepcion from '../../../shared/hooks/useExcepcion';
import useCheckOutHandler from '../../../redux/handler/useCheckOutHandler';
import { useNavigate } from 'react-router';
import { startTransition } from 'react';

const useProducto = (codigo) => {
    // state
    const [loader, setLoader] = useState(true);
    const [producto, setProducto] = useState(null);
    const navigate = useNavigate();

    // Hooks
   // const { handleMessageException } = useExcepcion();

    //Redux
    const { checkout } = useCheckOutHandler();

    useEffect(() => {
        setLoader(true);

        startTransition(() => {
            getProductoDetalleAsync(codigo, checkout.IsCarritoActivo ? checkout.SucursalDeEntrega.CodigoSucursal : '')
            .then(d => {
    
                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                    'ecommerce': {
                        'detail': {
                            'actionField': { 'list': 'Product Gallery' },    // 'detail' actions have an optional list property.
                            'products': [{
                                'name': d.Descripcion || '',         // Name or ID is required.
                                'id': d.Codigo,
                                'price': d.PrecioNormal,
                                'brand': d.Marca,
                                'category': d.AreaFuncional,
                                'variant': ''
                            }]
                        }
                    }
                });
    
                setProducto(d)
            }).catch(err => {
                navigate("/404")
                //handleMessageException(err);
            }).finally(() => {
                setLoader(false);
            })
        });

        //eslint-disable-next-line
    }, [checkout.SucursalDeEntrega, codigo])
    
    return {loader, producto}
}

export default useProducto;