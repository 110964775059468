import { createAdapterBanner  } from "../../../adapter/bannerAdapter";
import { getAsync } from "../httpServiceIntelafApi";

const URI = '/Banner';

export const getBannersHomeMain = () => {
    return getAsync(URI,{}).then((response) => {
        return createAdapterBanner(response);
    });
};

export const getBannerAleatorio = (area, tipo = "producto") => {
    return getAsync(URI + `?tipo=${tipo}&busqueda=${area}`, {}).then((response) => {
        return createAdapterBanner(response)
    });
}
