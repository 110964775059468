import { createSlice } from "@reduxjs/toolkit";

const getSessionFromMenu = () => {
    const menu = sessionStorage.getItem('app-categorias');
    return menu ? JSON.parse(menu) : []
}

const setSessionFromMenu = (menu) => {
  sessionStorage.setItem('app-categorias', JSON.stringify(menu));
}

const handlerResetMenu = () => {
  setSessionFromMenu([]);
  return [];
}

const handlerCreateMenu = (state, action) => {
  let menu = action.payload;
  setSessionFromMenu(menu);
  return menu;
}

const menuSlice = createSlice({
    name: 'categoria',
    initialState: getSessionFromMenu(),
    reducers: {
      resetMenu: handlerResetMenu,
      createMenu: handlerCreateMenu,
    },
});

export const menuReducer = menuSlice.reducer;
export const { resetMenu, createMenu } = menuSlice.actions;
