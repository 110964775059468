import React from 'react'

const IconVerifiedUser = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="m438-437.384-63.693-63.692q-8.923-8.923-20.884-9.116-11.961-.192-21.269 9.116-9.307 9.307-9.307 21.384 0 12.076 9.307 21.384l80.539 81.153q10.846 10.846 25.307 10.846 14.461 0 25.307-10.846l165.769-165.769q8.923-8.922 9.116-21.191.192-12.269-9.116-21.577-9.307-9.307-21.384-9.307-12.076 0-21.384 9.307L438-437.384Zm42 332.229q-6.231 0-12.231-1t-11.615-3q-126.538-45-201.345-159.577Q180.001-383.308 180.001-516v-180.153q0-22.692 13.154-40.961 13.154-18.27 33.846-26.5l227.692-85q12.846-4.615 25.307-4.615 12.461 0 25.307 4.615l227.692 85q20.692 8.23 33.846 26.5 13.154 18.269 13.154 40.961V-516q0 132.692-74.808 247.268-74.807 114.577-201.345 159.577-5.615 2-11.615 3t-12.231 1ZM480-164q104-33 172-132t68-220v-180.538q0-3.846-2.116-6.923-2.115-3.078-5.962-4.616l-227.691-85q-1.923-.769-4.231-.769t-4.231.769l-227.691 85q-3.847 1.538-5.962 4.616-2.116 3.077-2.116 6.923V-516q0 121 68 220t172 132Zm0-315.231Z"
        fill={props.fill}
      />
    </svg>
  )
}

export default IconVerifiedUser