import React from 'react';

const IconDomain = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M94.617-197.692v-578.463q0-25.307 17.73-43.038 17.73-17.73 43.037-17.73h258.463q25.307 0 43.038 17.73 17.73 17.731 17.73 43.038v99.232h330.001q25.307 0 43.037 17.73 17.731 17.731 17.731 43.038v418.463q0 25.307-17.731 43.037-17.73 17.731-43.037 17.731H155.384q-25.307 0-43.037-17.731-17.73-17.73-17.73-43.037Zm59.998.769h100.001v-100.001H154.615v100.001Zm0-160h100.001v-100.001H154.615v100.001Zm0-160h100.001v-100.001H154.615v100.001Zm0-160h100.001v-100.001H154.615v100.001Zm160 480h100.001v-100.001H314.615v100.001Zm0-160h100.001v-100.001H314.615v100.001Zm0-160h100.001v-100.001H314.615v100.001Zm0-160h100.001v-100.001H314.615v100.001Zm160 480h330.77v-420.001h-330.77v100.001h87.693v59.999h-87.693v100.001h87.693v59.999h-87.693v100.001Zm175.386-260.001v-59.999h59.998v59.999h-59.998Zm0 160v-59.999h59.998v59.999h-59.998Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconDomain;
