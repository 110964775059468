class BannerDto {
    constructor(data) {
        this.BannerPrincipal = this.parseBannesItem(data.BannerPrincipal);
        this.BannerAleatorio = data.BannerAleatorio || '';
    }
    
    parseBannesItem(banners) {
        if(banners) {
            return banners.map(banner => new BannerPrincipalDto(banner));
        }
        
        return [];
    }
    
    toJSON() {    
        return JSON.parse(JSON.stringify({ ...this }));
    }
}

class BannerPrincipalDto {
    constructor(banner) {
        this.DescripcionImagen = banner.DescripcionImagen;
        this.NombreImagen      = banner.NombreImagen;
        this.NombreImagenMovil = banner.NombreImagenMovil;
        this.LinkImagen        = banner.LinkImagen;
        this.OrdenImagen       = banner.OrdenImagen;
        this.Secuencia         = banner.Secuencia;
        this.Activo            = banner.Activo;
        this.FechaInicio       = banner.FechaInicio;
        this.FechaFinal        = banner.FechaFinal;
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }));
    }
}

export default BannerDto;