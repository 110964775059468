class MenuDto {
    constructor(menu) {
        this.Id       = menu.Id       || 0;
        this.Nombre   = menu.Nombre   || '';
        this.Area     = menu.Area     || '';
        this.MenuSub1 = menu.MenuSub1 || [];
        this.Imagen   = menu.Imagen   || '';
        this.Orden    = menu.Orden    || 0;
        this.Select   = menu.Select   || false;
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}

export default MenuDto;