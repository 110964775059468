import React, { useState } from 'react';
import IconInventory from '../../../icons/IconInventory';
import { Tooltip, styled } from '@material-ui/core'; 
import { useMetodoDeEntrega } from "../../../../contexts/MetodoDeEntrega";
import IconStoreFront from '../../../icons/IconStoreFront';
import IconLocationOn from '../../../icons/IconLocationOn';
import metodoDeEnvio from '../../../../enums/metodoDeEnvio';
import IconHelp from '../../../icons/IconHelp';

const TitleMetodo = ({ checkout, media }) => {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };

    const { openDialogEntrega } = useMetodoDeEntrega();

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
        <Content onClick={()  => openDialogEntrega({}) }>
            {
                checkout.IsCarritoActivo ? 
                <>
                    {
                        checkout.MetodoDeEnvio === metodoDeEnvio.TIENDA ? 
                        <>
                            <IconStoreFront width={18} height={18} fill={"#808080"}/>

                            {
                                media ? 
                                <>
                                    <Tienda>{ checkout.SucursalDeEntrega.Nombre.toCapitalize() }</Tienda>
                                    <Bar>|</Bar>
                                    <Direccion>{ checkout.SucursalDeEntrega.Direccion_corta.toCapitalize() }</Direccion>
                                </> : 
                                <>
                                    <Tienda>Pickup { checkout.SucursalDeEntrega.Nombre.toCapitalize() }</Tienda>
                                    <Bar>|</Bar>
                                    <IconLocationOn width={18} height={18} fill={"#808080"}/>
                                    <Direccion>{ checkout.SucursalDeEntrega.Direccion_corta.toCapitalize() }</Direccion>
                                </>
                            }

                         
                        </> : 
                        <>
                            <IconStoreFront width={18} height={18} fill={"#808080"}/>
                            <Tienda>Entrega a Domicilio</Tienda>
                        </>
                    }
                </> : 
                <>
                    <IconInventory  width={18} height={18} fill={"#808080"}/>
                    <Tienda>Elegir método de entrega</Tienda>
                </>
            }
        </Content>
        { 
            checkout.MetodoDeEnvio === metodoDeEnvio.TIENDA && checkout.SucursalDeEntrega.CodigoSucursal === 5 ? 
            <>
                <MuiTooltip open={open} onClose={handleClose} onOpen={handleOpen} title={<Titletip><strong>¡Atención!</strong> Esta sucursal no funciona como una tienda tradicional. No ofrecemos ventas físicas. En cambio, puedes realizar pedidos a través de nuestro sitio web y luego recogerlos en la sucursal.</Titletip>} >
                    <span>
                        <IconHelp width={18} height={18} fill={"red"}/>
                    </span>
                </MuiTooltip>
            </> : <></> 
        }
    </div>
  )
}

const Titletip = styled('p')({
    fontSize: '12px', 
    margin: '0px', 
    padding: '5px',
    lineHeight: 1.5
})

const MuiTooltip = styled(Tooltip)({
    height: '10px',
    tooltipPlacementBottom: {
        margin: '0px 0px'
    }
})

const Content = styled('div')({
    display: 'flex', 
    gap: '10px',
    '&:hover > span': {
        color: '#F0761D'  
    },
    '&:hover > svg > path': {
        fill: '#F0761D'  
    },
    cursor: 'pointer'
})

const Tienda = styled('span')({
    color: 'grey',
    fontSize: '13px',
    fontWeight: '400',
    letterSpacing: '-.25px',
    textDecoration: 'none',
    fontFamily: 'Inter',
    '&:hover': {
        textDecoration: 'none',
        color: '#F0761D'
    },
    '&:focus': {
        textDecoration: 'none',
        color: '#F0761D'
    }
});

const Bar = styled('span')({
    color: 'rgb(197, 197, 197)',
    fontSize: '12px',
    fontWeight: '100',
    letterSpacing: '-.25px',
    textDecoration: 'none',
    fontFamily: 'Inter',
});

const Direccion = styled('span')({
    color: 'grey',
    fontSize: '13px',
    fontWeight: '400',
    letterSpacing: '-.25px',
    textDecoration: 'none',
    fontFamily: 'Inter',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '210px',
    '&:hover': {
        textDecoration: 'none',
        color: '#F0761D'
    },
    '&:focus': {
        textDecoration: 'none',
        color: '#F0761D'
    }
});

export default TitleMetodo;
