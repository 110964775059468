import React from 'react';

const IconFmdBad = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path d="M480.013-496.537q12.756 0 21.371-8.625 8.615-8.624 8.615-21.374v-153.849q0-12.749-8.628-21.374-8.629-8.625-21.384-8.625-12.756 0-21.371 8.625-8.615 8.625-8.615 21.374v153.849q0 12.75 8.628 21.374 8.629 8.625 21.384 8.625ZM480-357.307q13.731 0 23.019-9.289 9.288-9.288 9.288-23.019 0-13.73-9.288-23.018-9.288-9.289-23.019-9.289-13.731 0-23.019 9.289-9.288 9.288-9.288 23.018 0 13.731 9.288 23.019 9.288 9.289 23.019 9.289Zm0 177.846q117.384-105.076 179.654-201.577 62.269-96.5 62.269-169.039 0-109.384-69.5-179.846T480-800.385q-102.923 0-172.423 70.462t-69.5 179.846q0 72.539 62.269 169.039Q362.616-284.537 480-179.461Zm0 57.075q-11.692 0-23.384-4.038-11.692-4.039-21.154-12.5-53.845-49.615-100.768-102.191-46.923-52.577-81.577-105.115-34.654-52.539-54.846-104.154-20.193-51.616-20.193-99.693 0-138.46 89.577-224.191Q357.231-859.999 480-859.999t212.345 85.731q89.577 85.731 89.577 224.191 0 48.077-20.193 99.501-20.192 51.423-54.654 104.154-34.461 52.73-81.384 105.115-46.923 52.384-100.769 101.998-9.323 8.462-21.189 12.693-11.867 4.23-23.733 4.23Zm0-435.306Z" fill={props.fill}
      />
    </svg>
  );
};

export default IconFmdBad;
