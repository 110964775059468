export default function useMetaTag(meta) {

    const metaTitle       = document.querySelector('meta[name="Title"]');
    const metaDescripcion = document.querySelector('meta[name="Description"]');
    const metaKeywords    = document.querySelector('meta[name="Keywords"]');

    if(meta.Title === undefined){
        metaTitle.setAttribute('content', "Intelaf - Te rodeamos de Computadoras, Laptops, Tablets, Celulares, Camaras Digitales, Smartphones, Notebooks y mas en Guatemala");
    }else{
        metaTitle.setAttribute('content', meta.Title + " Guatemala - Intelaf");
    }

    if(meta.Description === undefined){
        metaDescripcion.setAttribute('content', "Intelaf | Compra en Intelaf Guatemala los mejores productos en tecnología como: laptop, vigilancia, webcam, monitor lcd, monitor led, memoria, teclado, red, router, switch, scanner, impresora, toner, bocinas, audífonos, auriculares, entretenimiento, tarjetas de video, tablet, smartphone, celular, Android y IOS. Procesadores i3, Windows, Xiaomi, Xtech, Zagg. ¡Encuentra todo lo que necesitas para tu hogar u oficina!");
    }else{
        metaDescripcion.setAttribute('content', meta.Description);
    }

    if(meta.Keywords === undefined){
       metaKeywords.setAttribute('content', "Intelaf, Guatemala, computadora, notebook, impresora, scanner, camara, webcams, monitor, memoria, disco duro, teclado, modem, red, router, Canon, HP, Genius, Manhattan, Trendnet, Toshiba, Samsung, tablet,smartphone,phone,cell,celular");
    }else{
        metaKeywords.setAttribute('content', meta.Keywords);
    }

    if(meta.OgTag) {
        const iEsOG   = document.querySelector('meta[name="og:title"]');
        if(iEsOG) {

            const ogMetaTitle   = document.querySelector('meta[name="og:title"]');
            const ogMetaUrl     = document.querySelector('meta[name="og:url"]');
            const ogMetaImage   = document.querySelector('meta[name="og:image"]'); 
            const ogMetaDescrip = document.querySelector('meta[name="og:description"]');
            const ogMetaLocale  = document.querySelector('meta[name="og:locale"]');
            const ogMetaType    = document.querySelector('meta[name="og:type"]');
    
            if(meta.tipo === 'producto') {
                ogMetaTitle.setAttribute('content', meta.Title);
                ogMetaUrl.setAttribute('content', "https://www.intelaf.com/precios_stock_detallado/"+ meta.Codigo.toLowerCase());
                ogMetaImage.setAttribute('content', "https://www.intelaf.com/images/productos/gran/"+ meta.Codigo +".jpg");
                ogMetaDescrip.setAttribute('content', meta.Description);
                ogMetaLocale.setAttribute('content', "es_GT");
                ogMetaType.setAttribute('content', 'product');
    
            } else if(meta.tipo === 'marca') {
                ogMetaTitle.setAttribute('content', meta.Title);
                ogMetaUrl.setAttribute('content', `https://www.intelaf.com/marca/${meta.Codigo}`);
                ogMetaImage.setAttribute('content', `https://www.intelaf.com/img/marca/${meta.Codigo}/${meta.Codigo}.png`);
                ogMetaDescrip.setAttribute('content', meta.Description);
                ogMetaLocale.setAttribute('content', "es_GT");
                ogMetaType.setAttribute('content', 'website');
            } else {

                if(meta.Title) {
                    ogMetaTitle.setAttribute('content', meta.Title);
                    ogMetaUrl.setAttribute('content', window.location.href);
                    ogMetaImage.setAttribute('content', "https://www.intelaf.com/img/top_logo_new_white.png");
    
                    if(meta.Description) {
                        ogMetaDescrip.setAttribute('content', meta.Description);
                    }
    
                    ogMetaLocale.setAttribute('content', "es_GT");
                    ogMetaType.setAttribute('content', 'website');
                }           
                
            }

        } else {

            if(meta.tipo === 'producto') {
                const nuevoTitle = document.createElement('meta');
                nuevoTitle.name = 'og:title';
                nuevoTitle.content = meta.Title;
    
                const nuevaUrl = document.createElement('meta');
                nuevaUrl.name = 'og:url';
                nuevaUrl.content = "https://www.intelaf.com/precios_stock_detallado/"+meta.Codigo.toLowerCase();
    
                const nuevaImagen = document.createElement('meta');
                nuevaImagen.name = 'og:image'; 
                nuevaImagen.content = "https://www.intelaf.com/images/productos/gran/"+meta.Codigo+".jpg";
    
                const nuevaDescrip = document.createElement('meta');
                nuevaDescrip.name = 'og:description'; 
                nuevaDescrip.content = meta.Description;
    
                const nuevoLocale = document.createElement('meta');
                nuevoLocale.name = 'og:locale'; 
                nuevoLocale.content = "es_GT";

                const nuevoTipo = document.createElement('meta');
                nuevoTipo.name = 'og:type'; 
                nuevoTipo.content = "product";
    
                document.head.appendChild(nuevoTitle);
                document.head.appendChild(nuevaUrl);
                document.head.appendChild(nuevaImagen);
                document.head.appendChild(nuevaDescrip);
                document.head.appendChild(nuevoLocale);
                document.head.appendChild(nuevoTipo);

            } else if(meta.tipo === 'marca') {
                const nuevoTitle = document.createElement('meta');
                nuevoTitle.name = 'og:title';
                nuevoTitle.content = meta.Title;
    
                const nuevaUrl = document.createElement('meta');
                nuevaUrl.name = 'og:url';
                nuevaUrl.content = `https://www.intelaf.com/marca/${meta.Codigo}`;
    
                const nuevaImagen = document.createElement('meta');
                nuevaImagen.name = 'og:image'; 
                nuevaImagen.content = `https://www.intelaf.com/img/marca/${meta.Codigo}/${meta.Codigo}.png`;
    
                const nuevaDescrip = document.createElement('meta');
                nuevaDescrip.name = 'og:description'; 
                nuevaDescrip.content = meta.Description;
    
                const nuevoLocale = document.createElement('meta');
                nuevoLocale.name = 'og:locale'; 
                nuevoLocale.content = "es_GT";

                const nuevoTipo = document.createElement('meta');
                nuevoTipo.name = 'og:type'; 
                nuevoTipo.content = "website";
    
                document.head.appendChild(nuevoTitle);
                document.head.appendChild(nuevaUrl);
                document.head.appendChild(nuevaImagen);
                document.head.appendChild(nuevaDescrip);
                document.head.appendChild(nuevoLocale);
                document.head.appendChild(nuevoTipo);

            } else if(meta.Title) {
                const nuevoTitle = document.createElement('meta');
                nuevoTitle.name = 'og:title';
                nuevoTitle.content = meta.Title;
    
                const nuevaUrl = document.createElement('meta');
                nuevaUrl.name = 'og:url';
                nuevaUrl.content = `${window.location.href}`;
    
                const nuevaImagen = document.createElement('meta');
                nuevaImagen.name = 'og:image'; 
                nuevaImagen.content = `https://www.intelaf.com/img/top_logo_new_white.png`;
    
                if(meta.Description) {
                    const nuevaDescrip = document.createElement('meta');
                    nuevaDescrip.name = 'og:description'; 
                    nuevaDescrip.content = meta.Description;
                    document.head.appendChild(nuevaDescrip);
                }
    
                const nuevoLocale = document.createElement('meta');
                nuevoLocale.name = 'og:locale'; 
                nuevoLocale.content = "es_GT";

                const nuevoTipo = document.createElement('meta');
                nuevoTipo.name = 'og:type'; 
                nuevoTipo.content = "website";
    
                document.head.appendChild(nuevoTitle);
                document.head.appendChild(nuevaUrl);
                document.head.appendChild(nuevaImagen);
                document.head.appendChild(nuevoLocale);
                document.head.appendChild(nuevoTipo);
            }

        }


    } else {
        const ogMetaTitle   = document.querySelector('meta[name="og:title"]');
        const ogMetaUrl     = document.querySelector('meta[name="og:url"]');
        const ogMetaImage   = document.querySelector('meta[name="og:image"]');
        const ogMetaLocale  = document.querySelector('meta[name="og:locale"]');
        const ogMetaDescrip = document.querySelector('meta[name="og:description"]');
        const ogMetaType    = document.querySelector('meta[name="og:type"]');

        if(ogMetaTitle){
            ogMetaTitle.remove();
            ogMetaUrl.remove();
            ogMetaImage.remove();
            ogMetaDescrip.remove();
            ogMetaLocale.remove();
            ogMetaType.remove();
        }
    }
}