import React from 'react'

const IconFavorite = (props) => {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" height={props.height} viewBox="0 -960 960 960" width={props.width}>
        <path d="M479.615-171.617q-12.846 0-25.807-4.616-12.962-4.615-22.808-14.461l-57.46-52.23q-106.385-97-189.962-190.577Q100.001-527.078 100.001-634q0-85.153 57.423-142.576Q214.847-833.999 300-833.999q48.384 0 95.577 22.308 47.192 22.308 84.423 72.462 37.231-50.154 84.423-72.462 47.193-22.308 95.577-22.308 85.153 0 142.576 57.423Q859.999-719.153 859.999-634q0 108.076-85 202.73T585.46-242.309l-56.845 51.615q-9.846 9.846-23 14.461-13.154 4.616-26 4.616Zm-28.384-506.844q-32.461-49.462-68.346-72.5Q347-774 300-774q-60 0-100 40t-40 100q0 48.154 31.038 100.692 31.038 52.538 77.922 104.46 46.885 51.923 101.577 101.462 54.692 49.538 101.385 92.078 3.462 3.077 8.078 3.077t8.078-3.077q46.693-42.54 101.385-92.078 54.692-49.539 101.577-101.462 46.884-51.922 77.922-104.46Q800-585.846 800-634q0-60-40-100t-100-40q-47 0-82.885 23.039-35.885 23.038-68.346 72.5-5.077 7.692-12.769 11.539-7.693 3.846-16 3.846t-16-3.846q-7.692-3.847-12.769-11.539ZM480-502.539Z" fill={props.fill}/>
    </svg>
  )
}

export default IconFavorite