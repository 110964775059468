import React from 'react';

const IconBarcode = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M73.849-213.847v-516.92h73.845v516.92H73.849Zm110.768 0v-516.92h73.846v516.92h-73.846Zm110.769 0v-516.92h36.923v516.92h-36.923Zm110.768 0v-516.92H480v516.92h-73.846Zm110.769 0v-516.92h110.768v516.92H516.923Zm147.691 0v-516.92h36.923v516.92h-36.923Zm110.769 0v-516.92h110.768v516.92H775.383Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconBarcode;
