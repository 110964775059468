import { useState } from 'react';
import useCheckOutHandler from '../../../redux/handler/useCheckOutHandler';
import useExcepcion from '../../../shared/hooks/useExcepcion';
import metodoDeEnvio from '../../../enums/metodoDeEnvio';
import Swal from '../../../shared/SweetAlert';
import useSucursalesHandler from '../../../redux/handler/useSucursalesHandler';
import useUsuarioHandler from '../../../redux/handler/useUsuarioHandler';

const useCheckOut = () => {

    const { sucursales } = useSucursalesHandler();
    const { usuario } = useUsuarioHandler();

    //State
    const [loader, setLoader] = useState();

    //Hooks
    const { 
        checkout,
        carrito,
        handleUpdateMetodoDePagoCarritoAsync,
        handleUpdateSucursalCarritoCheckOutAsync,
        handleCreateCarritoCheckOutAsync
    } = useCheckOutHandler();

    const { handleMessageException } = useExcepcion();

    //Funtions
    const handleUpdateMetodoDePago = async (metodoDePago) => {
        try {
            setLoader(true);
            await handleUpdateMetodoDePagoCarritoAsync(metodoDePago);
        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

    const handleCreateUpdateCarrito = async (sucursalDto) => {
        try {
            setLoader(true);

            const sucursal = sucursales.filter(x =>  (x.Tipo === 1 | x.Tipo === 2 | x.Tipo === 4)).find(x => x.CodigoSucursal.toString() === sucursalDto.CodigoSucursal);
        

            if(sucursal) {

                if(sucursal.CodigoSucursal === 1) {
                    Swal.fire({
                        title: '',
                        text: 'La tienda no cuenta con compra en linea !',
                        icon: 'info',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: 'Orange',
                    })

                    return;
                }


                if(usuario.IsMayorista) {
                    if(sucursal.CodigoSucursal !== 5) {
                        Swal.fire({
                            title: '',
                            text: 'Tienda no aplica compra en linea mayoristas',
                            icon: 'info',
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: 'Orange',
                        })

                        return;
                    }
                }

                Swal.fire({
                    title: '',
                    text: '¿Desea cambiar de sucursal?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'Orange',
                    cancelButtonText: 'Cancelar',
                    cancelButtonColor: 'Red'
                }).then(async (result) => {
                    if (result.isConfirmed) {

                        Swal.fire({
                            title: 'Cargando...',
                            text: '',
                            icon: 'info',
                            showConfirmButton: false,
                            didOpen: async () => {
                                Swal.showLoading();
                                if(checkout.IsCarritoActivo) {
                                    await handleUpdateSucursalCarritoCheckOutAsync(sucursal, metodoDeEnvio.TIENDA);
                                } else {
                                    await handleCreateCarritoCheckOutAsync(sucursal, metodoDeEnvio.TIENDA, null)
                                }
                                Swal.close();
                            },
                        });
                    }
                });
            }



        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

  return {
    loader,
    checkout,
    carrito,
    handleUpdateMetodoDePago,
    handleCreateUpdateCarrito
  }
};

export default useCheckOut;
