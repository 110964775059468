import "./css/Button.css"
const Button = (props)=>{
    return (
        <button name={props.name} 
                style={props.style} 
                className={ props.ButtonLoading ? (props.className.includes('btn-intelaf-whiteghost') ? `${props.className} btn-loading-claro` : `${props.className} btn-loading`) : (props.className)} 
                onClick={props.onClick}
                disabled={props.ButtonLoading ? true : false}>
            <span className="btn-text">{props.Texto}</span>
        </button>
        
    )  
}

export default Button