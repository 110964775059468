import React from 'react'
import { Link } from 'react-router-dom';
import packageJson from '../../../../../../package.json'

const Politicas = () => {
  return (
    <div style={{ backgroundColor: "white", padding: "15px" }} >
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "15px" }}>
            <div style={{ textAlign: "center" }}>
                <p>Síguenos en nuestras redes</p>
                <p>#TeConectaHaciaLaTecnologia</p>
            </div>
            <div style={{ display: "flex", gap: "22px", flexWrap: "wrap", justifyContent: "center" }}>
                <a href="https://www.facebook.com/intelaf" target="_blank" rel="noreferrer" className="hide-text">
                    <span className="redes">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M20 10.0609C20 4.50304 15.5242 0 10 0C4.47581 0 0 4.50304 0 10.0609C0 15.0824 3.65686 19.2446 8.4375 20V12.9692H5.89718V10.0609H8.4375V7.84422C8.4375 5.32292 9.92944 3.93022 12.2145 3.93022C13.3089 3.93022 14.4532 4.12657 14.4532 4.12657V6.60122H13.1919C11.95 6.60122 11.5625 7.37688 11.5625 8.17241V10.0609H14.3359L13.8923 12.9692H11.5625V20C16.3431 19.2446 20 15.0824 20 10.0609Z"
                                fill="#6C6C6C" />
                        </svg>
                    </span>
                    Facebook
                </a>
                <a href="https://www.instagram.com/intelafgt/" target="_blank" rel="noreferrer" className="hide-text">
                    <span className="redes">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.0022 4.87117C7.16453 4.87117 4.87563 7.16007 4.87563 9.99777C4.87563 12.8355 7.16453 15.1244 10.0022 15.1244C12.8399 15.1244 15.1288 12.8355 15.1288 9.99777C15.1288 7.16007 12.8399 4.87117 10.0022 4.87117ZM10.0022 13.3307C8.16843 13.3307 6.66927 11.836 6.66927 9.99777C6.66927 8.15951 8.16397 6.66481 10.0022 6.66481C11.8405 6.66481 13.3352 8.15951 13.3352 9.99777C13.3352 11.836 11.836 13.3307 10.0022 13.3307ZM16.5343 4.66146C16.5343 5.32627 15.9989 5.85722 15.3385 5.85722C14.6737 5.85722 14.1428 5.32181 14.1428 4.66146C14.1428 4.00112 14.6782 3.4657 15.3385 3.4657C15.9989 3.4657 16.5343 4.00112 16.5343 4.66146ZM19.9297 5.87507C19.8539 4.27329 19.488 2.85443 18.3146 1.68544C17.1456 0.516453 15.7267 0.150586 14.1249 0.0702733C12.4741 -0.0234244 7.52593 -0.0234244 5.87507 0.0702733C4.27775 0.146124 2.8589 0.511991 1.68544 1.68098C0.511991 2.84997 0.150586 4.26882 0.0702733 5.87061C-0.0234244 7.52147 -0.0234244 12.4696 0.0702733 14.1205C0.146124 15.7223 0.511991 17.1411 1.68544 18.3101C2.8589 19.4791 4.27328 19.845 5.87507 19.9253C7.52593 20.019 12.4741 20.019 14.1249 19.9253C15.7267 19.8494 17.1456 19.4836 18.3146 18.3101C19.4835 17.1411 19.8494 15.7223 19.9297 14.1205C20.0234 12.4696 20.0234 7.52594 19.9297 5.87507ZM17.797 15.8918C17.449 16.7663 16.7752 17.4401 15.8963 17.7925C14.58 18.3146 11.4568 18.1941 10.0022 18.1941C8.54769 18.1941 5.41997 18.3101 4.1082 17.7925C3.23369 17.4445 2.55996 16.7708 2.20747 15.8918C1.68544 14.5756 1.80591 11.4523 1.80591 9.99777C1.80591 8.54323 1.68991 5.41551 2.20747 4.10374C2.55549 3.22923 3.22922 2.55549 4.1082 2.20301C5.42443 1.68098 8.54769 1.80145 10.0022 1.80145C11.4568 1.80145 14.5845 1.68544 15.8963 2.20301C16.7708 2.55103 17.4445 3.22476 17.797 4.10374C18.319 5.41997 18.1985 8.54323 18.1985 9.99777C18.1985 11.4523 18.319 14.58 17.797 15.8918Z"
                                fill="#6C6C6C" />
                        </svg>
                    </span>
                    Instagram
                </a>
                <a href="https://twitter.com/intelaf" target="_blank" rel="noreferrer" className="hide-text">
                    <span className="redes">
                        <svg width="20" height="22" viewBox="0 0 20 17" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.7489 0.200195H22.3058L14.535 9.08172L23.6767 21.1675H16.5188L10.9125 13.8375L4.49759 21.1675H0.938523L9.25017 11.6677L0.480469 0.200195H7.82009L12.8877 6.90005L18.7489 0.200195ZM17.5005 19.0385H19.4715L6.74915 2.21736H4.63414L17.5005 19.0385Z"
                                fill="#6C6C6C" />
                        </svg>
                    </span>
                    Twitter
                </a>
                <a href="https://www.linkedin.com/company/intelaf/" target="_blank" rel="noreferrer" className="hide-text">
                    <span className="redes">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.5714 0H1.42411C0.638393 0 0 0.647322 0 1.44196V18.558C0 19.3527 0.638393 20 1.42411 20H18.5714C19.3571 20 20 19.3527 20 18.558V1.44196C20 0.647322 19.3571 0 18.5714 0ZM6.04464 17.1429H3.08036V7.59821H6.04911V17.1429H6.04464ZM4.5625 6.29464C3.61161 6.29464 2.84375 5.52232 2.84375 4.57589C2.84375 3.62946 3.61161 2.85714 4.5625 2.85714C5.50893 2.85714 6.28125 3.62946 6.28125 4.57589C6.28125 5.52679 5.51339 6.29464 4.5625 6.29464ZM17.1562 17.1429H14.192V12.5C14.192 11.3929 14.1696 9.96875 12.6518 9.96875C11.1071 9.96875 10.8705 11.1741 10.8705 12.4196V17.1429H7.90625V7.59821H10.75V8.90179H10.7902C11.1875 8.15179 12.1563 7.36161 13.5982 7.36161C16.5982 7.36161 17.1562 9.33929 17.1562 11.9107V17.1429Z"
                                fill="#6C6C6C" />
                        </svg>
                    </span>
                    Linkedin
                </a>
                <a href="https://www.tiktok.com/@intelaf" target="_blank" rel="noreferrer" className="hide-text">
                    <span className="redes">
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.8001 8.20169C16.08 8.2058 14.402 7.66934 13.0032 6.6681V13.6511C13.0027 14.9444 12.6074 16.2068 11.8702 17.2694C11.1329 18.332 10.0887 19.1442 8.87741 19.5974C7.66607 20.0505 6.34525 20.1231 5.09156 19.8053C3.83787 19.4876 2.71107 18.7946 1.86183 17.8192C1.01259 16.8437 0.481391 15.6323 0.339257 14.3468C0.197123 13.0613 0.450831 11.763 1.06646 10.6256C1.68208 9.48819 2.63028 8.56583 3.78427 7.98185C4.93825 7.39788 6.24302 7.18013 7.5241 7.35772V10.8699C6.93788 10.6855 6.30838 10.6911 5.7255 10.8858C5.14262 11.0805 4.63617 11.4544 4.27847 11.9541C3.92077 12.4539 3.73012 13.0538 3.73374 13.6684C3.73736 14.2829 3.93506 14.8806 4.29862 15.376C4.66218 15.8715 5.173 16.2394 5.75814 16.4272C6.34327 16.6151 6.97279 16.6132 7.5568 16.4219C8.14081 16.2306 8.64945 15.8597 9.01007 15.3621C9.3707 14.8645 9.56487 14.2657 9.56485 13.6511V0H13.0032C13.0008 0.290363 13.0252 0.580329 13.0759 0.866235C13.1954 1.50447 13.4438 2.11163 13.806 2.65057C14.1682 3.1895 14.6365 3.64889 15.1823 4.00062C15.9588 4.51407 16.8692 4.78774 17.8001 4.78754V8.20169Z"
                                fill="#6C6C6C" />
                        </svg>
                    </span>
                    TikTok
                </a>
                <a href="https://api.whatsapp.com/send?phone=50258330003" target="_blank" rel="noreferrer" className="hide-text">
                    <span className="redes">
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.1041 2.90625C15.2335 1.03125 12.7425 0 10.0951 0C4.63086 0 0.184431 4.44643 0.184431 9.91071C0.184431 11.6562 0.639788 13.3616 1.50586 14.8661L0.0996094 20L5.35407 18.6205C6.8005 19.4107 8.42997 19.8259 10.0907 19.8259H10.0951C15.555 19.8259 20.0996 15.3795 20.0996 9.91518C20.0996 7.26786 18.9746 4.78125 17.1041 2.90625ZM10.0951 18.1563C8.613 18.1563 7.16211 17.7589 5.89872 17.0089L5.59961 16.8304L2.48354 17.6473L3.3139 14.6071L3.11747 14.2946C2.29157 12.9821 1.85854 11.4688 1.85854 9.91071C1.85854 5.37054 5.55497 1.67411 10.0996 1.67411C12.3005 1.67411 14.3675 2.53125 15.921 4.08929C17.4746 5.64732 18.43 7.71429 18.4255 9.91518C18.4255 14.4598 14.6353 18.1563 10.0951 18.1563ZM14.613 11.9866C14.3675 11.8616 13.1487 11.2634 12.921 11.183C12.6934 11.0982 12.5282 11.058 12.363 11.308C12.1978 11.558 11.7246 12.1116 11.5773 12.2813C11.4344 12.4464 11.2871 12.4688 11.0416 12.3438C9.58622 11.6161 8.63086 11.0446 7.67104 9.39732C7.41657 8.95982 7.9255 8.99107 8.39872 8.04464C8.47907 7.87946 8.4389 7.73661 8.3764 7.61161C8.3139 7.48661 7.81836 6.26786 7.613 5.77232C7.41211 5.29018 7.20675 5.35714 7.05497 5.34821C6.91211 5.33929 6.74693 5.33929 6.58175 5.33929C6.41657 5.33929 6.14872 5.40179 5.92104 5.64732C5.69336 5.89732 5.05497 6.49554 5.05497 7.71429C5.05497 8.93304 5.94336 10.1116 6.0639 10.2768C6.1889 10.442 7.80943 12.942 10.296 14.0179C11.8675 14.6964 12.4835 14.7545 13.2693 14.6384C13.7469 14.567 14.7335 14.0402 14.9389 13.4598C15.1443 12.8795 15.1443 12.3839 15.0818 12.2813C15.0237 12.1696 14.8585 12.1071 14.613 11.9866Z"
                                fill="#6C6C6C" />
                        </svg>
                    </span>
                    WhatsApp
                </a>
                <a href="https://www.twitch.tv/intelaf" target="_blank" rel="noreferrer" className="hide-text">
                    <span className="redes">
                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.4301 4.0418H12.9211V8.32695H14.4301V4.0418ZM10.2828 4.02344H8.77383V8.31055H10.2828V4.02344ZM3.86992 0L0.0996094 3.57109V16.4289H4.62422V20L8.39492 16.4289H11.4125L18.2004 10V0H3.86992ZM16.6918 9.28711L13.6754 12.1434H10.6582L8.01758 14.6434V12.1434H4.62422V1.42891H16.6918V9.28711Z"
                                fill="#6C6C6C" />
                        </svg>
                    </span>
                    Twitch
                </a>
                <a href="https://www.youtube.com/c/INTELAF1" target="_blank" rel="noreferrer" className="hide-text">
                    <span className="redes">
                        <svg width="20" height="19" viewBox="0 0 20 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.99969 0C9.99969 0 3.73993 3.21843e-05 2.17487 0.41189C1.33468 0.642531 0.642531 1.33461 0.41189 2.19128C3.21843e-05 3.75634 0 7.00159 0 7.00159C0 7.00159 3.21843e-05 10.2633 0.41189 11.8119C0.642531 12.6686 1.3182 13.3442 2.17487 13.5749C3.7564 14.0032 9.99969 14.0032 9.99969 14.0032C9.99969 14.0032 16.26 14.0031 17.8251 13.5913C18.6817 13.3606 19.3574 12.685 19.588 11.8283C19.9999 10.2632 19.9999 7.018 19.9999 7.018C19.9999 7.018 20.0164 3.75634 19.588 2.19128C19.3574 1.33461 18.6817 0.658941 17.8251 0.4283C16.26 -3.21843e-05 9.99969 0 9.99969 0ZM8.00642 4.00348L13.2122 7.00159L8.00642 9.99969V4.00348Z"
                                fill="#6C6C6C" />
                        </svg>
                    </span>
                    YouTube
                </a>
            </div>
            <p style={{ textAlign: "center", fontSize: "10px" }}>
                Todas las imágenes mostradas son de carácter ilustrativo. Los nombres comerciales de productos son propiedad de sus respectivas marcas. <br></br>
                La venta de los productos online está sujeta a verificación de stock. Los precios y cuotas bancarias en <span> www.intelaf.com</span> así como la información del sitio, están sujetos a cambios sin previo aviso.
            </p>
            <img src="https://www.intelaf.com/img/footer/forma-pago.png" width={225} height={37} style={{ height: '120%' }} alt="logo" loading="lazy"></img>
            <div style={{ display: "flex", gap: "25px" }}>
                <Link to="/terminos_y_condiciones">Términos y condiciones</Link>
                <Link to="/politicas_privacidad">Política de privacidad</Link>
                <Link to="/politicas_cookies">Política de cookies</Link>
            </div>
            <p style={{ textAlign: "center", fontSize: "10px" }}>Copyright © 1989-{new Date().getFullYear()} Intelaf S.A. Todos los derechos reservados.</p>
            <p style={{ textAlign: "center", fontSize: "10px" }}>Versión {packageJson.version}</p>
        </div>
    </div>
  )
}

export default Politicas;