import React from 'react'

const IconSell = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M556.153-117.847q-17.264 17.23-42.785 17.23-25.521 0-42.752-17.23l-352-352q-8.692-8.692-13.653-20.01-4.962-11.317-4.962-24.143v-286q0-24.538 17.731-42.268 17.73-17.731 42.268-17.731h286q12.384 0 23.725 4.834 11.342 4.833 19.659 13.012l352 352.616q17.615 17.615 17.807 43.037.192 25.422-17.038 42.653l-286 286ZM513.212-160l286.173-286-353.213-354H160v286l353.212 354ZM259.955-650.001q20.814 0 35.429-14.57 14.615-14.57 14.615-35.384t-14.57-35.429q-14.57-14.615-35.384-14.615t-35.429 14.57q-14.615 14.57-14.615 35.384t14.57 35.429q14.57 14.615 35.384 14.615ZM160-800Z"
        fill={props.fill}
      />
    </svg>
  )
}

export default IconSell