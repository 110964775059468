import React, { useState } from 'react';
import { Divider, List, ListItem, Popper, styled } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const PopperSearch = ({ data, onClose, ...props }) => {
    const navigate = useNavigate();

    const handleOnNavigate = (URL) => {
      navigate(URL);
      onClose();
    }

    return (
      <MuiPopper 
        { ...props } 
        placement="bottom"
        onBlur={onClose}
      >
        <Content>
          <Title>Categorias</Title>
          <List>
            {
              data.Categorias.map((categoria, index) => (
                <Item
                  key={index}
                  text={categoria.Descripcion}
                  onClick={() => handleOnNavigate(`/precios_stock_resultado/${categoria.Area.toLowerCase()}`)}
                />
              ))
            }
          </List>
          <Divider style={{ margin: '10px 10px 10px 10px' }}/>
          <Title>Productos</Title>
          <List>
            {
              data.Productos.map((producto, index) => (
                <Item
                  key={index}
                  text={producto.Descripcion.toCapitalize()}
                  onClick={() => handleOnNavigate(`/precios_stock_detallado/${producto.Codigo.toLowerCase()}`)}
                />
              ))
            }
          </List>
          <Divider style={{ margin: '10px 10px 10px 10px' }}/>
          <Title>Marcas</Title>
          <List style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(8rem, 1fr))',
          }}>
            {
              data.Marcas.map((marca, index) => (
                <Img
                  key={index}
                  src={marca.Imagen}
                  onClick={() => handleOnNavigate(`/marca/${marca.Marca.toLowerCase()}`)}
                />
              ))
            }
          </List>
        </Content>
      </MuiPopper>
    );
};

const Item = React.forwardRef(({ text, icon, ...props }, ref) => {
  return (
    <MuiListItem ref={ref}>
      <MuiItem {...props}>
        {text}
      </MuiItem>
    </MuiListItem>
  );
});

const Img = React.forwardRef((props, ref) => {

  const [error, setError] = useState(false);

  return (
    error ? <></> :
    <ListItem ref={ref}>
      <img 
        style={{
          maxheight: '35px',
          maxwidth: '100%',
          display: 'inline-block',
          overflowX: 'hidden',
          cursor: 'pointer',
          objectFit: 'contain'
        }}
        onError={() => {
          setError(true);
        }}

      {...props} height={40} width={80} alt='marcas'/>
    </ListItem>
  );
});

const MuiPopper = styled(Popper)({
  backgroundColor: 'white', 
  boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
  zIndex: '1300',
  maxWidth: '550px', 
  borderRadius: '15px',
  height: 'auto',
  overflow: 'hidden',
  marginTop: '0px',
  padding: '0px',
  '@media (max-width: 991px)': {
    width: 'calc(100% - 10px)',
    height: 'calc(100% - 125px)',
  },
});

const Content = styled('div')({
  overflowY: 'auto',
  whiteSpace: 'nowrap',
  padding: '15px 20px',
  height: '100%',
  '&::-webkit-scrollbar': {
    width: '7px'
  },
 '&::-webkit-scrollbar-track': {
    borderRadius: '25px',
    backgroundColor: '#f5f5f5',
    paddingRight: '25px'
  },
 '&::-webkit-scrollbar-thumb': {
    background: '#dddddd',
    borderRadius: '2px',
    animation: 'linear 3s'
  },
 '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'darkgray'
  },
  scrollbarWidth: 'thin',
});

const Title = styled('p')({
  color: '#c4c4c4',
  fontSize: '12px',
  margin: '0px 10px 0px 15px',
  fontFamily: 'Inter, sans-serif',
});

const MuiListItem = styled(ListItem)({
  padding: '0px 0px',
});

const MuiItem = styled('div')({
  padding: '2px 16px',
  textDecoration: 'none',
  color: '#1c1c1c',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '20px',
  textTransform: 'capitalize',
  fontFamily: 'Inter, sans-serif',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'none',
    color: '#1c1c1c',
    backgroundColor: 'rgb(242, 242, 242)'
  },
  '&:focus': {
    textDecoration: 'none',
    color: '#1c1c1c',
  },
});

export default PopperSearch;


