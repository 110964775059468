import { postAsync, putAsync } from "../httpServiceIntelafApi";
import { getAuthorization } from '../../../shared/TokenHeaders';

const URI = '/producto/favorito';

export const postAddProductoFavoritoAsync = (codigo) => {
    return postAsync(URI, getAuthorization(), {
        codigoInventario: codigo,
        estado: 1
    }).then((response) => {
        return response;
    })
}

export const removeProductoFavoritoAsync = (codigo) => {
    return putAsync(URI, getAuthorization(), {
        codigoInventario: codigo,
        estado: 0
    }).then((response) => {
        return response;
    });
}