import React from 'react';

const IconMail = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path d="M172.309-180.001q-30.308 0-51.308-21t-21-51.308v-455.382q0-30.308 21-51.308t51.308-21h615.382q30.308 0 51.308 21t21 51.308v455.382q0 30.308-21 51.308t-51.308 21H172.309ZM800-662.309 499.461-469.924q-4.615 2.615-9.538 4.115t-9.923 1.5q-5 0-9.923-1.5t-9.538-4.115L160-662.309v410q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462h615.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-410ZM480-520l313.846-200H166.154L480-520ZM160-662.309v9.23-45.729 1.192V-720v22.384-1.269 45.806-9.23V-240v-422.309Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconMail;
