import { createAdapterCarrito  } from "../../../adapter/carritoAdapter";
import { getAsync, postAsync, putAsync, deleteAsync } from "../httpServiceIntelafApi";
import { getAuthorization, getToken } from '../../../shared/TokenHeaders';
import metodoDePago  from '../../../enums/metodoDePago';

const URI = '/carrito';

const getTipoPago = (metodo) => {
    return metodo === metodoDePago.MONETARIO ? 'efectivo' : metodo;
}

export const postCreateCarritoAsync = (sucursalId) => {
    return postAsync(`${URI}?idSucursal=${sucursalId}`, getToken(), null).then((carrito) => {
        return createAdapterCarrito(carrito);
    });
}

export const getShowCarritoAsync = (carritoId, metodoPago) => {
    return getAsync(`${URI}/${carritoId}?tipoPago=${getTipoPago(metodoPago)}`, getToken()).then((carrito) => {
        return createAdapterCarrito(carrito);
    });
}

export const putUpdateSucursalCarritoAsync = (carritoId, newSucursalId) => {
    return putAsync(`${URI}/${carritoId}?idSucursal=${newSucursalId}&accion=CambiarSucursal`, {}, null)
    .then((carrito) => {
        return createAdapterCarrito(carrito);
    });
}

export const putLimpiarCarritoAsync = (carritoId, sucursalId) => {
    return putAsync(`${URI}/${carritoId}?idSucursal=${sucursalId}&accion=Limpiar`, {}, null)
    .then((carrito) => {
        return createAdapterCarrito(carrito);
    });
}

export const postAddCuponCarritoAsync = (carritoId, codigoDescuento, metodoPago) => {
    return postAsync(`${URI}/cupon/${carritoId}?codigoDescuento=${codigoDescuento}&tipoPago=${getTipoPago(metodoPago)}`, getAuthorization(), null).then((carrito) => {
        return createAdapterCarrito(carrito);
    });
}

export const deleteCuponCarritoAsync = (carritoId, metodoPago) => {
    return deleteAsync(`${URI}/cupon/${carritoId}?tipoPago=${getTipoPago(metodoPago)}`, getAuthorization(), null).then((carrito) => {
        return createAdapterCarrito(carrito);
    });
}

export const postAddProductoCarritoAsync = (carritoId, codigoInventario, cantidad, metodoPago) => {
    return postAsync(`${URI}/producto/${carritoId}?codigoInventario=${codigoInventario}&cantidad=${cantidad}&tipoPago=${getTipoPago(metodoPago)}`, getToken(), null).then((carrito) => {
        return createAdapterCarrito(carrito);
    });
}

export const putUpdateProductoCarritoAsync = (carritoId, codigoInventario, cantidad, metodoPago) => {
    return putAsync(`${URI}/producto/${carritoId}?codigoInventario=${codigoInventario}&cantidad=${cantidad}&tipoPago=${getTipoPago(metodoPago)}`, getToken(), null).then((carrito) => {
        return createAdapterCarrito(carrito);
    });
}

export const deleteProductoCarritoAsync = (carritoId, codigoInventario, metodoPago) => {
    return deleteAsync(`${URI}/producto/${carritoId}?codigoInventario=${codigoInventario}&tipoPago=${getTipoPago(metodoPago)}`, getToken(), null).then((carrito) => {
        return createAdapterCarrito(carrito);
    });    
}

export const postUpdateCarritoAsync = (carritoId) => {
    return putAsync(`${URI}/actualizar/${carritoId}`, getAuthorization(), null).then((carrito) => {
        return createAdapterCarrito(carrito);
    });
}

export const postAddCodigoDescuentoAsync = (idCarrito, cupon, metodoPago) => {
    return postAsync(URI + `/cupon/${idCarrito}?codigoDescuento=${cupon}&tipoPago=${getTipoPago(metodoPago)}`, getAuthorization(), null).then((carrito) => {
        return createAdapterCarrito(carrito);   
    });
}

export const deleteCodigoDescuentoAsync = (idCarrito, metodoPago) => {
    return deleteAsync(URI + `/cupon/${idCarrito}?tipoPago=${getTipoPago(metodoPago)}`, getAuthorization(), null).then((carrito) => {
        return createAdapterCarrito(carrito);
    });
}
