import React from 'react'

const IconWorkspace = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="m480-488-65.307 50.461q-5.616 4.231-11.039.308t-3.423-10.154l24.846-81.692-66.153-50.615q-5-4.231-2.808-10.346 2.192-6.116 8.423-6.116h81.769l24.846-80.461q2-6.231 8.846-6.231t8.846 6.231l24.846 80.461h81.154q6.23 0 8.73 6.116 2.5 6.115-2.5 10.346l-66.769 50.615 24.847 81.692q2 6.231-3.423 10.154-5.424 3.923-11.039-.308L480-488Zm0 347.229L307.232-86.079q-17.692 6.23-32.461-4.808-14.77-11.038-14.77-29.115v-237.076q-38-39.692-59-91.768-21-52.077-21-111.154 0-125.538 87.231-212.768 87.23-87.231 212.768-87.231t212.768 87.231q87.231 87.23 87.231 212.768 0 59.077-21 111.154-21 52.076-59 91.768v237.076q0 18.077-14.77 29.115-14.769 11.039-32.461 4.808L480-140.771ZM480-320q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-158.615l160-42.924 160 42.924v-148.618q-34.231 22.308-74.923 34.77-40.692 12.462-85.077 12.462t-85.077-12.462q-40.692-12.462-74.923-34.77v148.618Zm160-74.309Z"
        fill={props.fill}
      />
    </svg>
  )
}

export default IconWorkspace
