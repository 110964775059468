import { useState } from 'react';
import {  getProductoCuotasAsync } from '../../../../services/intelafApiClient/repository/productoRepository'
import useExcepcion from '../../../../shared/hooks/useExcepcion';


const useCuotasBancoService = (codigo) => {
    // state
    const [loader, setLoader] = useState(false);

    // Hooks
    const { handleMessageException } = useExcepcion();

    const getCuotasBancoAsync = async()=>{
        setLoader(true)
        return getProductoCuotasAsync(codigo).then(d => {
           return d.sort((a, b) => (a.Orden - b.Orden))
        }).catch(err => {
            handleMessageException(err);
        }).finally(() => {
            setLoader(false);
        })
    }
    
    return {loader, getCuotasBancoAsync}
}

export default useCuotasBancoService;