import React from 'react';

const IconNewspaper = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path d="M162.308-130.001q-29.923 0-51.115-21.192-21.192-21.192-21.192-51.115v-591.767q0-6.231 5.423-8.539 5.423-2.307 10.038 2.308l28.692 28.692q5.615 5.615 12.846 5.615t12.846-5.615l40.308-41.308q5.615-5.615 12.846-5.615t12.846 5.615l41.308 41.308q5.615 5.615 12.846 5.615t12.846-5.615l41.308-41.308q5.615-5.615 12.846-5.615t12.846 5.615l40.308 41.308q5.615 5.615 12.846 5.615t12.846-5.615l41.308-41.308q5.615-5.615 12.846-5.615t12.846 5.615l41.308 41.308q5.615 5.615 12.846 5.615t12.846-5.615l40.308-41.308q5.615-5.615 12.846-5.615t12.846 5.615l41.308 41.308q5.615 5.615 12.846 5.615t12.846-5.615l41.308-41.308q5.615-5.615 12.846-5.615t12.846 5.615l40.308 41.308q5.615 5.615 12.846 5.615t12.846-5.615l28.692-28.692q4.615-4.615 10.038-2.308 5.423 2.308 5.423 8.539v591.767q0 29.923-21.192 51.115-21.192 21.192-51.115 21.192H162.308Zm0-59.998h287.693v-260.002H149.999v247.693q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462Zm347.691 0h287.693q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-87.693H509.999v100.002Zm0-160h300.002v-100.002H509.999v100.002Zm-360-160h660.002v-143.848H149.999v143.848Z" fill={props.fill}
      />
    </svg>
  );
};

export default IconNewspaper;
