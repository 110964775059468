import React,  { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText, Menu, MenuItem, styled } from '@material-ui/core';
import { Link as DomLink } from 'react-router-dom';
import IconExpandMore from '../../../icons/IconExpandMore';
import IconBuildCircle from '../../../icons/IconBuildCircle';
import IconNewspaper from '../../../icons/IconNewspaper';

const MenuSoporte = () => {
    const [anchor, setAnchor] = useState();

    const handleClose = () => {
        setAnchor(null)
    }

    const handleOpen = (e) => {
        if(Boolean(anchor)) {
            setAnchor(null)
        }

        setAnchor(e.currentTarget)
    }

    const customizeMenu = {
        id: "customized-menu",
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        anchorEl: anchor,
        keepMounted: true,
        open: Boolean(anchor),
        onClose: handleClose
    };

  return (
    <MuiItem>
        <Box onClick={handleOpen}>
            <Text>Soporte</Text>
            <IconExpandMore width={22} height={22} fill={'#F0761D'}/>
        </Box>
        <MuiMenu { ...customizeMenu }>
            <MenuItemLink
                icon={<IconBuildCircle width={26} height={26} fill={"#808080"}/>}
                text={'Centros de servicios'}
                to={'/centros_servicio'}   
            />
            <MenuItemLink
                icon={<IconNewspaper width={26} height={26} fill={"#808080"}/>}
                text={'Blog'}
                to={'https://intelaf.wordpress.com/'}
                target="_blank"
            />
        </MuiMenu>
    </MuiItem>
  )
}
  
const MenuItemLink = React.forwardRef(({ text, icon, ...props }, ref) => {
    return (
      <Item ref={ref}>
        <Link {...props}>
          <ListItemIcon style={{ minWidth: '40px' }}>{icon}</ListItemIcon>
          <TextItem primary={text} />
        </Link>
      </Item>
    );
});


const Item = styled(MenuItem)({
    padding: '0px',
    margin: '0px',
    display: 'block'
});

const MuiMenu = styled(Menu)({
    '& .MuiPaper-rounded': {
        borderRadius: '13px',
    },
});

const TextItem = styled(ListItemText)({
    '& .MuiTypography-body1': {
        color: 'grey',
        fontSize: '13px',
        fontWeight: '400',
        letterSpacing: '-.25px',
        textDecoration: 'none',
        fontFamily: 'Inter, sans-serif',
    },
});

const Box = styled('div')({
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    padding: '0px',
    justifyContent: 'flex-end',
    cursor: 'pointer'
})

const MuiItem = styled(ListItem)({
    padding: '0px',
    margin: '0px',
    display: 'block'
});

const Link = styled(DomLink)({
    color: 'grey',
    fontSize: '13px',
    fontWeight: '400',
    letterSpacing: '-.25px',
    textDecoration: 'none',
    fontFamily: 'Inter',
    '&:hover': {
        textDecoration: 'none',
        color: '#F0761D',
    },
    '&:focus': {
        textDecoration: 'none',
        color: '#F0761D',
        border: 'none',
        outline: 'none',
        outlineOffset: 'none',
        backgroundColor: 'rgba(240,118,29,.1)',
    },
    display: 'flex',
    padding: '6px 20px'
});

const Text = styled('div')({
    color: 'grey',
    fontSize: '13px',
    fontWeight: '400',
    letterSpacing: '-.25px',
    textDecoration: 'none',
    fontFamily: 'Inter, sans-serif',
    '&:hover': {
        textDecoration: 'none',
        color: '#F0761D'
    },
    '&:focus': {
        textDecoration: 'none',
        color: '#F0761D',
    }
});

export default MenuSoporte;
