import React from 'react';
import { Link } from 'react-router-dom';
import LogoIntelaf from '../assets/LogoIntelaf';

const LinkLogoSvg = ({ to }) => {
  return (
    <Link to={to} style={{ justifySelf: 'start' }}>
      <LogoIntelaf/>
    </Link>
  )
}

export default LinkLogoSvg;
