import React from 'react';

const IconBlock = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M480.067-100.001q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-160q56.293 0 108.416-19.039 52.123-19.038 95.277-55.115L234.154-683.693q-35.692 43.154-54.923 95.277T160-480q0 134 93 227t227 93Zm245.846-116.307q36.077-43.154 55.115-95.277Q800-423.707 800-480q0-134-93-227t-227-93q-56.428 0-108.676 18.846-52.248 18.847-95.017 55.308l449.539 449.539Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconBlock;
