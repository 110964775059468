import { createSlice } from "@reduxjs/toolkit";
import CheckOutDto from "../../models/checkOutDto";

const getSessionFromCheckOut = () => {
    const checkout  = sessionStorage.getItem('app-checkout');
    return checkout ? JSON.parse(checkout) : new CheckOutDto({}).toJSON();
}
const setSessionFromCheckOut = (checkout) => {
    sessionStorage.setItem('app-checkout', JSON.stringify(checkout));
}

const handlerResetCheckOut = () => {
    let checkout = new CheckOutDto({}).toJSON();
    setSessionFromCheckOut(checkout);
    return checkout;
  }
  
  const handlerUpdateCheckOut = (state, action) => {
    let checkout =  new CheckOutDto({ ...state, ...action.payload }).toJSON();
    setSessionFromCheckOut(checkout);
    return checkout;
  }
  
  const handlerCreateCheckOut = (state, action) => {
    let checkout = new CheckOutDto(action.payload).toJSON();
    setSessionFromCheckOut(checkout);
    return checkout;
  }

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: getSessionFromCheckOut(),
    reducers: {
        resetCheckout: handlerResetCheckOut,
        updateCheckout: handlerUpdateCheckOut,
        createCheckout: handlerCreateCheckOut,
    }
});

export const checkoutReducer = checkoutSlice.reducer;
export const { resetCheckout, updateCheckout, createCheckout } = checkoutSlice.actions;