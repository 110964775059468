import React from 'react';

const IconAddShoppingCart = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M450.001-730.001H360q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h90.001V-880q0-12.75 8.628-21.375 8.629-8.624 21.384-8.624 12.756 0 21.371 8.624 8.615 8.625 8.615 21.375v90.001H600q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615h-90.001V-640q0 12.75-8.628 21.375-8.629 8.624-21.384 8.624-12.756 0-21.371-8.624-8.615-8.625-8.615-21.375v-90.001ZM286.154-97.694q-29.153 0-49.576-20.422-20.423-20.423-20.423-49.577 0-29.153 20.423-49.576 20.423-20.423 49.576-20.423 29.154 0 49.577 20.423t20.423 49.576q0 29.154-20.423 49.577-20.423 20.422-49.576 20.422Zm387.692 0q-29.154 0-49.577-20.422-20.423-20.423-20.423-49.577 0-29.153 20.423-49.576 20.423-20.423 49.577-20.423 29.153 0 49.576 20.423 20.423 20.423 20.423 49.576 0 29.154-20.423 49.577-20.423 20.422-49.576 20.422ZM136.155-810H90.001q-12.75 0-21.375-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.625-8.615 21.375-8.615h61.714q10.285 0 19.208 5.398 8.923 5.398 13.385 15.294l157.077 331.615h272.691q3.462 0 6.155-1.731 2.692-1.731 4.615-4.808L764.461-775q3.846-7.307 10.583-11.153t14.97-3.846q17.216 0 25.831 14.692 8.615 14.692.307 29.691L677.384-494.309q-9.847 17.308-26.055 26.962-16.208 9.653-35.483 9.653H324l-46.308 84.616q-3.077 4.616-.192 10.001t8.654 5.385h427.692q12.749 0 21.374 8.629 8.625 8.628 8.625 21.384 0 12.755-8.625 21.37t-21.374 8.615H286.154q-39.999 0-60.422-34.192-20.423-34.192-1.116-69.191l57.078-102.616-145.539-306.308Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconAddShoppingCart;
