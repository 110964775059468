import React from 'react';

const IconHistory = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M479.231-140.001q-120.615 0-212.615-73.616-92-73.615-117.922-188.384-3.231-11.923 3.884-21.922 7.115-10 20.152-11.616 12.27-1.615 22.001 4.846 9.73 6.462 13.576 19Q231.154-319 306.731-259.5q75.576 59.5 172.5 59.5 117 0 198.5-81.5t81.5-198.5q0-117-81.5-198.5t-198.5-81.5q-65.538 0-122.846 29.115-57.307 29.115-98.692 80.115h74.616q12.749 0 21.374 8.629 8.625 8.628 8.625 21.384 0 12.755-8.625 21.37-8.625 8.616-21.374 8.616H195.387q-15.365 0-25.759-10.395-10.394-10.394-10.394-25.759v-136.921q0-12.75 8.628-21.375 8.629-8.625 21.384-8.625 12.756 0 21.371 8.625 8.615 8.625 8.615 21.375v64.77q48.692-57.461 116.615-89.192 67.923-31.731 143.384-31.731 70.798 0 132.629 26.77 61.831 26.769 107.831 72.768 46 46 72.769 107.824 26.769 61.824 26.769 132.615 0 70.791-26.769 132.637-26.769 61.846-72.769 107.846-46 45.999-107.831 72.768-61.831 26.77-132.629 26.77Zm31.153-352.153 110.001 110.001q8.307 8.308 8.499 20.884.193 12.577-8.499 21.269-8.692 8.692-21.077 8.692-12.384 0-21.076-8.692L461.231-457q-5.615-5.616-8.23-12.238-2.616-6.622-2.616-13.685v-167.078q0-12.749 8.629-21.374Q467.643-680 480.398-680q12.756 0 21.371 8.625 8.615 8.625 8.615 21.374v157.847Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconHistory;
