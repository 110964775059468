import React from 'react';

const IconDone = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="m382-339.384 345.539-345.538q8.923-8.923 21.192-9.116 12.269-.192 21.576 9.116 9.308 9.307 9.308 21.384 0 12.076-9.308 21.384l-363 362.999Q396.461-268.309 382-268.309q-14.461 0-25.307-10.846l-167-166.999q-8.923-8.923-8.808-21.192.116-12.269 9.423-21.576 9.308-9.308 21.384-9.308 12.077 0 21.384 9.308L382-339.384Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconDone;
