import { configureStore } from "@reduxjs/toolkit";
import { sucursalesReducer } from "./state/sucursalesSlice";
import { usuarioReducer } from "./state/usuarioSlice";
import { carritoReducer } from "./state/carritoSlice";
import { checkoutReducer } from "./state/checkOutSlice";
import { bannerReducer } from "./state/bannerSlice";
import { menuReducer } from "./state/menuSlice";

export const store = configureStore({
    reducer: {
        sucursales: sucursalesReducer,
        usuario: usuarioReducer,
        carrito: carritoReducer,
        checkout: checkoutReducer,
        banners: bannerReducer,
        menu: menuReducer
    },
})