// eslint-disable-next-line 
String.prototype.toCapitalize = function() {
    return this.toLowerCase().replace(/\b\w/g, function(a) { return a.toUpperCase(); });
};

// eslint-disable-next-line
Number.prototype.toCurrency = function () {
    const formateador = new Intl.NumberFormat("ES-GT", { 
      style: "currency", 
      currency: "GTQ" 
    });
      
    return formateador.format(this).replace(' ','').replace('GT','').replace('.00','');
};
  
// eslint-disable-next-line
String.prototype.toCurrency = function () {
  const formateador = new Intl.NumberFormat("ES-GT", { 
    style: "currency", 
    currency: "GTQ" 
  });

  return formateador.format(this).replace(' ','').replace('GT','').replace('.00','');;
};