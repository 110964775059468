import { postAsync } from "../httpServiceIntelafApi";
import { getAuthorization } from '../../../shared/TokenHeaders';

const URI = '/PayOnLine';

export const postErrorLog = (mensaje) => {
    return postAsync(`${URI}/logging?mensaje=${mensaje}`, getAuthorization(), null).then((response) => {
        return response;
    });
}

export const postLogsPage = (logs) => {
    return postAsync('/log', getAuthorization(), logs).then(response => {
        return response;
    })
}
