import { Button, styled } from '@material-ui/core';
import React from 'react';
import IconWidgets from '../../../icons/IconWidgets';
import { useState } from 'react';

const ButtonCategory = ({ text, ...props}) => {
  const [color, setColor] = useState('#808080');

  return (
    <MuiButton
        variant='contained'
        onMouseEnter={() => setColor('#f0761d')}
        onMouseLeave={() => setColor('#808080')}
        startIcon={<IconWidgets width={24} height={24} fill={color}/>}
        {...props}
    >
        { text }
    </MuiButton>
  )
}

const MuiButton = styled(Button)({
    color: '#808080',
    fontSize: '12px',
    fontWeight: '500',
    boxShadow: 'none',
    backgroundColor: 'rgb(247, 247, 247)',
    padding: '3px 16px',
    height: '42px',
    borderRadius: '12px',
    fontFamily: 'Inter',
    textTransform: 'none',
    '&.MuiButton-contained:hover': {
      backgroundColor: 'rgba(240,118,29,.1)',
      color: '#f0761d',
      boxShadow: 'none'
    },
    justifySelf: 'center'
});


export default ButtonCategory;
