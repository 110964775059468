import React from 'react'

const IconWork = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path d="M172.309-140.001q-30.308 0-51.308-21t-21-51.308v-415.382q0-30.308 21-51.308t51.308-21h167.692v-67.691q0-30.307 21-51.307 21-21 51.308-21h135.382q30.308 0 51.308 21t21 51.307v67.691h167.692q30.308 0 51.308 21t21 51.308v415.382q0 30.308-21 51.308t-51.308 21H172.309Zm0-59.999h615.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-415.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H172.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v415.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM400-699.999h160v-67.691q0-4.616-3.846-8.462-3.847-3.847-8.463-3.847H412.309q-4.616 0-8.463 3.847Q400-772.306 400-767.69v67.691ZM160-200V-640-200Z" 
      fill={props.fill}
      />
    </svg>
  )
}

export default IconWork
