import { createSlice } from "@reduxjs/toolkit";
import CarritoDto from "../../models/carritoDto";

const getSessionFromCarrito = () => {
    const carrito  = sessionStorage.getItem('app-carrito');
    return carrito ? JSON.parse(carrito) : new CarritoDto({}).toJSON();
}

const setSessionFromCarrito = (carrito) => {
    sessionStorage.setItem('app-carrito', JSON.stringify(carrito));
}

const handlerResetCarrito = () => {
    let usuario = new CarritoDto({}).toJSON();
    setSessionFromCarrito(usuario);
    return usuario;
  }
  
  const handlerUpdateCarrito = (state, action) => {
    let usuario =  new CarritoDto({ ...state, ...action.payload }).toJSON();
    setSessionFromCarrito(usuario);
    return usuario;
  }
  
  const handlerCreateCarrito = (state, action) => {
    let usuario = new CarritoDto(action.payload).toJSON();
    setSessionFromCarrito(usuario);
    return usuario;
  }

const carritoSlice = createSlice({
    name: 'carrito',
    initialState: getSessionFromCarrito(),
    reducers: {
      resetCarrito: handlerResetCarrito,
      updateCarrito: handlerUpdateCarrito,
      createCarrito: handlerCreateCarrito
    }
});


export const carritoReducer = carritoSlice.reducer;
export const { resetCarrito, updateCarrito, createCarrito } = carritoSlice.actions;




