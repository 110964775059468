import { Badge, IconButton, styled } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useMetodoDeEntrega } from "../../../../contexts/MetodoDeEntrega";
import IconShoppingCart from '../../../icons/IconShoppingCart';
import React from 'react';

const CartBadge = ({ quantity = 0, checkout }) => {
  const { openDialogEntrega } = useMetodoDeEntrega ();
  const navigate              = useNavigate();
  
  const handleMoverCart = () => {
    if(checkout.IsCarritoActivo) {
        navigate('/carrito')
    } else {
      openDialogEntrega({}).then((response) => {
          if(response) {
              navigate('/carrito')
          }
      });
    }         
  }

  return (
    <div onClick={handleMoverCart}>
      <MuiButton color="inherit">
        <MuiBadge badgeContent={quantity} overlap="rectangular">
          <IconShoppingCart width={35} height={35} fill={'#F0761D'}/>
        </MuiBadge>
      </MuiButton>
    </div>
  )
}

const MuiButton = styled(IconButton)({
  margin: '0px', 
  padding: '0px 0px 0px 0px',
  '&:hover': {
    backgroundColor: 'white'
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
})

const MuiBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        color: '#fff',
        backgroundColor: '#000',
        fontSize: '12px',
    },
    '& .MuiBadge-anchorOriginTopRightRectangular': {
      top: '3px',
      right: '8px'
    }
})

export default CartBadge;
