
import { useEffect, useState } from 'react';
import { postAddProductoFavoritoAsync, removeProductoFavoritoAsync } from '../repository/favoritoRepository';
import { useNavigate } from 'react-router-dom';
import useExcepcion from '../../../shared/hooks/useExcepcion';
import Swal from '../../../shared/SweetAlert';
import useUsuarioHandler from '../../../redux/handler/useUsuarioHandler';

const useFavorito = (producto) => {
    // state
    const [loader, setLoader]       = useState();
    const [isFavorito, setFavorito] = useState(false);

    // Hooks
    const { handleMessageException } = useExcepcion();
    const navigate = useNavigate();

    //Redux
    const { usuario } = useUsuarioHandler();

    useEffect(() => {
        if(producto !== null && producto !== undefined) {
            setFavorito(producto.EsFavorito);
        }
    }, [producto])

    const handleToogleFavorito = async () => {
        try {
            setLoader(true);

            if(usuario.IsLogged) {
                if(isFavorito) {
                    await removeProductoFavoritoAsync(producto.Codigo);

                    Swal.fire({
                        title: 'Exitoso',
                        text: 'Retirado de favoritos',
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: 'Orange',
                    });

                    setFavorito(false);

                } else {
                    await postAddProductoFavoritoAsync(producto.Codigo);

                    Swal.fire({
                        title: 'Exitoso',
                        text: 'Agregado a favoritos',
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: 'Orange',
                    });

                    setFavorito(true);
                }

            } else {
                navigate('/login');
            }

        } catch(err) {
            handleMessageException(err);
        } finally {
            setLoader(false);
        }
    }

    return {
        loader,
        isFavorito,
        handleToogleFavorito
    }
}

export default useFavorito;