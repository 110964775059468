import { InputAdornment, TextField, styled } from '@material-ui/core';
import { useState } from 'react';
import React from 'react'
import LupaNaranjaSvg from '../../assets/LupaNaranjaSvg';
import PopperSearch from './PopperSearch';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const InputSearch = ({ data, loader, onChange, onClick, ...props }) => {
    const [anchor, setAnchor] = useState(null);

    const location = useLocation();

    useEffect(() => {
      setAnchor(null);
    }, [location]);
 
    const handleInputOnChange = (e) => {
      onChange(e.target.value);
      setAnchor(e.currentTarget);
    }

    const handleInputClick = (e) => {
      setAnchor(null);
      onClick(e);
    }

    const handleInputKey = (e) => {      
      if (e.keyCode === 13) {
        setAnchor(null);
        onClick(e);
      }

      if (e.key === "Escape") { 
        setAnchor(null);
      }
    }

    const handleClose = () => {
      setAnchor(null);
    };


    useEffect(() => {
      if(data.Productos.length === 0) {
        setAnchor(null);
      }
    }, [data]);

  return (
    <div style={{ gridArea: 'inputSearch' }}>
      <MuiTextField
        {...props}
        id='input-text-search'
        variant="outlined"
        autoComplete='off'
        InputProps={{
          endAdornment: (
              <InputAdornment position="start">
                <LupaNaranjaSvg
                   onClick={handleInputClick}
                />
              </InputAdornment>
            ),
        }}
        onChange={handleInputOnChange}
        onKeyDown={handleInputKey}
        fullWidth
      />
      {
        loader ? <></> :
        <PopperSearch
          modifiers={{
            offset: {
              enabled: true,
              offset: '20, 3', 
            },
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
          open={Boolean(anchor)} 
          anchorEl={anchor}
          data={data}
          onClose={handleClose} 
        />
      }
    </div>
  )
}

const MuiTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
      backgroundColor: '#f7f7f7',
      borderRadius: '10px',
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Inter',
      color: 'grey',
      height: '45px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(240, 118, 29, 0.8)', 
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(240, 118, 29)',
      },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '5px'
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '12px',
    fontFamily: 'Inter',
    color: 'gray'
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
  }
});

export default InputSearch;