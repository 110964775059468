import React from 'react';

const IconNotListedLocation = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M480-332.693q13.923 0 24.115-10.192 10.192-10.193 10.192-24.115 0-13.923-10.192-24.115-10.192-10.192-24.115-10.192-13.923 0-24.115 10.192-10.192 10.192-10.192 24.115 0 13.922 10.192 24.115 10.192 10.192 24.115 10.192Zm107.846-283.768q0-43.923-29.269-70.462-29.27-26.538-74.346-26.538-42.538 0-71.923 20.038-29.384 20.039-29.769 44.346 0 8.077 3.231 13.231 3.23 5.153 10.076 7.384 8.693 2.846 16.692-1.077 8-3.923 14.923-11.846 8.231-12.538 21.962-19.077Q463.154-667 481.769-667q25.308 0 41.847 14.423 16.538 14.423 16.538 36.116 0 19.923-10.038 32.269Q520.077-571.846 502-556q-23.076 20.231-33.846 40.693-10.769 20.461-10.769 39.23 0 12.154 6.154 19.231 6.154 7.076 17.076 7.076 10.308 0 16.769-6.461 6.462-6.462 7.692-18 1.231-14.615 12.923-29.307 11.693-14.693 23.923-26.923 21.077-21.077 33.5-42 12.424-20.923 12.424-44ZM480-179.461q117.384-105.076 179.654-201.577 62.269-96.5 62.269-169.039 0-109.384-69.5-179.846T480-800.385q-102.923 0-172.423 70.462t-69.5 179.846q0 72.539 62.269 169.039Q362.616-284.537 480-179.461Zm0 57.075q-11.692 0-23.384-4.038-11.692-4.039-21.154-12.5-53.845-49.615-100.768-102.191-46.923-52.577-81.577-105.115-34.654-52.539-54.846-104.154-20.193-51.616-20.193-99.693 0-138.46 89.577-224.191Q357.231-859.999 480-859.999t212.345 85.731q89.577 85.731 89.577 224.191 0 48.077-20.193 99.501-20.192 51.423-54.654 104.154-34.461 52.73-81.384 105.115-46.923 52.384-100.769 101.998-9.461 8.462-21.345 12.693-11.885 4.23-23.577 4.23Zm0-435.306Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconNotListedLocation;
