import React from 'react'
import Container from '../../controls/Container';
import NeewsLetter from './components/NewsLetter';
import './style.css';
import SucuGroup from './components/SucuGroup';
import MenuGroup from './components/MenuGroup';
import Contactanos from './components/Contactanos';
import Politicas from './components/Politicas';

const Footer = ({ sucursales }) => {
  return (
    <>
      <Container>
        <NeewsLetter />
        <SucuGroup sucursales={sucursales} />
        <MenuGroup />
        <Contactanos />
      </Container>
      <Politicas />
    </>
  )
}

export default Footer;
