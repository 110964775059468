import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof fbq !== "undefined") {
      window.fbq("track", "PageView");
    }

  }, [location]);
};