import Swal from 'sweetalert2';

const sweetAlert = Swal.mixin({
  /*showCloseButton: true,*/
  customClass: {
    container: 'my-swal-container',
    confirmButton: 'btn-alert-swal-orange',
    cancelButton: 'btn-alert-swal-cancel',
    denyButton: 'btn-alert-swal-deny',
    title: 'swal-title-style',
    popup: 'swal-popup-style'
  },
  buttonsStyling: false
})

export default sweetAlert;