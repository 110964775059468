import React from 'react';

const IconArrowForward = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M665.078-450.001H210.001q-12.769 0-21.384-8.615-8.616-8.615-8.616-21.384t8.616-21.384q8.615-8.615 21.384-8.615h455.077l-206.77-206.77q-8.923-8.923-8.807-20.884.115-11.962 9.423-21.269 9.307-8.692 21.076-9 11.769-.308 21.076 9l253.615 253.615q5.615 5.615 7.923 11.846 2.308 6.23 2.308 13.461t-2.308 13.461q-2.308 6.231-7.923 11.846L501.076-201.078q-8.307 8.308-20.576 8.5-12.269.192-21.576-8.5-9.308-9.307-9.308-21.384t9.308-21.384l206.154-206.155Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconArrowForward;
