import React from 'react';
import IconLocationOn from "../../../../components/icons/IconLocationOn";
import './index.css';

const SucursalCard = ({ sucursal, onClick }) => {
    return (
        <div className="container-sucursal" onClick={() => onClick(sucursal.CodigoSucursal, 'tienda')}>
            <p>{sucursal.Nombre}{(sucursal.CodigoSucursal === 5 ? ": SOLO PICKUP o DOMICILIO" : "")}</p>
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconLocationOn height={16.75} width={17} fill={"#333333"} />
                </div>
                <p style={{ marginLeft: "4px" }}>{sucursal.CodigoSucursal !== 5 ? sucursal.Direccion : "Pickup-Recoger en: " + sucursal.Direccion + ". ¡¡¡¡¡Ojo: NO ES TIENDA!!!!!"}</p>
            </div>
        </div>
    )
}

export default SucursalCard;
