import { useState } from 'react';
import './css/RecomendadoCard.css';
import { Link} from "react-router-dom";
import NoImage from '../../pages/assets/productos/no-image.png';

const RecomendadoCard = (props) => {
    const [errorImage, setErrorImage] = useState();
    return (
        <Link to={"/precios_stock_detallado/" + props.producto.Codigo.toLowerCase()} style={{textDecoration:"none", color:"initial"}}>
            <div className='card-recomendado-producto'>
                <div className='card-imagen-producto'>
                    <div className='imagen'>
                        {
                            !errorImage
                            ? <img fetchpriority="high" onError={() => { setErrorImage(true) }} src={props.producto.Imagen} alt='producto' loading="lazy"></img>
                            : <img src={NoImage} alt="No disponible" loading="lazy" />
                        }
                    </div>
                </div>
                <hr className='card-linea'/>
                <div className='card-detalle-producto'>
                    <p className='card-nombre'>{props.producto.Descripcion}</p>
                    <p className='card-codigo'>{props.producto.Codigo}</p>
                </div>
            </div>
        </Link>
    )
}
export default RecomendadoCard