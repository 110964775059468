import React from 'react';
import {
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItem 
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';


const MenuGroup = () => {

  return (
    <div>
        <div 
            id="footer"
            style={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))',
            }}
        >
            <Accordion
            expanded={true}
            style={{ margin: '0px', boxShadow: 'none', background: 'rgb(247, 247, 247)'}}
        >
            <MuiSumary>
            <Title>Soporte</Title>
            </MuiSumary>
            <AccordionDetails style={{ display: 'block'}}>
                <Grid>
                    <ListItem 
                        component={'a'}
                        href="https://intelaf.wordpress.com"
                        style={{ '&:hover': { backgroundColor: 'red' }}}
                    >
                        <SubTitle>Blog</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/centros_servicio"
                    >
                        <SubTitle>Servicio técnico</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/sugerencias"
                    >
                        <SubTitle>FAQ</SubTitle>
                    </ListItem>
                </Grid>
            </AccordionDetails>
            </Accordion>

            <Accordion 
            expanded={true} 
            style={{ margin: '0px', boxShadow: 'none', background: 'rgb(247, 247, 247)'}}
        >
            <MuiSumary>
            <Title>Mayoreo</Title>
            </MuiSumary>
            <AccordionDetails style={{ display: 'block'}}>
                <Grid>
                    <ListItem 
                        component={Link}
                        to="/terminos_y_condiciones"
                    >
                        <SubTitle>Condiciones</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/mayoreo"
                    >
                        <SubTitle>Página mayoreo</SubTitle>
                    </ListItem>
                </Grid>
            </AccordionDetails>
            </Accordion>

            <Accordion 
            expanded={true} 
            style={{ margin: '0px', boxShadow: 'none', background: 'rgb(247, 247, 247)'}}
        >
            <MuiSumary>
            <Title>Política</Title>
            </MuiSumary>
            <AccordionDetails style={{ display: 'block'}}>
                <Grid>
                    <ListItem 
                        component={Link}
                        to="/envios"
                    >
                        <SubTitle>Envíos</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/mayoreo"
                    >
                        <SubTitle>Mayoreo</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/garantias"
                    >
                        <SubTitle>Garantías</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/devoluciones"
                    >
                        <SubTitle>Devoluciones</SubTitle>
                    </ListItem>

                </Grid>
            </AccordionDetails>
            </Accordion>

            <Accordion 
            expanded={true} 
            style={{ margin: '0px', boxShadow: 'none', background: 'rgb(247, 247, 247)'}}
        >
            <MuiSumary>
            <Title>Empresa</Title>
            </MuiSumary>
            <AccordionDetails style={{ display: 'block'}}>
                <Grid>
                    <ListItem 
                        component={Link}
                        to="/empresa"
                    >
                        <SubTitle>Historia</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/tiendas"
                    >
                        <SubTitle>Ubicaciones</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/centros_servicio"
                    >
                        <SubTitle>Centro de Servicio</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/empleos"
                    >
                        <SubTitle>Empleo</SubTitle>
                    </ListItem>

                </Grid>
            </AccordionDetails>
            </Accordion>

            <Accordion 
            expanded={true} 
            style={{ margin: '0px', boxShadow: 'none', background: 'rgb(247, 247, 247)'}}
        >
            <MuiSumary>
            <Title>Compra</Title>
            </MuiSumary>
            <AccordionDetails style={{ display: 'block'}}>
                <Grid>
                    <ListItem 
                        component={Link}
                        to="/recoger_en_tienda"
                    >
                        <SubTitle>Recoger en tienda</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/forma_de_pagos"
                    >
                        <SubTitle>Formas de pago</SubTitle>
                    </ListItem>
                </Grid>
            </AccordionDetails>
            </Accordion>

            <Accordion 
            expanded={true} 
            style={{ margin: '0px', boxShadow: 'none', background: 'rgb(247, 247, 247)'}}
        >
            <MuiSumary>
            <Title>Nosotros</Title>
            </MuiSumary>
            <AccordionDetails style={{ display: 'block'}}>
                <Grid>
                    <ListItem 
                        component={Link}
                        to="/mision_vision"
                    >
                        <SubTitle>Nuestra misión y visión</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="https://play.google.com/store/apps/details?id=com.intelaf.intelaf_app&hl=es" 
                        target="_blank" 
                        rel="noreferrer"
                    >
                        <SubTitle>App Intelaf</SubTitle>
                    </ListItem>
                </Grid>
            </AccordionDetails>
            </Accordion>

            <Accordion 
            expanded={true} 
            style={{ margin: '0px', boxShadow: 'none', background: 'rgb(247, 247, 247)'}}
        >
            <MuiSumary>
            <Title>Mi Perfil</Title>
            </MuiSumary>
            <AccordionDetails style={{ display: 'block'}}>
                <Grid>
                    <ListItem 
                        component={Link}
                        to="/usuario/favoritos"
                    >
                        <SubTitle>Mis favoritos</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/usuario"
                    >
                        <SubTitle>Mi cuenta</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/usuario/pedidos"
                    >
                        <SubTitle>Mis pedidos</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/usuario/direccion"
                    >
                        <SubTitle>Mis direcciones</SubTitle>
                    </ListItem>

                    <ListItem 
                        component={Link}
                        to="/como_eliminar_cuenta"
                    >
                        <SubTitle>Eliminar mi cuenta</SubTitle>
                    </ListItem>

                </Grid>
            </AccordionDetails>
            </Accordion>
        </div>
        <hr/>
    </div>
  )
}

const MuiSumary = styled(AccordionSummary)({
    minHeight: '40px !important', 
    '& .Mui-expanded': { 
        margin: '0px',
    }
})

const Title = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    color: '#222'
});

const SubTitle = styled(Typography)({
    fontSize: '12px',
    color: '#333'
});

export default MenuGroup;