import React from 'react';

const IconInventoryDos = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 -960 960 960"
      width={props.width}
    >
      <path
        d="M212.309-100.001q-29.923 0-51.115-21.193-21.193-21.192-21.193-51.115v-447.922q-17.615-9.077-28.807-25.808-11.193-16.731-11.193-38.577v-103.075q0-29.923 21.193-51.115 21.192-21.193 51.115-21.193h615.382q29.923 0 51.115 21.193 21.193 21.192 21.193 51.115v103.075q0 21.846-11.193 38.577-11.192 16.731-28.807 25.808v447.922q0 29.923-21.193 51.115-21.192 21.193-51.115 21.193H212.309ZM200-612.309v438.077q0 6.154 4.423 10.193Q208.847-160 215.386-160h532.305q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-440H200Zm-27.691-59.998h615.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-103.075q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H172.309q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v103.075q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462Zm224.229 249.613h167.308q12.385 0 20.423-8.23 8.038-8.231 8.038-20.615 0-12.385-8.038-20.423Q576.231-480 563.846-480H396.154q-12.385 0-20.423 8.038-8.038 8.038-8.038 20.423 0 12.384 8.23 20.615 8.231 8.23 20.615 8.23ZM480-386.154Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default IconInventoryDos;
