import {
    createContext, 
    useCallback, 
    useContext,
    useEffect, 
    useRef, 
    useState 
} from 'react';
import ModalPromoCondi from './components/ModalPromoCondi';

const PromoCondiContext = createContext();

export const PromoCondiProvider = ({ children }) => {

    //state
    const [open, setOpen]   = useState(false);
    const [promo, setPromo] = useState({ products: [], onAddPromo: ()=> { }});
 
    //Ref
    const resolveRef = useRef(null);

    //methods
    const openDialogPromo = useCallback((promo) => {
        setPromo(promo);
        setOpen(promo.products.length > 0);

        return new Promise((resolve) => {
            resolveRef.current = resolve;
        });
        
    }, []);

    const closeDialogPromo = useCallback((response) => { 
        if(resolveRef.current) {
            resolveRef.current(response);
            resolveRef.current = null;
        }

        setOpen(false);

        // eslint-disable-next-line
    },[])


    useEffect(() => {
        return () => {
            closeDialogPromo();
        }

        // eslint-disable-next-line
    }, []) 


  return (
    <PromoCondiContext.Provider value={{ openDialogPromo }}>
        { children }
        <ModalPromoCondi
            open={open}
            productos={promo.products}
            onClose={closeDialogPromo}
            onAddPromo={promo.onAddPromo}
        />
    </PromoCondiContext.Provider>
  );
};

export const usePromoCondi = () => {
    return useContext(PromoCondiContext);
};
