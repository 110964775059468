import { ListItem, ListItemIcon, ListItemText, Menu, MenuItem, styled } from '@material-ui/core';
import React,  { useState } from 'react';
import IconExpandMore from '../../../icons/IconExpandMore';
import { Link as DomLink } from 'react-router-dom';
import IconLocalShipping from '../../../icons/IconLocalShipping';
import IconPayments from '../../../icons/IconPayments';
import IconInventoryDos from '../../../icons/IconInventoryDos';
import IconWorkspace from '../../../icons/IconWorkspace';
import IconReceipt from '../../../icons/IconReceipt';

const MenuSoporte = () => {
    const [anchor, setAnchor] = useState();

    const handleClose = () => {
        setAnchor(null)
    }

    const handleOpen = (e) => {
        setAnchor(e.currentTarget)
    }

    const customizeMenu = {
        id: "customized-menu",
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        anchorEl: anchor,
        keepMounted: true,
        open: Boolean(anchor),
        onClose: handleClose
    };

  return (
    <MuiItem>
        <Box onClick={handleOpen}>
            <Text>Políticas</Text>
            <IconExpandMore width={22} height={22} fill={'#F0761D'}/>
        </Box>
        <MuiMenu { ...customizeMenu }>
            <MenuItemLink
                icon={<IconLocalShipping width={26} height={26} fill={"#808080"}/>}
                text={'Envíos'}
                to={'/envios'}
            />
            <MenuItemLink
                icon={<IconPayments width={26} height={26} fill={"#808080"}/>}
                text={'Pagos'}
                to={'/forma_de_pagos'}
            />
            <MenuItemLink
                icon={<IconInventoryDos width={26} height={26} fill={"#808080"}/>}
                text={'Mayoreo'}
                to={'/mayoreo'}
            />
            <MenuItemLink
                icon={<IconWorkspace width={26} height={26} fill={"#808080"}/>}
                text={'Garantías'}
                to={'/garantias'}
            />
            <MenuItemLink
                icon={<IconReceipt width={26} height={26} fill={"#808080"}/>}
                text={'Devoluciones'}
                to={'/devoluciones'}
            />
        </MuiMenu>
    </MuiItem>
  )
}


const MenuItemLink = React.forwardRef(({ text, icon, ...props }, ref) => {
    return (
      <Item ref={ref}>
        <Link {...props}>
          <ListItemIcon style={{ minWidth: '40px' }}>{icon}</ListItemIcon>
          <TextItem primary={text} />
        </Link>
      </Item>
    );
});


const Item = styled(MenuItem)({
    padding: '0px',
    margin: '0px',
    display: 'block'
});

const MuiMenu = styled(Menu)({
    '& .MuiPaper-rounded': {
        borderRadius: '13px'
    },
});

const TextItem = styled(ListItemText)({
    '& .MuiTypography-body1': {
        color: 'grey',
        fontSize: '13px',
        fontWeight: '400',
        letterSpacing: '-.25px',
        textDecoration: 'none',
        fontFamily: 'Inter, sans-serif',
    },
});

const Box = styled('div')({
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    padding: '0px',
    justifyContent: 'flex-end',
    cursor: 'pointer'
})

const MuiItem = styled(ListItem)({
    padding: '0px',
    margin: '0px',
    display: 'block'
});

const Link = styled(DomLink)({
    color: 'grey',
    fontSize: '13px',
    fontWeight: '400',
    letterSpacing: '-.25px',
    textDecoration: 'none',
    fontFamily: 'Inter',
    '&:hover': {
        textDecoration: 'none',
        color: '#F0761D',
    },
    '&:focus': {
        textDecoration: 'none',
        color: '#F0761D',
        border: 'none',
        outline: 'none',
        outlineOffset: 'none',
        backgroundColor: 'rgba(240,118,29,.1)',
    },
    display: 'flex',
    padding: '6px 25px'
});

const Text = styled('div')({
    color: 'grey',
    fontSize: '13px',
    fontWeight: '400',
    letterSpacing: '-.25px',
    textDecoration: 'none',
    fontFamily: 'Inter, sans-serif',
    '&:hover': {
        textDecoration: 'none',
        color: '#F0761D'
    },
    '&:focus': {
        textDecoration: 'none',
        color: '#F0761D',
    }
});

export default MenuSoporte;